import React from 'react';
import { ApplicationService } from '../../../../services/application-service';
import { SearchContainer } from '../search-container';
import { DownLoadResultList } from './download-result-list';

interface DownloadSearchProps {
  list: any; // PropTypes.array,
  onSelected: any; // PropTypes.func,
  onSearchBlur: any; // PropTypes.func,
  onSearchChange: any; // PropTypes.func,
}

export const DownLoadSearch = ({ ...props }: DownloadSearchProps) => {
  const handleSelectedApp = (selected) => {
    props.onSelected(selected.id);
  };

  const removeInActive = (list) => {
    return ApplicationService.removeInactive(list);
  };

  return (
    <React.Fragment>
      <SearchContainer
        resultComponent={DownLoadResultList}
        list={removeInActive(props.list)}
        placeHolder="Search item ID or name"
        onSelected={handleSelectedApp}
        onSearchChange={props.onSearchChange}
        onSearchBlur={props.onSearchBlur}
      />
    </React.Fragment>
  );
};
