import styled from 'styled-components';
import { BoxSection } from '../../../common/styles/shared';

export const FormFileCardContainer: any = styled(BoxSection).attrs({ className: 'EditFileCardContainer' })`
  width: 30rem;
  min-width: 30rem;
  margin-right: 1.6rem;
  display: flex;
  flex-direction: column;
`;

export const FileInfoSection: any = styled.div.attrs({ className: 'FileInfoSection' })`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex: 1;
`;

export const ButtonSection: any = styled.div.attrs({ className: 'ButtonSection' })`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 1.2rem;
`;
