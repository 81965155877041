import styled from 'styled-components';
import { Fonts } from './fonts';
import { Colors } from './colors';
import { Devices } from './devices';
import BGImage from '../../../assets/images/bg_d_2x.jpg';

export const BgContainer: any = styled.div.attrs({ className: 'BgContainer' })`
  background: ${(props: any) => props.background};
`;

export const RowContainer = styled.div.attrs({ className: 'RowContainer' })`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Button: any = styled.button.attrs({ className: 'Button' })`
  width: ${(props: any) => props.width};
  height: ${(props: any) => props.height};
  font-size: ${Fonts.fontSizeMedium};
  background: none;
  border: none;
  cursor: pointer;
`;

export const IconButton: any = styled(Button).attrs({ className: 'IconButton' })`
  width: ${(props: any) => props.width};
  height: ${(props: any) => props.height};
  background: url(${(props: any) => props.img});
  background-repeat: no-repeat;
`;

export const TextButton: any = styled(Button).attrs({ className: 'TextButton' })`
  font-size: ${Fonts.fontSizeMedium};
  color: ${Colors.textAccentSecondary};
  font-weight: normal;
`;

export const TextButtonSmall: any = styled(TextButton).attrs({ className: 'TextButtonSmall' })`
  font-size: ${Fonts.fontSizeDefault};
  font-weight: bold;
`;

export const TextButtonLargeBold: any = styled(TextButton).attrs({ className: 'TextButtonLargeBold' })`
  font-weight: bold;
`;

export const TextButtonUpper: any = styled(TextButton).attrs({ className: 'TextButtonUpper' })`
  text-transform: uppercase;
  font-weight: bold;
`;

export const BorderButton: any = styled(Button).attrs({ className: 'BorderButton' })`
  color: ${Colors.textSecondary};
  border: 0.1rem solid ${Colors.borderSecondary};
  background: ${Colors.bgSecondary};
`;

export const BackgroundButton: any = styled(Button).attrs({ className: 'BackgroundButton' })`
  color: ${Colors.textLight};
  background: ${Colors.accentSecondary};
`;

export const TextRegularSmall: any = styled.p.attrs({ className: 'TextRegularSmall' })`
  font-size: ${Fonts.fontSizeSmall};
  font-weight: normal;
`;

export const TextRegularDefault: any = styled.p.attrs({ className: 'TextRegularDefault' })`
  font-size: ${Fonts.fontSizeDefault};
  font-weight: normal;
  line-height: 1.6rem;
  max-width: 123rem;
  color: ${(props: any) => (props.color ? props.color : Colors.textPrimary)};
`;

export const TextRegularMedium: any = styled.p.attrs({ className: 'TextRegularMedium' })`
  font-size: ${Fonts.fontSizeMedium};
  font-weight: normal;
  line-height: 1.6rem;
  color: ${(props: any) => (props.color ? props.color : Colors.textPrimary)};
`;

export const TextRegularLarge: any = styled.p.attrs({ className: 'TextRegularLarge' })`
  font-size: ${Fonts.fontSizeLarge};
  font-weight: normal;
`;

export const TextRegularLargeBold: any = styled(TextRegularLarge).attrs({ className: 'TextRegularLargeBold' })`
  font-weight: bold;
`;

export const TextRegularMediumBold: any = styled(TextRegularMedium).attrs({ className: 'TextRegularMediumBold' })`
  font-weight: bold;
`;

export const TextRegularDefaultBold: any = styled(TextRegularDefault).attrs({ className: 'TextRegularDefaultBold' })`
  font-size: ${Fonts.fontSizeDefault};
  font-weight: bold;
  line-height: 1.6rem;
`;

export const TextMediumBold: any = styled.p.attrs({ className: 'TextMediumBold' })`
  font-size: ${Fonts.fontSizeMedium};
  font-weight: ${Fonts.fontWeightBold};
  color: ${(props) => props.color};
`;

export const TextMediumBoldUpperCase: any = styled(TextMediumBold).attrs({ className: 'TextMediumBoldUpperCase' })`
  text-transform: uppercase;
`;

export const TextMediumBoldInline: any = styled.span.attrs({ className: 'TextMediumBoldInline' })`
  font-size: ${Fonts.fontSizeMedium};
  font-weight: ${Fonts.fontWeightBold};
`;

export const TextRegularMediumInline: any = styled.span.attrs({ className: 'TextRegularMediumInline' })`
  font-size: ${Fonts.fontSizeMedium};
  font-weight: normal;
`;

export const TextLargeBold: any = styled.h1.attrs({ className: 'TextLargeBold' })`
  font-size: ${Fonts.fontSizeLarge};
  font-weight: bold;
  color: ${Colors.textSecondary};
`;

export const TextXLargeBold: any = styled.h1.attrs({ className: 'TextXLargeBold' })`
  font-size: ${Fonts.fontSizeXLarge};
  font-weight: bold;
  color: ${Colors.textAccentPrimary};
`;

export const TextLargeBoldItalic: any = styled.p.attrs({ className: 'TextLargeBoldItalic' })`
  font-size: ${Fonts.fontSizeLarge};
  font-weight: bold;
  font-style: italic;
`;

export const TextMediumRegularItalic: any = styled.p.attrs({ className: 'TextLargeBoldItalic' })`
  font-size: ${Fonts.fontSizeMedium};
  font-weight: normal;
  font-style: italic;
`;

export const Paragraph: any = styled(TextRegularDefault).attrs({ className: 'Paragraph' })`
  line-height: 1.6;
`;

export const PageTitle: any = styled.h1.attrs({ className: 'PageTitle' })`
  font-size: ${Fonts.fontSizeMega};
  font-weight: bold;
  color: ${(props) => (props.color ? props.color : Colors.textPrimary)};
`;

export const SectionTitle: any = styled(TextXLargeBold).attrs({ className: 'SectionTitle' })`
  color: ${Colors.textAccentPrimary};
`;

export const ErrorMessage: any = styled(TextRegularMedium).attrs({ className: 'ErrorMessage' })`
  color: ${Colors.textError};
  margin-top: 0.4rem;
  display: ${(props) => (props.visible ? 'block' : 'none')};
`;

export const Required: any = styled.span.attrs({ className: 'Required' })`
  color: ${Colors.textSecondary};
  font-size: ${Fonts.fontSizeLarge};
  font-weight: normal;
  margin-left: 0.4rem;
`;

export const Margin: any = styled.div.attrs({ className: 'Margin' })`
  margin-top: ${(props: any) => props.top};
  margin-bottom: ${(props: any) => props.bottom};
  margin-left: ${(props: any) => props.left};
  margin-right: ${(props: any) => props.right};
`;

export const BackDrop: any = styled.div.attrs({ className: 'BackDrop' })`
  display: ${(props: any) => (props.visible ? 'block' : 'none')};
  position: absolute;
  top: ${(props: any) => (props.top ? props.top : '0')};
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: ${Colors.backdrop};
`;

export const BackDropTransparent: any = styled(BackDrop).attrs({ className: 'BackDropTransparent' })`
  display: flex;
  justify-content: center;
  background: transparent;
  display: ${(props: any) => (props.visible ? 'block' : 'none')};
`;

export const Input: any = styled.input.attrs({ className: 'Input' })`
  width: ${(props: any) => props.width};
  height: 4rem;
  border-radius: 0.2rem;
  padding: 0 1.2rem;
  color: ${Colors.textInput};
  font-size: ${Fonts.fontSizeLarge};
  background: ${Colors.bgSecondary};
  border: 0.1rem solid ${Colors.borderInput};

  ${({ error }: { error: any }) =>
    error &&
    `
    border: .1rem solid ${Colors.borderError};
    background: ${Colors.bgInputError};
  `}
`;

export const SmallInputLabel: any = styled.div.attrs({ className: 'SmallInputLabel' })`
  margin-top: 5px;
`;

export const SmallInput: any = styled(Input).attrs({ className: 'SmallInput' })`
  height: 2rem;
  border-radius: 0.2rem;
  padding: 0 0.6rem;
  font-size: ${Fonts.fontSizeSmall};
`;

export const TextArea: any = styled.textarea.attrs({ className: 'TextArea' })`
  height: 9.4rem;
  min-height: 6rem;
  max-height: 30rem;
  width: ${(props: any) => props.width};
  max-width: 100%;
  min-width: 100%;
  border-radius: 0.2rem;
  padding: 1.2rem;
  color: ${Colors.textInput};
  font-size: ${Fonts.fontSizeLarge};
  background: ${Colors.bgSecondary};
  border-color: ${(props: any) => (props.error ? Colors.borderError : Colors.borderInput)};
  border-width: 0.1rem;
  border-style: solid;
`;

export const PageScroll: any = styled.div.attrs({ className: 'PageScroll' })`
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 1.6rem;
  overflow-x: hidden;
  overflow-y: auto;

  @media ${Devices.breakMegaLarge} {
    padding: 0 10%;
  }
`;

export const AdminPageHeader: any = styled.div.attrs({ className: 'PageHeader' })`
  margin: 2.8rem 0;
  display: flex;
  justify-content: space-between;
`;

export const BoxSection: any = styled.div.attrs({ className: 'BoxSection' })`
  padding: 1.6rem;
  margin-bottom: 1.6rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  background: ${Colors.bgSecondary};
  border-radius: 0.2rem;
`;

export const BGContainer: any = styled.div.attrs({ className: 'BGContainer' })`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center right;
  background-image: url(${BGImage});
`;

export const FileGroupContainer: any = styled.div.attrs({ className: 'FileGroupContainer' })`
  padding: 0.3rem 0.3rem 3.2rem 0.3rem;

  :last-of-type {
    padding-bottom: 0;
  }
`;

export const FileGroupHeadLine: any = styled(TextRegularDefaultBold).attrs({ className: 'FileGroupHeadLine' })`
  padding-bottom: 0.8rem;
  border-bottom: 0.1rem solid ${Colors.borderPrimary};
`;

export const FileList: any = styled.div.attrs({ className: 'FileList' })`
  display: flex;
  flex-wrap: wrap;

  @media ${Devices.breakLarge} {
    :nth-of-type(3) {
      margin-right: 1.6rem;
    }
  }
`;

export const ThinLink: any = styled.span.attrs({ className: 'ThinLink' })`
  font-size: ${Fonts.fontSizeMedium};
  font-weight: normal;
  line-height: 1.6rem;
  color: ${(props: any) => (props.color ? props.color : Colors.textPrimary)};
`;
