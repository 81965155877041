import styled from 'styled-components';
import { Colors } from '../styles/colors';
import { Paragraph } from '../styles/shared';

export const UserStateText: any = styled(Paragraph).attrs({ className: 'UserStateText' })`
  width: 100%;
  color: ${Colors.textLight};
  margin-right: 1.2rem;
`;

export const MarginTop: any = styled.div.attrs({ className: 'MarginTop' })`
  margin-top: 2rem;
`;
