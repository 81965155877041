import React from 'react';
import { TextRegularMedium } from '../../../common/styles/shared';
import { MyAppsText } from '../../../common/texts/my-apps-text';

export const MyAppsBlack = () => (
  <React.Fragment>
    <TextRegularMedium>
      <MyAppsText />
    </TextRegularMedium>
  </React.Fragment>
);
