import React, { Component } from 'react';
import { ToSelectorButton } from '../common/back-button/to-selector-button';
import { AboutPageContainer, AboutTextContainer, AboutTextSection, AboutSchneiderLink } from './about-page-style';
import { Colors } from '../common/styles/colors';
import { PageScroll, PageTitle, Paragraph, TextRegularDefaultBold, Margin } from '../common/styles/shared';
import { AppType, AppTypeGroup } from '../../interfaces/applications';

interface AboutProps {
  selectedAppType: AppType;
  applicationTypeGroups: AppTypeGroup[];
}

export class AboutContainer extends Component<AboutProps> {
  constructor(props: AboutProps) {
    super(props);
  }

  renderApplicationTypeGroups() {
    return (
      <AboutTextContainer>
        <TextRegularDefaultBold>Content</TextRegularDefaultBold>
        {this.props.applicationTypeGroups.map((appTypeGroup, index) => {
          return (
            <React.Fragment key={`appTypeGroup:${index}`}>
              <AboutTextSection>
                <Paragraph>{`${appTypeGroup.name}: `}</Paragraph>
                <Paragraph>{`${appTypeGroup.description}`}</Paragraph>
              </AboutTextSection>
            </React.Fragment>
          );
        })}
      </AboutTextContainer>
    );
  }

  render() {
    const { selectedAppType } = this.props;
    return (
      <PageScroll>
        <AboutPageContainer>
          <Margin bottom="1.6rem">
            <ToSelectorButton selectedAppType={selectedAppType} />
          </Margin>
          <PageTitle color={Colors.textAccentPrimary}>About BMS Applications</PageTitle>
          <AboutTextSection>
            <Paragraph>
              BMS Applications is a service where Schneider Electric provides complete pre-configured applications to be used together with
              RP-C controllers, and other files to be imported directly into EcoStruxure Building Operation Workstation for importing
              functionality, applications and setting up devices of different types.
            </Paragraph>
          </AboutTextSection>

          <AboutTextSection>
            <Paragraph>A filter function guides you to the right content that fits your model, device or wanted feature.</Paragraph>
          </AboutTextSection>

          {this.renderApplicationTypeGroups()}

          <AboutTextContainer>
            <TextRegularDefaultBold>Ready-to-use downloads</TextRegularDefaultBold>
            <AboutTextSection>
              <Paragraph>
                Downloaded export files can be used for custom development in EcoStruxture Building operation Workstation or providing
                access to pre-configured devices.
              </Paragraph>
            </AboutTextSection>
            <AboutTextSection>
              <Paragraph>
                With a device image file downloaded on a mobile phone, it is ready to be used directly in the EcoStruxure Building
                Commission mobile application.
              </Paragraph>
            </AboutTextSection>
          </AboutTextContainer>

          <AboutTextContainer>
            <TextRegularDefaultBold>Access</TextRegularDefaultBold>
            <Paragraph>
              All users logged in with a Schneider Electric partner or employee account have full access to download all our files.
            </Paragraph>
          </AboutTextContainer>

          <AboutTextContainer>
            <TextRegularDefaultBold>Supported browsers</TextRegularDefaultBold>
            <Paragraph>
              Windows: Chrome, Edge<sup>*</sup> and Firefox.
            </Paragraph>
            <Paragraph>Apple: Safari and Chrome.</Paragraph>
            <Paragraph>Android: Chrome.</Paragraph>
            <Paragraph>
              <sup>*</sup>If downloading using Edge, do not use “Save As”.
            </Paragraph>
          </AboutTextContainer>

          <AboutTextContainer>
            <TextRegularDefaultBold>Requirements</TextRegularDefaultBold>
            <Paragraph>
              The BMS Applications site uses ports 443 and 5001 to communicate over HTTPS. If any of those ports are blocked, this site will
              not work.
            </Paragraph>
          </AboutTextContainer>

          <AboutTextContainer>
            <TextRegularDefaultBold>Support</TextRegularDefaultBold>
            <Paragraph>
              If you have a problem using this website, find support contact information in your local SE website, within the “Support”
              section tab. Visit&nbsp;
              <a href="http://www.se.com">
                <AboutSchneiderLink>http://www.se.com</AboutSchneiderLink>
              </a>
              &nbsp;and select your country of origin.
            </Paragraph>
          </AboutTextContainer>

          <AboutTextContainer>
            <TextRegularDefaultBold>BMS Applications Version</TextRegularDefaultBold>
            <Paragraph>Version: 1.1.2.0</Paragraph>
          </AboutTextContainer>
        </AboutPageContainer>
      </PageScroll>
    );
  }
}
