import { SmallInput } from '../../styles/shared';

interface TextInputSmallProps {
  type: string;
  value: string;
  name: string;
  width: string;
  onChange: (e: any) => void;
  placeholder: string;
  maxLength: number;
}

export const TextInputSmall = ({ placeholder, maxLength, ...props }: TextInputSmallProps) => (
  <SmallInput
    {...props}
    maxLength={maxLength}
    placeholder={placeholder}
    onFocus={(e: any) => (e.target.placeholder = '')}
    onBlur={(e: any) => (e.target.placeholder = placeholder)}
  ></SmallInput>
);
