import styled from 'styled-components';
import { Colors } from '../../../common/styles/colors';
import { Devices } from '../../../common/styles/devices';
import { TextRegularDefault, BoxSection } from '../../../common/styles/shared';

export const FileCardContainer: any = styled(BoxSection).attrs({ className: 'FileCardContainer' })`
  width: 100%;
  height: auto;
  margin: 1.6rem 0 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  cursor: pointer;
  opacity: ${(props: any) => (props.active ? 1 : 0.5)};

  @media ${Devices.breakMedium} {
    width: 28rem;
    margin: 1.6rem 1.6rem 0 0;

    :nth-of-type(3) {
      margin-right: 1.6rem;
    }
  }
`;

export const FileCardText: any = styled(TextRegularDefault).attrs({ className: 'FileCardText' })`
  color: ${Colors.textSecondary};
  width: 55%;
`;

export const DownLoadButtons: any = styled.div.attrs({ className: 'DownLoadButtons' })`
  display: flex;
  justify-content: center;
  position: relative;
`;
