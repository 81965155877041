import React from 'react';
import { PopUpBodyListText, PopUpListContainer, FilterTagsContainer, Divider } from './body-style';
import { TextRegularMediumBold } from '../../../common/styles/shared';

export const DetailPopUpBody = ({ app }: { app: any }) => {
  const getFeatureTags = (feature) => {
    return feature.filterTags.map((tag, index) => {
      return (
        <PopUpBodyListText key={index}>
          {tag.name}
          {index !== feature.filterTags.length - 1 && <span>,&nbsp;</span>}
        </PopUpBodyListText>
      );
    });
  };

  const getFilterTags = (feature) => {
    return feature.filterTags.map((tag, index) => {
      return (
        <PopUpBodyListText key={index}>
          {tag.name}
          {feature.filterTags.length > 1 && index !== feature.filterTags.length - 1 && <span>,&nbsp;</span>}
        </PopUpBodyListText>
      );
    });
  };

  return (
    <React.Fragment>
      {app.model && (
        <PopUpListContainer>
          <div>
            <PopUpBodyListText>{app.model.name}</PopUpBodyListText>
          </div>
        </PopUpListContainer>
      )}

      {app.features && (
        <PopUpListContainer>
          {app.features.map((feature, index) => (
            <React.Fragment key={index}>{getFeatureTags(feature)}</React.Fragment>
          ))}
        </PopUpListContainer>
      )}

      {app.filterTags?.length > 0 && (
        <PopUpListContainer>
          {app.filterTags.map((feature, index) => (
            <React.Fragment key={index}>
              <FilterTagsContainer>
                <TextRegularMediumBold>{feature.name}:</TextRegularMediumBold>
                <span>&nbsp;</span>
                {getFilterTags(feature)}
              </FilterTagsContainer>
              {index !== app.filterTags.length - 1 && <Divider> | </Divider>}
            </React.Fragment>
          ))}
        </PopUpListContainer>
      )}
    </React.Fragment>
  );
};
