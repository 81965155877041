import React from 'react';
import PropTypes from 'prop-types';
import { Colors } from '../common/styles/colors';
import { PageLoaderWhite } from '../common/loaders/page-loader-white';
import { PageUnavailable } from '../common/error-pages/page-unavailable';
import { PageLoaderContainer } from '../admin/admin-style';
import { PageTitle, AdminPageHeader, BackDropTransparent, Margin } from '../common/styles/shared';
import { ToSelectorButton } from '../common/back-button/to-selector-button';
import { FeedbackList } from './feedback-list';
import { ListPageScroll } from '../sortable-list/sortable-list-page-style';

export const FeedbackListPage = ({ list, loading, responseCode, selectedAppType }) => {
  return (
    <React.Fragment>
      {loading && (
        <React.Fragment>
          <BackDropTransparent visible={loading}></BackDropTransparent>
          <PageLoaderContainer>
            <PageLoaderWhite />
          </PageLoaderContainer>
        </React.Fragment>
      )}

      {responseCode !== 0 && responseCode === 500 && <PageUnavailable top="11.2rem" />}

      {!loading && responseCode === 0 && (
        <ListPageScroll>
          <Margin top="1.2rem" bottom="1.6rem">
            <ToSelectorButton selectedAppType={selectedAppType} />
          </Margin>
          <AdminPageHeader>
            <div>
              <PageTitle color={Colors.textAccentPrimary}>Customer feedback</PageTitle>
            </div>
          </AdminPageHeader>

          <FeedbackList list={list} />
        </ListPageScroll>
      )}
    </React.Fragment>
  );
};

FeedbackListPage.propTypes = {
  list: PropTypes.array,
  loading: PropTypes.bool,
  responseCode: PropTypes.number,
  selectedAppType: PropTypes.object,
};
