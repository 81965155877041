import React, { PureComponent } from 'react';
import { FileSearchResultContainer, EditListRow, DownloadButton } from '../search-result-style';
import { Margin, TextRegularDefault, TextRegularMediumBold } from '../../../common/styles/shared';
import { DownLoadIconGreen } from '../../../download-page/file-selector/action-buttons/action-buttons-style';
import { FileLoader } from '../../../download-page/file-selector/action-buttons/action-buttons-style';
import { LoaderGreenSmall } from '../../../common/loaders/green-loader-small';

interface FileResultProps {
  filteredList: any; // PropTypes.array,
  onResultSelect: any; // PropTypes.func,
  cursor: any; // PropTypes.number,
  onRowHover: any; // PropTypes.func,
  downloadingFileId: any; // PropTypes.number,
  showAll: any; // PropTypes.func,
  displayShowAllButton: any; // PropTypes.bool,
}

export class FileResultList extends PureComponent<FileResultProps, {}> {
  constructor(props) {
    super(props);
  }

  showAll = () => {
    this.props.showAll();
  };

  onSelect = (file) => {
    this.props.onResultSelect(file);
  };

  render() {
    const { filteredList, cursor, onRowHover, downloadingFileId, displayShowAllButton } = this.props;

    const showAllButton: JSX.Element = (
      <EditListRow key={'showAllButton'} onMouseDown={this.showAll} onmouseover={onRowHover}>
        <Margin left={'auto'} right={'auto'}>
          <TextRegularMediumBold>Show all</TextRegularMediumBold>
        </Margin>
      </EditListRow>
    );

    return (
      <FileSearchResultContainer>
        {filteredList.map((file, index) => {
          const isDownloading = file.id === downloadingFileId;
          const isActive = index === cursor || isDownloading;
          return (
            <EditListRow
              disabled={downloadingFileId === -1}
              key={index}
              onMouseDown={() => this.onSelect(file)}
              active={isActive}
              onmouseover={onRowHover}
            >
              <div>
                <TextRegularMediumBold>{file.name}</TextRegularMediumBold>
                <TextRegularDefault>
                  {file.applicationCode} / {file.applicationName}
                </TextRegularDefault>
                {file.searchTag && <TextRegularDefault>{file.searchTag}</TextRegularDefault>}
              </div>
              <DownloadButton active={isActive}>
                <DownLoadIconGreen />
                <FileLoader visible={isDownloading}>
                  <LoaderGreenSmall />
                </FileLoader>
              </DownloadButton>
            </EditListRow>
          );
        })}
        {displayShowAllButton && showAllButton}
      </FileSearchResultContainer>
    );
  }
}
