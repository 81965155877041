import React from 'react';
import { Input } from '../../styles/shared';

interface TextInputProps {
  type: any;
  value: any;
  name: any;
  width: any;
  error?: any;
  onChange: any;
  placeholder: any; // PropTypes.string,
  maxLength?: any; // PropTypes.number,
}

export const TextInput = ({ placeholder, maxLength, ...props }: TextInputProps) => (
  <Input
    {...props}
    maxLength={maxLength}
    placeholder={placeholder}
    onFocus={(e) => (e.target.placeholder = '')}
    onBlur={(e) => (e.target.placeholder = placeholder)}
  ></Input>
);
