import { FormStickyBottom } from './form-parts-style';
import { SaveBtn, CancelResetBtn } from '../admin-style';

interface SaveCancelProps {
  onReset: () => void;
  onCancel: () => void;
  onSave: () => void;
  uploading: boolean;
  formIsValid: boolean;
}

export const SaveCancelResetButtons = (props: SaveCancelProps) => {
  const { uploading, onCancel, formIsValid, onSave, onReset } = props;

  return (
    <FormStickyBottom>
      <CancelResetBtn disabled={uploading} onClick={onCancel}>
        Cancel
      </CancelResetBtn>
      <CancelResetBtn onClick={onReset}>Reset</CancelResetBtn>
      <SaveBtn disabled={!formIsValid || uploading} onClick={onSave}>
        Save item
      </SaveBtn>
    </FormStickyBottom>
  );
};
