import React from 'react';
import { MenuIconRow, MenuIcon } from '../menu/menu-style';
import { TextRegularMedium } from '../../common/styles/shared';
import Search from '../../../assets/images/search.svg';

export const DownloadFileSearch = ({ onClick }: { onClick: any }) => (
  <MenuIconRow onClick={onClick}>
    <MenuIcon img={Search}></MenuIcon>
    <TextRegularMedium>File search</TextRegularMedium>
  </MenuIconRow>
);
