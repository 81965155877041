const fontSizes = {
  small: '1rem',
  default: '1.2rem',
  medium: '1.4rem',
  large: '1.6rem',
  xLarge: '1.8rem',
  mega: '2.2rem',
};

const fontWeights = {
  thin: 300,
  default: 400,
  medium: 500,
  bold: 600,
};

export const Fonts = {
  fontSizeSmall: fontSizes.small,
  fontSizeDefault: fontSizes.default,
  fontSizeMedium: fontSizes.medium,
  fontSizeLarge: fontSizes.large,
  fontSizeXLarge: fontSizes.xLarge,
  fontSizeMega: fontSizes.mega,
  fontWeightThin: fontWeights.thin,
  fontWeightDefault: fontWeights.default,
  fontWeightMedium: fontWeights.medium,
  fontWeightBold: fontWeights.bold,
};
