import React from 'react';
import { EditResultContainer, EditListRow, EditButton } from '../search-result-style';
import { TextRegularDefault, TextRegularMediumBold } from '../../../common/styles/shared';

interface EditResultProps {
  filteredList: any; // PropTypes.array,
  onResultSelect: any; // PropTypes.func,
  cursor: any; // PropTypes.number,
  onRowHover: any; // PropTypes.func,
}

export const EditResultList = ({ filteredList, onResultSelect, cursor, onRowHover }: EditResultProps) => (
  <EditResultContainer>
    {filteredList.map((app, index) => {
      return (
        <EditListRow key={index} onMouseDown={() => onResultSelect(app)} active={index === cursor} onmouseover={onRowHover}>
          <div>
            <TextRegularMediumBold>{app.applicationCode}</TextRegularMediumBold>
            <TextRegularDefault>{app.name}</TextRegularDefault>
          </div>

          <EditButton active={index === cursor}>Edit</EditButton>
        </EditListRow>
      );
    })}
  </EditResultContainer>
);
