import styled from 'styled-components';
import { Devices } from '../styles/devices';
import { Colors } from '../styles/colors';

export const FilterContainer: any = styled.div.attrs({ className: 'FilterContainer' })`
  width: 28rem;
  min-width: 28rem;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 0.2rem;
  z-index: 2;
  display: ${(props: any) => (props.visible ? 'flex' : 'none')};
  flex-direction: column;
  background: ${Colors.bgSecondary};
  border: 0.1rem solid ${Colors.borderPrimary};
  overflow-x: hidden;
  overflow-y: auto;

  @media ${Devices.breakLarge} {
    z-index: 0;
    height: auto;
    top: 32.2rem;
    left: 1.6rem;
    bottom: 1.6rem;
    right: auto;
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: auto;
    border: none;
  }

  @media ${Devices.breakMegaLarge} {
    left: 10%;
    width: 29rem;
  }
`;

export const FilterActionBar: any = styled.div.attrs({ className: 'FilterActionBar' })`
  width: 28rem;
  position: relative;
  bottom: 0;
  right: 0;
  padding: 0.8rem 1.8rem;
  text-align: center;
  background: ${Colors.bgPrimary};
  box-shadow: 0 -0.1rem 0.3rem 0 rgba(0, 0, 0, 0.16);

  @media ${Devices.breakLarge} {
    display: none;
  }
`;

export const ButtonsWrapper: any = styled.div.attrs({ className: 'ButtonsWrapper' })`
  display: flex;
  justify-content: space-between;
  margin-top: 0.8rem;
`;

export const FilterContent: any = styled.div.attrs({ className: 'FilterContent' })`
  overflow-y: auto;
  overflow-x: hidden;
`;
