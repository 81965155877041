import styled from 'styled-components';
import { Devices } from '../common/styles/devices';
import { TextRegularMediumInline } from '../common/styles/shared';

export const ListWrapper: any = styled(TextRegularMediumInline).attrs({ className: 'ListWrapper' })``;

export const ListHeader: any = styled.div.attrs({ className: 'ListHeader' })`
  display: none;
  padding: 1rem 0rem;
  border-bottom: 0.1rem solid #999999;

  @media ${Devices.breakMedium} {
    display: flex;
  }
`;

export const ListRow: any = styled.div.attrs({ className: 'ListRow' })`
  display: flex;
  border-bottom: 0.1rem solid #999999;
  padding: 1rem 0rem;
  flex-direction: column;

  @media ${Devices.breakMedium} {
    flex-direction: row;
  }
`;

export const UserAndDate: any = styled.div.attrs({ className: 'UserAndDate' })`
  min-width: 10%;
`;

export const Comment: any = styled.div.attrs({ className: 'Comment' })`
  min-width: 85%;
  padding: 1rem 0rem;
  @media ${Devices.breakMedium} {
    flex-direction: row;
    padding: 0rem;
  }
`;
export const Priority: any = styled.div.attrs({ className: 'Priority' })`
  min-width: 5%;
  display: none;

  @media ${Devices.breakMedium} {
    flex-direction: row;
    padding: 0rem;
    display: flex;
  }
`;

export const User: any = styled(UserAndDate).attrs({ className: 'User' })``;
export const Date: any = styled(UserAndDate).attrs({ className: 'Date' })``;
