import React, { Component } from 'react';
import { Constants } from '../../services/constant-service';
import { Filter } from '../common/filter/filter';
import { ApplicationsList } from '../common/applications/applications-list/applications-list';
import { PageLoaderWhite } from '../common/loaders/page-loader-white';
import { RootHeader } from './root-header/root-header';
import { MyAppsHeader } from './my-apps-header/my-apps-header';
import { NoMyApps } from './no-apps/no-my-apps';
import { NoApps } from './no-apps/no-apps';
import { ToastBottom } from '../common/user-messages/toast-bottom';
import { Colors } from '../common/styles/colors';
import { SmallDeviceScroll, ResultBar, PageLoaderContainer, SearchBackDrop, SearchContainer } from './selector-page-style';
import { DownLoadSearch } from '../page-header/search/download-search/download-search';
import { BackDrop, BgContainer, TextRegularMedium, TextButtonUpper } from '../common/styles/shared';
import { AppType, AppTypeGroup, Application } from '../../interfaces/applications';

interface SelectorPageProps {
  currentRoute: string;
  formData: any; // PropTypes.object,
  appType: AppType;
  applicationTypeGroups: AppTypeGroup[];
  filteredApplications: Application[];
  isLargeDevice: boolean;
  filtersVisible: boolean;
  onFilter: (name: string, value: string, index: number) => void;
  onBackDropClick: () => void;
  onToggleGroup: (groupName: string) => void;
  onToggleFilterOptions: () => void;
  onAppClick: (appId: number) => void;
  onClear: () => void;
  loading: boolean;
  responseCode: number;
  responseMessage: string;
  hideToast: () => void;
  allApplications: Application[];
  handleRedirect: (appId: number) => void;
}

interface SelectorPageState {
  showBackDrop: boolean;
  filteredList: any;
}

export class SelectorPage extends Component<SelectorPageProps, SelectorPageState> {
  constructor(props: SelectorPageProps) {
    super(props);

    this.state = {
      showBackDrop: false,
      filteredList: [],
    };
  }

  handleSearchChange = (filteredList) => {
    this.setState({
      filteredList,
      showBackDrop: true,
    });
  };

  handleSelectedApp = (id) => {
    this.props.handleRedirect(id);
  };

  handleSearchBlur = () => {
    this.setState({
      showBackDrop: false,
    });
  };

  render() {
    const {
      currentRoute,
      loading,
      responseCode,
      formData,
      applicationTypeGroups,
      appType,
      responseMessage,
      hideToast,
      filteredApplications,
      onToggleFilterOptions,
      onAppClick,
      isLargeDevice,
      filtersVisible,
      onBackDropClick,
      onFilter,
      onToggleGroup,
      onClear,
    } = this.props;

    const { showBackDrop, filteredList } = this.state;

    let filterGroups: any = undefined;
    const isCompleted = responseCode === 0 && !loading;

    if (isCompleted) {
      filterGroups = Array.from(formData.values());
    }

    const currentAppTypeGroup = applicationTypeGroups.find((appTypeGroup) => {
      return appTypeGroup.applicationTypes.includes(appType.id);
    });

    if (currentAppTypeGroup == null) {
      console.error('Current application type group not found. This should not happen.');
      return null;
    }

    const isMyAppsPage = currentRoute === Constants.routes.myApplications;
    const showFilter = filtersVisible && !isLargeDevice;

    return (
      <React.Fragment>
        <ErrorToast responseCode={responseCode} responseMessage={responseMessage} hideToast={hideToast} />

        <SmallDeviceScroll>
          <PageHeader isMyAppsPage={isMyAppsPage} appType={appType} currentAppTypeGroup={currentAppTypeGroup} />
          <NoAppPage isMyAppsPage={isMyAppsPage} responseCode={responseCode} />

          {loading && (
            <PageLoaderContainer>
              <PageLoaderWhite />
            </PageLoaderContainer>
          )}

          {isCompleted && (
            <React.Fragment>
              <SearchBackDrop visible={showBackDrop && filteredList.length}></SearchBackDrop>
              <SearchContainer>
                <DownLoadSearch
                  list={this.props.allApplications}
                  onSelected={this.handleSelectedApp}
                  onSearchChange={this.handleSearchChange}
                  onSearchBlur={this.handleSearchBlur}
                />
              </SearchContainer>
              <ResultBar>
                <TextRegularMedium>{filteredApplications.length} items</TextRegularMedium>
                <TextButtonUpper className="filter" onClick={onToggleFilterOptions}>
                  Filter
                </TextButtonUpper>
              </ResultBar>

              <ApplicationsList
                filteredApplications={filteredApplications}
                onAppClick={onAppClick}
                selectedAppType={appType}
                appTypeGroup={currentAppTypeGroup}
              />
            </React.Fragment>
          )}
        </SmallDeviceScroll>

        <BackDrop visible={showFilter} onClick={onBackDropClick}></BackDrop>

        {isCompleted && (
          <Filter
            visible={showFilter || isLargeDevice}
            filteredApplications={filteredApplications}
            formData={filterGroups}
            onFilter={onFilter}
            onToggleGroup={onToggleGroup}
            onToggleFilterOptions={onToggleFilterOptions}
            onClear={onClear}
          />
        )}
      </React.Fragment>
    );
  }
}

function ErrorToast({
  responseCode,
  responseMessage,
  hideToast,
}: {
  responseCode: number;
  responseMessage: string;
  hideToast: () => void;
}) {
  if (responseCode == 0 || responseCode === 404) {
    return null;
  }

  return <ToastBottom responseCode={responseCode} text={responseMessage} onClose={hideToast} />;
}

function NoAppPage({ isMyAppsPage, responseCode }: { isMyAppsPage: boolean; responseCode: number }) {
  if (responseCode !== 0 && responseCode === 404) {
    return <React.Fragment>{isMyAppsPage ? <NoMyApps /> : <NoApps />}</React.Fragment>;
  }
  return null;
}

function PageHeader({ isMyAppsPage, appType, currentAppTypeGroup }: { isMyAppsPage: boolean; appType: any; currentAppTypeGroup: any }) {
  return (
    <BgContainer background={Colors.bgSecondary}>
      {isMyAppsPage ? <MyAppsHeader selectedAppType={appType} /> : <RootHeader appTypeGroup={currentAppTypeGroup} />}
    </BgContainer>
  );
}
