import React from 'react';
import { TextRegularMediumBold, IconButton } from '../../styles/shared';
import ArrowUp from '../../../../assets/images/chevron-up.svg';
import ArrowDown from '../../../../assets/images/chevron-down.svg';
import { ShapedTagGroup } from '../../../../interfaces/tags';

interface GroupHeaderProps {
  group: ShapedTagGroup;
  onToggleGroup: (groupName: string) => void;
  color?: string;
}

export const GroupHeader = ({ group, color, onToggleGroup }: GroupHeaderProps) => {
  return (
    <React.Fragment>
      <TextRegularMediumBold color={color}>{group.label}</TextRegularMediumBold>
      <IconButton
        width="1.3rem"
        height="1.8rem"
        name={group.name}
        img={group.collapsed ? ArrowDown : ArrowUp}
        onClick={() => onToggleGroup(group.name)}
      ></IconButton>
    </React.Fragment>
  );
};
