import styled from 'styled-components';
import { Colors } from '../../styles/colors';

export const RadioIcon: any = styled.svg.attrs({ className: 'RadioIcon' })`
  width: 2rem;
  height: 2rem;
  fill: ${Colors.accentPrimary};
`;

export const StyledRadioButton: any = styled.div.attrs({ className: 'StyledRadioButton' })`
  display: inline-block;
  width: 2rem;
  height: 2rem;
  margin-right: 0.8rem;
  border-radius: 50%;
  border: 0.1rem solid ${Colors.borderInput};
  cursor: pointer;

  ${RadioIcon} {
    visibility: ${(props: any) => (props.checked ? 'visible' : 'hidden')};
  }
`;
