import { FilterOptions } from './filter-options/filter-options';
import { FilterButtons } from './filter-options/filter-buttons';
import { FilterContainer, FilterActionBar, ButtonsWrapper, FilterContent } from './filter-style';
import { TextRegularMedium } from '../styles/shared';
import { Application } from '../../../interfaces/applications';

interface FilterProps {
  visible: boolean;
  filteredApplications: Application[];
  formData: any; // PropTypes.array,
  onFilter: (name: string, value: string, index: number) => void;
  onToggleGroup: (name: string) => void;
  onToggleFilterOptions: () => void;
  onClear: () => void;
}

export const Filter = (props: FilterProps) => (
  <FilterContainer visible={props.visible}>
    <FilterContent>
      <FilterOptions {...props} />
    </FilterContent>

    <FilterActionBar>
      <TextRegularMedium>{props.filteredApplications.length} items</TextRegularMedium>
      <ButtonsWrapper>
        <FilterButtons onClear={props.onClear} onToggleFilterOptions={props.onToggleFilterOptions} />
      </ButtonsWrapper>
    </FilterActionBar>
  </FilterContainer>
);
