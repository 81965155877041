import React from 'react';
import { AppFormContainer } from '../application-form/application-form-container';
import { PageLoaderWhite } from '../../common/loaders/page-loader-white';
import { PageLoaderContainer } from '../admin-style';
import { Colors } from '../../common/styles/colors';
import { PageTitle, PageScroll, AdminPageHeader, BackDropTransparent } from '../../common/styles/shared';
import { Response } from '../../../interfaces/common';
import { Application } from '../../../interfaces/applications';

interface AddAppProps {
  form: any;
  originalForm: any;
  selectedAppType: any;
  uploading: boolean;
  applications: Application[];
  onSubmit: (form: any) => Promise<Response>;
  redirectUpdate: () => Promise<void>;
}

export const AddAppPage = ({ form, uploading, applications, originalForm, onSubmit, selectedAppType, redirectUpdate }: AddAppProps) => {
  return (
    <React.Fragment>
      {uploading && (
        <React.Fragment>
          <BackDropTransparent visible={uploading}></BackDropTransparent>
          <PageLoaderContainer>
            <PageLoaderWhite text="Uploading..." />
          </PageLoaderContainer>
        </React.Fragment>
      )}

      <PageScroll>
        <AdminPageHeader>
          <div>
            <PageTitle color={Colors.textAccentPrimary}>{`Add new ${selectedAppType.description}`}</PageTitle>* required fields
          </div>
        </AdminPageHeader>
        <AppFormContainer
          form={form}
          uploading={uploading}
          applications={applications}
          originalForm={originalForm}
          onSubmit={onSubmit}
          selectedAppType={selectedAppType}
          redirectUpdate={redirectUpdate}
        />
      </PageScroll>
    </React.Fragment>
  );
};
