import styled from 'styled-components';
import { Colors } from '../styles/colors';
import { PageTitle, TextRegularMedium } from '../styles/shared';
import EcoStruxure from '../../../assets/images/ecostruxure.png';
import LifeIsOn from '../../../assets/images/logo_life_is_on_white.svg';

export const SplashContainer: any = styled.div.attrs({ className: 'SplashContainer' })`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 10;
`;

export const LoaderContainer: any = styled.div.attrs({ className: 'LoaderContainer' })`
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const BrandingContainer: any = styled.div.attrs({ className: 'BrandingContainer' })`
  width: 100%;
  height: 45%;
`;

export const SplashBranding: any = styled.div.attrs({ className: 'SplashBranding' })`
  width: 100%;
  height: 60%;
  padding: 0 3.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${Colors.accentPrimary};
`;

export const OpacitySection: any = styled.div.attrs({ className: 'OpacitySection' })`
  width: 100%;
  height: 10%;
  opacity: 0.5;
  background: ${Colors.accentPrimary};
`;

export const EcoLogo: any = styled.div.attrs({ className: 'SplashBranding' })`
  width: 12rem;
  height: 2.2rem;
  background-image: url(${EcoStruxure});
  background-repeat: no-repeat;
`;

export const ApplicationName: any = styled(PageTitle).attrs({ className: 'ApplicationName' })`
  color: ${Colors.textLight};
  margin-top: 0.8rem;
  margin-bottom: 0.4rem;
`;

export const PayOff: any = styled(TextRegularMedium).attrs({ className: 'PayOff' })`
  color: ${Colors.textLight};
`;

export const LifeIsOnContainer: any = styled.div.attrs({ className: 'LifeIsOnContainer' })`
  width: 100%;
  height: 30%;
  padding: 0 3.2rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const LifeIsOnLogo: any = styled.div.attrs({ className: 'LifeIsOnLogo' })`
  width: 18.2rem;
  height: 3.9rem;
  background-image: url(${LifeIsOn});
  background-repeat: no-repeat;
`;
