import { Route } from 'react-router-dom';
import { DefaultLayoutContainer } from '../layouts/default-layout-container';
import { StateContext } from '../../services/state-context';

export const NotProtectedRoute = ({ component: Component, ...props }) => {
  return (
    <Route
      {...props}
      render={() => {
        return (
          <StateContext.Consumer>
            {(context) => <DefaultLayoutContainer component={Component} {...props} {...context} />}
          </StateContext.Consumer>
        );
      }}
    />
  );
};
