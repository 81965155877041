import { ErrorPageLinkThin } from './error-pages-style';

export const SeLink = () => {
  return (
    <span>
      <a href="http://www.se.com">
        <ErrorPageLinkThin>http://www.se.com</ErrorPageLinkThin>
      </a>
    </span>
  );
};
