import { Application } from '../interfaces/applications';
import { ShapedTagGroup, ShapedTagOptions } from '../interfaces/tags';
import { Constants } from './constant-service';
import { uniq, flatten } from 'lodash';

export const SelectorFormCreateService = {
  getForm: (applications: Application[], tagGroups: ShapedTagGroup[]) => {
    const groups = modelFilterGroups(applications, tagGroups);
    return createFilterForm(groups, applications);
  },
};

const modelFilterGroups = (applications: Application[], tagGroups: ShapedTagGroup[]) => {
  const allGroupIds: number[] = getAllUniqueTags(applications, 'tagsGroupsId').sort((a, b) => a - b);
  const allTagIds: number[] = getAllUniqueTags(applications, 'tagsId').sort((a, b) => a - b);
  const defaultGroups: ShapedTagGroup[] = getDefaultGroups(allGroupIds, tagGroups);
  //parent group has no tags therefore won't be present in applications taglist or tag group list
  const parentGroups: ShapedTagGroup[] = Array.from(getParentGroups(tagGroups));

  let groups = [...defaultGroups, ...parentGroups];
  groups = groups.map((group) => {
    group.options = removeNotAvailableTags(group, allTagIds);
    return group;
  });

  return groups.sort((a, b) => a.level - b.level);
};

const getParentGroups = (tagGroups: ShapedTagGroup[]) => {
  return tagGroups.filter((group) => group.isParentGroup);
};

const createFilterForm = (groups: ShapedTagGroup[], applications: Application[]) => {
  groups = getShapedFilterGroups(groups, applications);
  let form = new Map();
  groups.map((group) => {
    form.set(group.name, group);
  });
  return form;
};

const getAllUniqueTags = (applications: Application[], property: string): number[] => {
  const options: number[] = flatten(applications.map((app) => app[property]));
  return uniq(options);
};

const removeNotAvailableTags = (group: ShapedTagGroup, options: number[]) => {
  return group.options.filter((option) => options.includes(option.id));
};

const getDefaultGroups = (groupIds: number[], groups: ShapedTagGroup[]) => {
  const foundGroups = groupIds.map((id) => {
    return groups.find((obj) => obj.id === id);
  });

  if (foundGroups?.length > 0) {
    return foundGroups as ShapedTagGroup[];
  }

  return [];
};

const getShapedFilterGroups = (groups: ShapedTagGroup[], applications: Application[]) => {
  return groups.map((group) => {
    return shapeGroup(group, applications);
  });
};

const shapeGroup = (group: ShapedTagGroup, applications: Application[]): ShapedTagGroup => {
  group.label = group.name;
  group.options = group.options.length ? getShapedOptions(group, applications) : [];

  if (!group.multipleSelection) {
    group.selected = Constants.defaultSelected;
  }

  group.total = group.options.reduce((acc, option) => {
    return acc + option.total;
  }, 0);
  return group;
};

const getShapedOptions = (group: ShapedTagGroup, applications: Application[]): ShapedTagOptions[] => {
  let options = group.options.map((option) => {
    const obj: any = {
      value: option.id,
      label: option.name,
      group: group.id,
      total: calcTotalByTag(option.id, applications),
      viewType: option.viewType,
    };

    if (group.multipleSelection) {
      obj.selected = false;
    }
    return obj;
  });

  if (!group.multipleSelection) {
    options.unshift({ value: 'x', label: 'All', group: group.id, total: 0 }); //radio buttons group need default none option
  }
  return options;
};

const calcTotalByTag = (id: number, applications: Application[]) => {
  let count = 0;
  applications.map((app) => {
    if (app.tagsId.includes(id)) {
      count += 1;
    }
  });
  return count;
};
