import { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import { cloneDeep as CloneDeep } from 'lodash';
import { Constants } from '../../services/constant-service';
import { ApiService } from '../../services/api-service';
import { ApplicationService } from '../../services/application-service';
import { CacheService } from '../../services/cache-service';
import { SelectorFormCreateService } from '../../services/selector-form-create-service';
import { SelectorPage } from './selector-page';
import { Devices } from '../common/styles/devices';
import { AppType, AppTypeGroup, Application } from '../../interfaces/applications';
import { ShapedTagGroup, TagGroup } from '../../interfaces/tags';

interface SelectorContainerProps {
  applications: Application[];
  tagGroups: ShapedTagGroup[];
  selectedAppType: AppType;
  getCurrentRoute: () => string;
  applicationTypeGroups: AppTypeGroup[];
  updatingApplications: boolean;
}

interface SelectorContainerState {
  loading: boolean;
  formData: any;
  appType: AppType;
  filteredApplications: Application[];
  redirect: boolean;
  filtersVisible: boolean;
  isLargeDevice: boolean;
  responseCode: number;
  responseMessage: string;
}

export class SelectorContainer extends PureComponent<SelectorContainerProps, SelectorContainerState> {
  private applications: Application[] = [];
  private selectedAppId: number | undefined = undefined;
  private currentRoute: string = '';
  private cacheName: string = '';

  constructor(props: SelectorContainerProps) {
    super(props);

    this.currentRoute = props.getCurrentRoute();
    this.cacheName = this.getCacheName(this.currentRoute);

    this.state = {
      loading: true,
      formData: {},
      appType: props.selectedAppType,
      filteredApplications: [],
      redirect: false,
      filtersVisible: false,
      isLargeDevice: false,
      responseCode: 0,
      responseMessage: '',
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    this.updateApplications();
  }

  componentDidUpdate(prevProps: SelectorContainerProps) {
    if (!this.props.updatingApplications && prevProps.updatingApplications !== this.props.updatingApplications) {
      this.setState({ loading: true, responseCode: 0, responseMessage: '' }, () => this.updateApplications());
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => this.setState({ isLargeDevice: window.innerWidth > parseInt(Devices.large) });

  updateApplications = async () => {
    const isSelectorPage = this.currentRoute && !this.currentRoute.includes('applications');
    const tagGroups = CloneDeep(this.props.tagGroups);

    this.applications = isSelectorPage ? this.getApplicationsByType() : await this.fetchMyApplications();

    this.applications?.length ? this.updateForm(this.applications, tagGroups) : this.setStateNoApps();
    return this.setState({
      loading: false,
    });
  };

  getApplicationsByType = () => {
    return ApplicationService.getAppsByType(CloneDeep(this.props.applications), this.props.selectedAppType);
  };

  fetchMyApplications = async () => {
    try {
      const myApps = await ApiService.getMyApplications();
      return myApps ? this.getApplicationsById(myApps, this.props.applications) : [];
    } catch (error: any) {
      return this.setState({
        responseCode: error.response.status,
        responseMessage: 'Something went wrong fetching your item. Please try again later.',
        loading: false,
      });
    }
  };

  getApplicationsById = (myApps: { applicationId: number }[], allApps: Application[]) => {
    return allApps.filter((app) => myApps.some((myApp) => myApp.applicationId === app.id));
  };

  updateForm = (apps: Application[], groups: ShapedTagGroup[]) => {
    const form = CacheService.getCachedForm(this.cacheName);
    form ? this.createFromCache(form.formData, apps) : this.createNewForm(apps, groups);
  };

  createFromCache = (form: any, apps: Application[]) => {
    const selected = this.getSelected(form);
    const filterApplications = this.filterApplications(selected, apps);
    this.updateState(form, filterApplications);
  };

  createNewForm = (apps: Application[], groups: ShapedTagGroup[]) => {
    const formData = SelectorFormCreateService.getForm(apps, groups);
    this.updateState(formData, apps);
  };

  updateState = (formData: any, apps: Application[]) => {
    return this.setState({
      loading: false,
      formData,
      filteredApplications: apps,
    });
  };

  setStateNoApps = () => {
    this.setState({
      loading: false,
      responseCode: 404,
      formData: {},
      filteredApplications: [],
    });
  };

  getCacheName = (route: string) => {
    return route === Constants.routes.root ? Constants.cache.selectorForm : Constants.cache.myAppsForm;
  };

  handleFilterChange = (name: string, value: string, index: number) => {
    const formData = this.state.formData;
    let updatedFormElement = formData.get(name);
    let formGroups: ShapedTagGroup[] = Array.from(formData.values());

    this.setSelected(updatedFormElement, value, index);
    const selected = this.getSelected(formData);

    if (!selected.length) {
      this.reset();
    }

    let filteredApplications = this.filterApplications(selected, this.applications);

    formGroups.map((group) => {
      return this.calculateAvailable(filteredApplications, updatedFormElement, group);
    });

    const cacheForm = {
      form: CloneDeep(formData),
      formIsValid: true,
    };

    CacheService.cacheForm(this.cacheName, cacheForm);

    this.setState({
      formData,
      filteredApplications,
    });
  };

  setSelected = (updatedFormElement: any, value: string, index: number) => {
    let option = updatedFormElement.options[index];

    if (updatedFormElement.selected) {
      //single option choice
      updatedFormElement.selected = value;
    } else {
      //multiple option choice
      option.selected = !option.selected;
    }
  };

  getSelected = (formData: any) => {
    const formGroups: ShapedTagGroup[] = Array.from(formData.values());
    let selected: number[] = [];

    formGroups.forEach((group) => {
      if (group.selected && group.selected !== Constants.defaultSelected) {
        selected.push(Number(group.selected));
      } else {
        group.options.forEach((option) => {
          if (option.selected) {
            selected.push(Number(option.value));
          }
        });
      }
    });
    return selected;
  };

  filterApplications = (selected: number[], applications: Application[]) => {
    return applications.filter((app) => {
      if (selected.every((r) => app.tagsId.includes(r))) {
        return app;
      }
    });
  };

  calcTotalByTag = (id: number, applications: Application[]) => {
    let count = 0;
    applications.map((app) => {
      if (app.tagsId.includes(id)) {
        count += 1;
      }
    });
    return count;
  };

  calculateAvailable = (filteredApplications: Application[], updatedFormElement: any, tagGroup: ShapedTagGroup) => {
    const differentGroup = tagGroup.id !== updatedFormElement.id;
    const sameGroupButMultiple = tagGroup.id === updatedFormElement.id && tagGroup.multipleSelection;
    const selectedIsDefault = tagGroup.id === updatedFormElement.id && tagGroup.selected === Constants.defaultSelected;

    if (differentGroup || sameGroupButMultiple || selectedIsDefault) {
      tagGroup.total = 0;
      tagGroup.options.map((option) => {
        option.total = this.calcTotalByTag(parseInt(option.value), filteredApplications);
        tagGroup.total += option.total;
      });
    }
    tagGroup.collapsed = tagGroup.total === 0 && !tagGroup.isParentGroup ? true : tagGroup.collapsed;
    return tagGroup;
  };

  reset = () => {
    CacheService.removeFromCache(this.cacheName);
    this.updateApplications();
  };

  toggleGroup = (name: string) => {
    const newFormData = CloneDeep(this.state.formData);

    let updatedFormElement = newFormData.get(name);
    updatedFormElement.collapsed = !updatedFormElement.collapsed;

    this.setState({
      formData: newFormData,
    });
  };

  toggleFilterOptions = () => {
    this.setState({
      filtersVisible: !this.state.filtersVisible,
    });
  };

  handleAppClick = (id: number) => {
    this.selectedAppId = id;
    this.setState({
      redirect: !this.state.redirect,
    });
  };

  handleRedirect = (id: number) => {
    this.selectedAppId = id;
    this.setState({ redirect: true });
  };

  hideToast = () => {
    return this.setState({
      responseCode: 0,
      responseMessage: '',
    });
  };

  render() {
    const { redirect, loading, filteredApplications, formData, filtersVisible, isLargeDevice, responseCode, responseMessage } = this.state;

    const { selectedAppType } = this.props;

    if (redirect) {
      return <Redirect to={{ pathname: `/type/${this.props.selectedAppType.name}/download/${this.selectedAppId}` }} push={true} />;
    }

    const isLoading = loading || this.props.updatingApplications;

    return (
      <SelectorPage
        currentRoute={this.currentRoute}
        filteredApplications={filteredApplications}
        formData={formData}
        appType={selectedAppType}
        applicationTypeGroups={this.props.applicationTypeGroups}
        filtersVisible={filtersVisible}
        isLargeDevice={isLargeDevice}
        onFilter={this.handleFilterChange}
        onBackDropClick={this.toggleFilterOptions}
        onToggleGroup={this.toggleGroup}
        onToggleFilterOptions={this.toggleFilterOptions}
        onAppClick={this.handleAppClick}
        onClear={this.reset}
        loading={isLoading}
        responseCode={responseCode}
        responseMessage={responseMessage}
        hideToast={this.hideToast}
        allApplications={this.props.applications}
        handleRedirect={this.handleRedirect}
      />
    );
  }
}
