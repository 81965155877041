import styled from 'styled-components';
import { TextRegularMedium } from '../../../common/styles/shared';

export const PopUpBodyListText: any = styled(TextRegularMedium).attrs({ className: 'PopUpBodyText' })`
  line-height: 1.36;
`;

export const PopUpListContainer: any = styled.div.attrs({ className: 'PopUpListContainer' })`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.6rem;
  overflow: hidden;

  :last-of-type {
    margin-bottom: 0;
  }
`;

export const VersionRow: any = styled.div.attrs({ className: 'VersionRow' })`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.6rem;

  :last-of-type {
    margin-bottom: 0;
  }
`;

export const VersionText: any = styled(TextRegularMedium).attrs({ className: 'VersionText' })`
  width: 100%;
  word-break: break-all;
`;

export const FilterTagsContainer: any = styled.div.attrs({ className: 'FeatureContainer' })`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const Divider: any = styled(TextRegularMedium).attrs({ className: 'Divider' })`
  display: flex;
  justify-content: center;
  align-items: center;
  color: grey;
  padding: 0 0.8rem;
`;
