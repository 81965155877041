import styled from 'styled-components';
import { Devices } from '../../common/styles/devices';
import { SectionTitle } from '../../common/styles/shared';

export const FileSelectorContainer: any = styled.div.attrs({ className: 'FileSelectorContainer' })`
  margin: 3.2rem 0;

  @media ${Devices.breakMedium} {
    margin: 3.2rem 1.6rem;
  }
`;

export const FileSelectorMenu: any = styled.div.attrs({ className: 'FileSelectorMenu' })`
  display: flex;
  flex-direction: column;
  margin-bottom: 3.2rem;

  @media ${Devices.breakMedium} {
    flex-direction: row;
  }
`;

export const FileSelectorHeadLine: any = styled(SectionTitle).attrs({ className: 'FileSelectorHeadLine' })`
  margin-bottom: 1.6rem;

  @media ${Devices.breakMedium} {
    flex-direction: row;
  }
`;
