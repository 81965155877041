import { FileUploadInput, FileUploadButton, FileUploadText } from './file-upload-style';

export const FileUpload = ({ ...props }) => (
  <label>
    <FileUploadInput {...props}></FileUploadInput>
    <FileUploadButton>
      <FileUploadText>+ Add new file</FileUploadText>
    </FileUploadButton>
  </label>
);
