import { ApiService } from './api-service';

export const TagGroupService = {
  get: async (tagsGroupsId) => {
    let options = await ApiService.getTags();
    let groups = await ApiService.getTagGroups();
    groups = groupByTagGroup(options, groups).filter((group) => tagsGroupsId.includes(group.id));
    return groups.sort((a, b) => a.level - b.level);
  },
};

const groupByTagGroup = (options, groups) => {
  groups = groups.map((group) => {
    group.options = options.filter((option) => option.tagGroup === group.id);
    group.options.sort((a, b) => a.level - b.level);
    return group;
  });
  return setParentGroups(groups);
};

const setParentGroups = (groups) => {
  return groups.map((group) => {
    group.isParentGroup = group.options.length ? false : true;
    group.collapsed = group.options.length ? group.collapsed : true;
    return group;
  });
};
