import { FileCard } from '../file-card/file-card';
import { FileGroupContainer, FileGroupHeadLine, FileList } from '../../../common/styles/shared';
import { File, FileType } from '../../../../interfaces/files';
import { ShapedUser } from '../../../../interfaces/users';

interface FileGroupProps {
  user: ShapedUser;
  groupIndex: number;
  fileGroup: FileType;
  onDownload: (groupIndex: number, file: File) => void;
}

export const FileGroup = ({ user, groupIndex, fileGroup, onDownload }: FileGroupProps) => (
  <FileGroupContainer active={user}>
    <FileGroupHeadLine>{fileGroup.name}</FileGroupHeadLine>
    <FileList>
      {fileGroup.files.map((file, index) => {
        return (
          <FileCard
            key={index}
            file={file}
            user={user}
            groupIndex={groupIndex}
            isDownloading={file.isDownloading || false}
            onDownload={onDownload}
          />
        );
      })}
    </FileList>
  </FileGroupContainer>
);
