import React from 'react';
import { PopUpContainer, PopUpHeader, PopUpHeaderText, PopUpCloseIcon, PopUpBody, PopUpFooter, PopUpCloseButton } from './popup-style';

interface PopUpProps {
  title: any; // PropTypes.string,
  children: any; // PropTypes.object,
  onClose: any; // PropTypes.func,
}

export const PopUp = ({ title, children, onClose }: PopUpProps) => (
  <PopUpContainer>
    <PopUpHeader>
      <PopUpHeaderText>{title}</PopUpHeaderText>
      <PopUpCloseIcon onClick={onClose}></PopUpCloseIcon>
    </PopUpHeader>
    <PopUpBody>{children}</PopUpBody>
    <PopUpFooter>
      <PopUpCloseButton onClick={onClose}>Close</PopUpCloseButton>
    </PopUpFooter>
  </PopUpContainer>
);
