import { Application } from '../interfaces/applications';
import { FileType } from '../interfaces/files';
import { Constants } from './constant-service';
import { last } from 'lodash';

export const PopulateEditFormService = {
  populate: (app, form) => {
    //TODO: Think about a way to generalise this.
    getName(app, form);
    getDescription(app, form);
    getActive(app, form);
    getVersion(app, form);
    getReleaseNotes(app, form);
    getFirmWare(app, form);
    getCustomType(app, form);
    getTags(app, form);
    getFiles(Constants.swFiles, app, form);
    getFiles(Constants.docFiles, app, form);
    return form;
  },
};

const getName = (app: Application, form) => {
  let group = form.get(Constants.uploadForm.name.name);
  group.value = app.name;
  group.valid = true;
  form.set(Constants.uploadForm.name.name, group);
  return form;
};

const getDescription = (app: Application, form) => {
  let group = form.get(Constants.uploadForm.description.name);
  group.value = app.description;
  group.valid = true;
  form.set(Constants.uploadForm.description.name, group);
  return form;
};

const getActive = (app: Application, form) => {
  let group = form.get(Constants.uploadForm.active.name);
  group.selected = app.active;
  form.set(Constants.uploadForm.active.name, group);
  return form;
};

const getVersion = (app: Application, form) => {
  let group = form.get(Constants.uploadForm.version.name);
  group.value = app.version;
  group.valid = true;
  form.set(Constants.uploadForm.version.name, group);
  return form;
};

const getReleaseNotes = (app: Application, form) => {
  let group = form.get(Constants.uploadForm.releaseNotes.name);
  if (group == null) {
    return form;
  }
  if (app.releaseNotes?.length > 0) {
    const lastNote: any = last(app.releaseNotes);
    group.value = lastNote.text;
  } else {
    group.value = '';
  }
  group.valid = true;
  form.set(Constants.uploadForm.releaseNotes.name, group);
  return form;
};

const getFirmWare = (app: Application, form) => {
  let group = form.get(Constants.uploadForm.firmWare.name);
  if (group == null) {
    return form;
  }
  group.value = app.firmware;
  group.valid = true;
  form.set(Constants.uploadForm.firmWare.name, group);
  return form;
};

const getCustomType = (app: Application, form) => {
  let group = form.get(Constants.uploadForm.customType.name);
  if (group == null) {
    return form;
  }
  group.value = app.customType;
  group.valid = true;
  form.set(Constants.uploadForm.customType.name, group);
  return form;
};

const getTags = (app: Application, form) => {
  form.forEach((item) => {
    item.valid = true;

    //input fields does not have id and single group active is not considered a tag
    if (item.id && item.name !== Constants.uploadForm.active.name) {
      item.options.forEach((option) => {
        if (item.multipleSelection) {
          option.selected = app.tagsId.includes(option.value);
        } else if (app.tagsId.includes(option.value)) {
          item.selected = option.value;
        }
      });
    }
  });
  return form;
};

const getFiles = (name, app, form) => {
  let group = form.get(name);
  group.valid = true;

  app.fileGroups.forEach((appGroup: FileType) => {
    let formGroup = group.options.find((option) => option.name === appGroup.name);
    if (formGroup) {
      formGroup.multipleSelection
        ? (formGroup.files = handleMultipleFiles(appGroup.files))
        : (formGroup.file = handleSingleFile(appGroup.files[0]));
    }
  });
};

const handleMultipleFiles = (files) => {
  return files.map((file) => {
    file.status = Constants.status.unchanged;
    return file;
  });
};

const handleSingleFile = (file) => {
  file.status = Constants.status.unchanged;
  return file;
};
