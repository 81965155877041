/**
 * Returns true if the array is empty or uninitialized.
 */
export function isEmpty(items) {
  return items === null || items === undefined || items.length === 0;
}

/**
 * Returns the last item in the array or null if the array is empty.
 */
export function last(items) {
  return isEmpty(items) ? null : items[items.length - 1];
}

/**
 * Returns the first item in the array or null if the array is empty.
 */
export function first(items) {
  return isEmpty(items) ? null : items[0];
}
