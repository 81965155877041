import { FormDeleteBottom } from './form-parts-style';
import { DeleteBtn } from '../admin-style';

interface DeleteProps {
  onDelete: any;
}

export const DeleteButtons = (props: DeleteProps) => {
  const { onDelete } = props;

  return (
    <FormDeleteBottom>
      <DeleteBtn onClick={onDelete}>Delete</DeleteBtn>
    </FormDeleteBottom>
  );
};
