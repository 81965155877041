import styled from 'styled-components';
import { Colors } from '../../styles/colors';
import { Devices } from '../../styles/devices';
import { IconButton, RowContainer } from '../../styles/shared';
import CloseIcon from '../../../../assets/images/close.svg';

export const OptionsHeadLine: any = styled.div.attrs({ className: 'OptionsHeadLine' })`
  height: 4.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1.2rem;
  padding-right: 1.6rem;
`;

export const Close: any = styled(IconButton).attrs({ className: 'Close' })`
  width: 1rem;
  height: 1rem;
  background: url(${CloseIcon}) center center;
  background-repeat: no-repeat;

  @media ${Devices.breakLarge} {
    display: none;
  }
`;

export const Clear: any = styled.div.attrs({ className: 'Clear' })`
  display: none;

  @media ${Devices.breakLarge} {
    display: block;
  }
`;

export const DropDownHeader: any = styled.div.attrs({ className: 'DropDownHeader' })`
  height: 4.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1.2rem;
  padding-right: 1.6rem;
`;

export const DefaultDropDownHeader: any = styled(DropDownHeader).attrs({ className: 'DefaultDropDownHeader' })`
  background: ${Colors.bgPrimary};
`;

export const SubGroupDropDownHeader: any = styled(DropDownHeader).attrs({ className: 'SubGroupDropDownHeader' })`
  background: ${Colors.bgSecondary};
  padding-left: 1.6rem;
  color: ${Colors.textSecondary};
`;

export const ParentBody: any = styled.div.attrs({ className: 'ParentBody' })`
  display: ${(props: any) => (props.hidden ? 'none' : 'block')};
`;

export const OptionsGroupDropDownBody: any = styled.div.attrs({ className: 'OptionsGroupDropDownBody' })`
  padding: 1.2rem 1.6rem;
  display: ${(props: any) => (props.hidden ? 'none' : 'block')};
`;

export const OptionsRow: any = styled(RowContainer).attrs({ className: 'OptionsRow' })`
  margin-top: 1.6rem;
  cursor: pointer;

  :first-of-type {
    margin-top: 0;
  }

  ${({ disabled }) =>
    disabled &&
    `
    opacity: .5;
    pointer-events: none;
  `}
`;
