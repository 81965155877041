import React from 'react';
import { MyAppsGreyText } from './my-apps-style';
import { MyAppsText } from '../../../common/texts/my-apps-text';

export const MyAppsGrey = () => (
  <React.Fragment>
    <MyAppsGreyText>
      <MyAppsText />
    </MyAppsGreyText>
  </React.Fragment>
);
