import styled from 'styled-components';
import { Devices } from '../../styles/devices.js';
import { Colors } from '../../styles/colors.js';
import { Fonts } from '../../styles/fonts.js';
import { BoxSection } from '../../styles/shared.js';

export const ApplicationsContainer: any = styled.div.attrs({ className: 'ApplicationsContainer' })`
  margin: 1.6rem 0;
  right: 1.6rem;

  @media ${Devices.breakLarge} {
    position: absolute;
    top: 30.4rem;
    left: 30rem;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
  }

  @media ${Devices.breakMegaLarge} {
    left: calc(30rem + 10%);
    right: 10%;
  }
`;

export const ApplicationsInner: any = styled.div.attrs({ className: 'ApplicationsInner' })`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 0.2rem;

  @media ${Devices.breakMedium} {
    padding: 0 1.6rem 0 0;
    width: 100%;
  }

  @media ${Devices.breakXLarge} {
    width: 100%;
  }

  @media ${Devices.breakMegaLarge} {
    padding: 0 1.6rem 0 0;
    width: 100%;
  }
`;

export const ApplicationsInformation: any = styled(BoxSection).attrs({ className: 'ApplicationsInformation' })`
  padding: 1.6rem;
  width: 100%;

  @media ${Devices.breakSmall} {
    position: sticky;
    top: 0;
  }

  @media ${Devices.breakMedium} {
    margin-left: 1.6rem;
  }

  h1 {
    font-size: ${Fonts.fontSizeXLarge};
    font-weight: bold;
    color: ${Colors.textAccentPrimary};
  }

  p {
    font-size: ${Fonts.fontSizeMedium};
    font-weight: normal;
    line-height: 1.6rem;
    color: ${(props: any) => (props.color ? props.color : Colors.textPrimary)};
  }

  a {
    color: ${Colors.accentSecondary};
  }

  a:visited {
    color: ${Colors.accentSecondary};
  }
`;
