import React from 'react';
import { ToSelectorButton } from '../common/back-button/to-selector-button';
import { Details } from './details/details';
import { FileSelector } from './file-selector/file-selector';
import { Colors } from '../common/styles/colors';
import { PageHeader, FlexStretch } from './download-page-style';
import { PopUp } from '../common/popup/popup';
import { PopUpBackDrop } from './download-page-style';
import { DetailPopUpBody } from './popup/popup-body/detail-body';
import { ReleaseNotesPopUpBody } from './popup/popup-body/release-notes-body';
import { ToastBottom } from '../common/user-messages/toast-bottom';
import { PageNotFound } from '../common/error-pages/page-not-found';
import { isStandardApplication } from '../../services/constant-service';
import { PageScroll, PageTitle, Margin, TextRegularDefault } from '../common/styles/shared';

interface DownloadPageProps {
  user: any; // PropTypes.object,
  appDetails: any; // PropTypes.object,
  onDownLoad: any; // PropTypes.func,
  showDetails: any; // PropTypes.func,
  showReleaseNotes: any; // PropTypes.func,
  backDrop: any; // PropTypes.object,
  responseCode: any; // PropTypes.number,
  responseMessage: any; // PropTypes.string,
  closePopUp: any; // PropTypes.func,
  hideToast: any; // PropTypes.func,
  selectedAppType: any; // PropTypes.object,
}

export const DownloadPage = ({
  user,
  appDetails,
  onDownLoad,
  showDetails,
  showReleaseNotes,
  backDrop,
  responseCode,
  responseMessage,
  closePopUp,
  hideToast,
  selectedAppType,
}: DownloadPageProps) => {
  const fileGroups = appDetails.fileGroups;

  return (
    <React.Fragment>
      <PopUpBackDrop visible={backDrop.show} onClick={closePopUp}></PopUpBackDrop>
      {backDrop.details && (
        <PopUp title={`Details - ${appDetails.applicationCode}`} onClose={closePopUp}>
          <DetailPopUpBody app={appDetails} />
        </PopUp>
      )}
      {backDrop.releaseNotes && (
        <PopUp title={`Release notes - ${appDetails.applicationCode}`} onClose={closePopUp}>
          <ReleaseNotesPopUpBody app={appDetails} />
        </PopUp>
      )}

      {responseCode !== 0 && responseCode !== 404 && <ToastBottom responseCode={responseCode} text={responseMessage} onClose={hideToast} />}

      {responseCode !== 0 && responseCode === 404 && <PageNotFound />}

      {responseCode !== 404 && (
        <PageScroll>
          <PageHeader>
            <Margin bottom="1.6rem">
              <ToSelectorButton selectedAppType={selectedAppType} />
            </Margin>
            <FlexStretch>
              <PageTitle color={Colors.textAccentPrimary}>
                {appDetails.applicationCode}
                <TextRegularDefault>{appDetails.name}</TextRegularDefault>
              </PageTitle>
            </FlexStretch>
          </PageHeader>

          <Details
            details={appDetails}
            showDetails={showDetails}
            showReleaseNotes={showReleaseNotes}
            showRequirements={isStandardApplication(selectedAppType.id)}
          />

          {fileGroups && <FileSelector app={appDetails} user={user} fileGroups={fileGroups} onDownload={onDownLoad} />}
        </PageScroll>
      )}
    </React.Fragment>
  );
};
