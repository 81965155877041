import React, { PureComponent } from 'react';
import { SearchInput } from '../../common/inputs/search/search-input';
import { TextRegularMedium, TextRegularSmall } from '../../common/styles/shared';
import { SearchHeader } from '../../common/inputs/search/search-input-style';

interface SearchContainerProps {
  list: any; // PropTypes.array,
  resultComponent: any; // PropTypes.func,
  placeHolder: any; // PropTypes.string,
  onSelected: any; // PropTypes.func,
  onSearchBlur: any; // PropTypes.func,
  prepopulateList?: any; // PropTypes.bool,
  headerTitle?: any; // PropTypes.string,
  downloadingFileId?: any; // PropTypes.number,
  width?: any; // PropTypes.string,
  isFileSearch?: any; // PropTypes.bool,
  onSearchChange?: any; // PropTypes.func,
}

interface SearchContainerState {
  filteredList: any;
  showEraseButton: boolean;
  cursor: number;
  value: string;
  showAllItems: boolean;
}

export class SearchContainer extends PureComponent<SearchContainerProps, SearchContainerState> {
  private listThreshold = 50;

  constructor(props: SearchContainerProps) {
    super(props);

    this.state = {
      filteredList: this.props.prepopulateList ? this.props.list : [],
      showEraseButton: false,
      cursor: -1,
      value: '',
      showAllItems: false,
    };
  }

  change = (input) => {
    const { list, onSearchChange, isFileSearch } = this.props;
    const value = input.toLowerCase();
    let filteredList = [];

    if (value !== '') {
      filteredList = isFileSearch ? this.findFiles(list, value) : this.findApps(list, value);

      if (onSearchChange) {
        onSearchChange(filteredList);
      }

      this.setState({
        filteredList,
        showEraseButton: true,
        value: input,
      });
    } else {
      this.init();
    }
  };

  findFiles = (items, value) => {
    return items.filter((file) => file.name.toLowerCase().includes(value) || file.searchTag?.toLowerCase().includes(value));
  };

  findApps = (items, value) => {
    return items.filter((app) => app.applicationCode.toLowerCase().includes(value) || app.name.toLowerCase().includes(value));
  };

  blur = () => {
    const { onSearchBlur } = this.props;
    onSearchBlur();
    this.init();
  };

  handleRowHover = () => {
    this.setState({ cursor: -1 });
  };

  handleResultSelect = (selected) => {
    const { onSelected } = this.props;
    onSelected(selected);
  };

  handleShowAll = () => {
    this.setState({ showAllItems: true });
  };

  handleKeyDown = (e) => {
    let { cursor, filteredList } = this.state;
    cursor = cursor === -1 ? 0 : cursor;
    if (e.keyCode === 40 && cursor < filteredList.length - 1) {
      this.setState((prevState) => ({
        cursor: prevState.cursor + 1,
      }));
    } else if (e.keyCode === 38 && cursor > 0) {
      this.setState((prevState) => ({
        cursor: prevState.cursor - 1,
      }));
    } else if (e.keyCode === 13) {
      const app = filteredList[cursor];
      if (app == undefined) {
        e.preventDefault();
        return false;
      }
      this.handleResultSelect(app);
    } else if (e.keyCode === 27) {
      this.blur();
    }
  };

  init = () => {
    const filteredList = this.props.prepopulateList ? this.props.list : [];
    return this.setState({
      filteredList,
      showEraseButton: false,
      cursor: -1,
      value: '',
    });
  };

  render() {
    const { value, filteredList, showEraseButton, cursor, showAllItems } = this.state;
    const { list, downloadingFileId, headerTitle, prepopulateList } = this.props;

    let displayedList = [];
    let displayShowAllButton = false;
    if (showAllItems || filteredList.length <= this.listThreshold) {
      displayedList = filteredList;
    } else {
      displayedList = filteredList.slice(0, this.listThreshold);
      displayShowAllButton = true;
    }

    const searchHeader = prepopulateList ? (
      <SearchHeader>
        <TextRegularMedium>{headerTitle}</TextRegularMedium>
        <TextRegularSmall>Showing {`${displayedList.length}/${list.length}`}</TextRegularSmall>
      </SearchHeader>
    ) : null;

    return (
      <React.Fragment>
        {searchHeader}
        <SearchInput
          type="text"
          value={value}
          placeHolder={this.props.placeHolder || 'Search item ID or name'}
          showEraseButton={showEraseButton}
          onSearchChange={this.change}
          onKeyDown={this.handleKeyDown}
          onSearchErase={this.init}
          onSearchBlur={this.props.isFileSearch != null ? this.blur : undefined}
        />
        <this.props.resultComponent
          cursor={cursor}
          onKeyDown={this.handleKeyDown}
          filteredList={displayedList}
          onResultSelect={this.handleResultSelect}
          onRowHover={this.handleRowHover}
          downloadingFileId={downloadingFileId}
          showAll={this.handleShowAll}
          displayShowAllButton={displayShowAllButton}
        />
      </React.Fragment>
    );
  }
}
