import styled from 'styled-components';
import { Devices } from '../styles/devices';
import LogoElectric from '../../../assets/images/logo_green.svg';
import LifeIsOnGreen from '../../../assets/images/logo_life_is_on_green.svg';

export const GreenLogo: any = styled.svg.attrs({ className: 'GreenLogo' })`
  width: 7.8rem;
  height: 2.7rem;
  background: url(${LogoElectric}) center left;
  background-repeat: no-repeat;

  @media ${Devices.breakMedium} {
    width: 20rem;
    height: 4.4rem;
    background: url(${LifeIsOnGreen}) center right;
    background-repeat: no-repeat;
  }
`;
