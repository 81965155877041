import styled from 'styled-components';
import { Colors } from '../../../../common/styles/colors';
import { TextRegularDefaultBold } from '../../../../common/styles/shared';

export const StatusContainer: any = styled.div.attrs({ className: 'StatusContainer' })`
  display: flex;
  justify-content: center;
  padding: 0 0.2rem;
`;

export const Deleted: any = styled(StatusContainer).attrs({ className: 'Deleted' })`
  background: ${Colors.deleted};
`;

export const New: any = styled(StatusContainer).attrs({ className: 'New' })`
  background: ${Colors.new};
`;

export const NewVersion: any = styled(StatusContainer).attrs({ className: 'NewVersion' })`
  background: ${Colors.updated};
`;

export const FileStatusText: any = styled(TextRegularDefaultBold).attrs({ className: 'FileStatusContainer' })`
  text-transform: uppercase;
  color: ${Colors.textLight};
  white-space: nowrap;
  overflow: hidden;
`;
