import React from 'react';
import { PopUpContainer, PopUpHeader, PopUpHeaderText, PopUpCloseIcon, PopUpBody, PopUpFooter, PopupActionButton } from './popup-style';

interface PopUpActionProps {
  title: any; // PropTypes.string,
  component: any; // PropTypes.func,
  onClose: any; // PropTypes.func,
  onAction: any; // PropTypes.func,
}

export const PopUpAction = ({ title, onClose, onAction, component: BodyComponent }: PopUpActionProps) => (
  <PopUpContainer>
    <PopUpHeader>
      <PopUpHeaderText>{title}</PopUpHeaderText>
      <PopUpCloseIcon onClick={onClose}></PopUpCloseIcon>
    </PopUpHeader>
    <PopUpBody>
      <BodyComponent />
    </PopUpBody>
    <PopUpFooter>
      <PopupActionButton onClick={onAction}>{title}</PopupActionButton>
    </PopUpFooter>
  </PopUpContainer>
);
