import { Component } from 'react';
import { ListPage } from './sortable-list-page';
import { getListItems, getColumns, getDefaultColumnName } from './contents';
import { AppType, Application } from '../../interfaces/applications';
import { TagGroup } from '../../interfaces/tags';
import { FileTypeGroup } from '../../interfaces/files';

interface ListContainerProps {
  tagGroups: TagGroup[];
  applications: Application[];
  fileGroups: FileTypeGroup[];
  selectedAppType: AppType;
  path: string;
}

interface ListContainerState {
  loading: boolean;
  list: any;
  filteredList: any;
  showEraseButton: boolean;
  responseCode: number;
  columns: any;
  searchableColumns: any;
}

export class ListContainer extends Component<ListContainerProps, ListContainerState> {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      list: [],
      filteredList: [],
      showEraseButton: false,
      responseCode: 0,
      columns: null,
      searchableColumns: [],
    };
  }

  async componentDidMount() {
    try {
      const { path } = this.props;
      const items = await getListItems(path);
      const columns = getColumns(path);

      this.init(items, columns);
    } catch (error: any) {
      return this.setState({
        responseCode: error.response.status,
        loading: false,
      });
    }
  }

  init = (items, columns) => {
    const searchableColumns = columns.filter((column) => column.searchable).map((searchableColumn) => searchableColumn.name);

    this.setState(
      {
        list: items,
        filteredList: items,
        loading: false,
        columns: columns,
        searchableColumns: searchableColumns,
      },
      () => this.sortList(getDefaultColumnName(columns))
    );
  };

  filter = (input) => {
    const { list: statisticsList } = this.state;
    let filteredList = undefined;
    const value = input.toLowerCase();

    if (value !== '') {
      filteredList = statisticsList.filter((app) =>
        this.state.searchableColumns.some((column) => app[column].toString().toLowerCase().includes(value))
      );

      this.setState({
        showEraseButton: true,
        filteredList,
      });
    } else {
      this.resetSearch();
    }
  };

  resetSearch = () => {
    const { list, columns } = this.state;
    const currentActive = columns.find((col) => col.active);
    this.sort(currentActive, Array.from(list));

    this.setState({
      showEraseButton: false,
    });
  };

  sortList = (selectedColumn) => {
    const { columns, filteredList } = this.state;
    let obj = columns.find((col) => col.name === selectedColumn);
    if (obj.enableSort) {
      let currentActive = columns.find((col) => col.active);

      if (currentActive.name !== obj.name) {
        obj.active = true;
        currentActive.active = false;
      }

      this.sort(obj, filteredList);
      this.setState({ columns });
    }
  };

  sortByColumn = (key, reverse) => {
    const moveSmaller = reverse ? 1 : -1;
    const moveLarger = reverse ? -1 : 1;
    const name = key.name;

    return (a, b) => {
      const first = key.isDate ? new Date(a[name]) : a[name];
      const second = key.isDate ? new Date(b[name]) : b[name];
      if (first < second) {
        return moveSmaller;
      }
      if (first > second) {
        return moveLarger;
      }
      return 0;
    };
  };

  sort = (obj, list) => {
    list.sort(this.sortByColumn(obj, obj.desc));

    obj.desc = !obj.desc;

    this.setState({ filteredList: [...list] });
  };

  render() {
    const { path, selectedAppType } = this.props;
    const { loading, filteredList, showEraseButton, columns, responseCode } = this.state;

    return (
      <ListPage
        loading={loading}
        filteredList={filteredList}
        onSearchChange={this.filter}
        onSearchErase={this.resetSearch}
        showEraseButton={showEraseButton}
        columns={columns}
        onSortList={this.sortList}
        responseCode={responseCode}
        path={path}
        selectedAppType={selectedAppType}
      />
    );
  }
}
