import React, { Component } from 'react';
import { cloneDeep as CloneDeep } from 'lodash';
import { ApplicationFormCreateService } from '../../../services/application-form-create-service';
import { ApplicationFormSubmitService } from '../../../services/application-form-submit-service';
import { ErrorCodes } from '../../../services/constant-service';
import { AddAppPage } from './add-app-page';
import { Response } from '../../../interfaces/common';
import { TagGroup } from '../../../interfaces/tags';
import { AppType, Application } from '../../../interfaces/applications';
import { ShapedUser } from '../../../interfaces/users';
import { FileTypeGroup } from '../../../interfaces/files';

interface AddAppProps {
  getUser: () => ShapedUser; //PropTypes.func,
  tagGroups: TagGroup[];
  applications: Application[];
  fileGroups: FileTypeGroup[];
  selectedAppType: AppType;
  redirectUpdate: () => Promise<void>;
}

interface AddAppState {
  loading: boolean;
  uploading: boolean;
  form: any;
  originalForm: any;
}

export class AddAppContainer extends Component<AddAppProps, AddAppState> {
  constructor(props: AddAppProps) {
    super(props);

    this.state = {
      loading: true,
      uploading: false,
      form: undefined,
      originalForm: undefined,
    };
  }

  componentDidMount() {
    this.init();
  }

  init = () => {
    const groups = CloneDeep(this.props.tagGroups);
    const fileGroups = CloneDeep(this.props.fileGroups);
    const originalForm = ApplicationFormCreateService.getForm(groups, fileGroups, this.props.selectedAppType);
    const form = CloneDeep(originalForm);

    this.setState({
      form,
      originalForm,
      loading: false,
      uploading: false,
    });
  };

  submit = async (form: any): Promise<Response> => {
    const { getUser, selectedAppType } = this.props;
    const user = getUser();

    if (!user) {
      return ApplicationFormSubmitService.setErrorMessage(ErrorCodes.Unauthorized);
    } else {
      if (!user.isUpLoader) {
        return ApplicationFormSubmitService.setErrorMessage(ErrorCodes.Forbidden);
      }
    }

    this.setState({
      uploading: true,
    });

    try {
      const resp = await ApplicationFormSubmitService.saveApplication(form, selectedAppType.id, user);
      return this.submitFiles(resp);
    } catch (error: any) {
      this.setState({ uploading: false });
      return {
        status: error.response.status,
        message: error.response.data.error,
      };
    }
  };

  submitFiles = async (app: Application): Promise<Response> => {
    const { form } = this.state;
    try {
      await ApplicationFormSubmitService.saveFiles(app.id, form);
      this.init();
      return {
        status: 200,
        message: `Item ${app.applicationCode}
          successfully added `,
      };
    } catch (error: any) {
      this.setState({ uploading: false });
      return ApplicationFormSubmitService.setErrorMessage(error.response.status);
    }
  };

  render() {
    const { form, originalForm, loading, uploading } = this.state;
    const { applications, selectedAppType, redirectUpdate } = this.props;

    return (
      <React.Fragment>
        {!loading && (
          <AddAppPage
            selectedAppType={selectedAppType}
            form={form}
            uploading={uploading}
            applications={applications}
            originalForm={originalForm}
            onSubmit={this.submit}
            redirectUpdate={redirectUpdate}
          />
        )}
      </React.Fragment>
    );
  }
}
