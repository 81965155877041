import React from 'react';
import { Link } from 'react-router-dom';
import { Constants } from '../../../services/constant-service';
import { MenuIconRow, MenuIcon } from '../menu/menu-style';
import { TextRegularMedium } from '../../common/styles/shared';
import Communication from '../../../assets/images/communication_bubbles2.svg';

export const ViewFeedbackList = ({ onClick }: { onClick: any }) => (
  <Link to={Constants.routes.feedbackList} /* push="true" REMOVE? */>
    <MenuIconRow onClick={onClick}>
      <MenuIcon img={Communication}></MenuIcon>
      <TextRegularMedium>View customer feedback</TextRegularMedium>
    </MenuIconRow>
  </Link>
);
