import React from 'react';
import { Constants, isStandardApplication } from '../../../services/constant-service';
import { RadioButton } from '../../common/inputs/radiobutton/radiobutton';
import { TextInput } from '../../common/inputs/text-input/text-input';
import { TextAreaInput } from '../../common/inputs/text-area/text-area';
import { DescriptionExample } from './description-example';
import ArrowUp from '../../../assets/images/chevron-up-blue.svg';
import ArrowDown from '../../../assets/images/chevron-down-blue.svg';

import {
  FormSection,
  FormSectionHeader,
  TextInputContainer,
  TextAreaContainer,
  InputControl,
  ControlHeadline,
  FlexSpaceBetween,
  TemplateButton,
  ArrowIcon,
} from './form-parts-style';
import { Margin, RowContainer, TextRegularMedium, ErrorMessage, Required } from '../../common/styles/shared';
import { AppType } from '../../../interfaces/applications';

interface BasicsFormProps {
  form: any; // PropTypes.object,
  onChange: (elem: any, value?: string | undefined, index?: number | undefined) => void;
  onToggleDescExemple: () => void;
  showDescExample: boolean;
  selectedAppType: AppType;
}

export const BasicsFormPart = (props: BasicsFormProps) => {
  const { form } = props;
  const name = form.get(Constants.uploadForm.name.name);
  const description = form.get(Constants.uploadForm.description.name);
  const active = form.get(Constants.uploadForm.active.name);

  return (
    <FormSection>
      <FormSectionHeader>Item basics</FormSectionHeader>
      <InputControl>
        <ControlHeadline>
          {name.label}
          <Required>*</Required>
        </ControlHeadline>
        <TextInputContainer>
          <TextInput
            type="text"
            value={name.value}
            name={name.name}
            width="100%"
            placeholder={name.placeHolder}
            error={name.touched && !name.valid}
            onChange={(e) => props.onChange(e)}
            maxLength={50}
          />
        </TextInputContainer>

        {name.touched && !name.valid && (
          <React.Fragment>
            <ErrorMessage visible={name.value === ''}>Item name is required</ErrorMessage>
            <ErrorMessage visible={name.value !== ''}>Item name already exists</ErrorMessage>
          </React.Fragment>
        )}
      </InputControl>

      <InputControl>
        <TextAreaContainer>
          <FlexSpaceBetween>
            <ControlHeadline>
              {description.label}
              <Required>*</Required>
            </ControlHeadline>
            {isStandardApplication(props.selectedAppType.id) && (
              <TemplateButton onClick={props.onToggleDescExemple}>
                Template & example
                <ArrowIcon img={props.showDescExample ? ArrowUp : ArrowDown}></ArrowIcon>
              </TemplateButton>
            )}
          </FlexSpaceBetween>
          {props.showDescExample && <DescriptionExample />}
          <TextAreaInput
            name={description.name}
            value={description.value}
            width="100%"
            placeholder={description.placeHolder}
            onChange={(e) => props.onChange(e)}
          />
        </TextAreaContainer>
      </InputControl>

      <InputControl>
        <ControlHeadline>{active.label}</ControlHeadline>
        {active.options.map((option, index) => {
          return (
            <Margin top="1.6rem" key={index}>
              <RowContainer onClick={() => props.onChange(active.name, option.value, index)}>
                <RadioButton checked={active.selected === option.value} />
                <TextRegularMedium>{option.label}</TextRegularMedium>
              </RowContainer>
            </Margin>
          );
        })}
      </InputControl>
    </FormSection>
  );
};
