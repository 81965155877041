import styled from 'styled-components';
import { Devices } from '../../common/styles/devices';
import { Colors } from '../../common/styles/colors';
import { Button } from '../../common/styles/shared';

export const ResultList: any = styled.div.attrs({ className: 'ResultList' })`
  position: absolute;
  z-index: 1;
  width: 100%;
  background: ${Colors.bgSecondary};
`;

export const ListRow: any = styled.div.attrs({ className: 'ListRow' })`
  padding: 0.8rem 1.2rem;
  background: ${(props: any) => (props.active ? Colors.bgPrimary : Colors.bgSecondary)};
  border-style: solid;
  border-width: 0.1rem;
  cursor: pointer;
`;

export const ResultListRow: any = styled(ListRow).attrs({ className: 'ResultListRow' })`
  border-color: ${(props: any) => (props.active ? Colors.accentPrimary : 'transparent')};

  :hover {
    border: 0.1rem solid ${Colors.accentPrimary};
  }
`;

export const EditResultContainer: any = styled.div.attrs({ className: 'EditResultContainer' })`
  width: 30rem;
  min-height: 30rem;
  max-height: 30rem;
  margin-top: 1.6rem;
  background: ${Colors.bgSecondary};
  overflow: hidden;
  overflow-y: auto;
`;

export const FileSearchResultContainer: any = styled(EditResultContainer).attrs({ className: 'FileSearchResultContainer' })`
  width: 30rem;

  @media ${Devices.breakSmall} {
    width: 45rem;
  }

  @media ${Devices.breakMedium} {
    width: 55rem;
  }
`;

export const EditButton: any = styled(Button).attrs({ className: 'EditButton' })`
  background: ${Colors.accentSecondary};
  color: ${Colors.textLight};
  border-radius: 0.3rem;
  padding: 0 2.4rem;
  display: ${(props: any) => (props.active ? 'block' : 'none')};
  min-width: 7.3rem;
`;

export const DownloadButton: any = styled(Button).attrs({ className: 'DownloadButton' })`
  position: relative;
  width: 2.4rem;
  height: 2.4rem;
  background: transparent;
  align-self: center;
  border-radius: 0.3rem;
  min-width: 2rem;
  display: ${(props: any) => (props.active ? 'block' : 'none')};
`;

export const EditListRow: any = styled(ListRow).attrs({ className: 'EditListRow' })`
  border: none;
  display: flex;
  justify-content: space-between;
  word-break: break-word;
  :hover {
    background: ${Colors.bgPrimary};
  }

  &:hover ${EditButton} {
    display: block;
  }

  &:hover ${DownloadButton} {
    display: block;
  }
`;
