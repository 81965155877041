import styled from 'styled-components';
import { Colors } from '../styles/colors';
import { Devices } from '../styles/devices';
import { TextXLargeBold, TextRegularMediumBold, TextRegularMedium, ThinLink } from '../styles/shared';

export const ErrorPageContainer: any = styled.div.attrs({ className: 'ErrorPageContainer' })`
  position: absolute;
  top: ${(props: any) => (props.top ? props.top : 0)};
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
`;

export const ErrorPageContent: any = styled.div.attrs({ className: 'ErrorPageContent' })`
  padding: 0 1.6rem;

  @media ${Devices.breakMegaLarge} {
    padding: 0 10%;
  }
`;

export const ErrorUnavailableHeader: any = styled.div.attrs({ className: 'ErrorUnavailableHeader' })`
  width: 100%;
  display: ${(props: any) => (props.visible ? 'flex' : 'none')};
  padding: 0 1.6rem;
  justify-content: space-between;
  background: ${Colors.bgPrimary};
  border-bottom: 0.8rem solid ${Colors.accentPrimary};

  @media ${Devices.breakMegaLarge} {
    padding: 0 10%;
  }
`;

export const HeaderContent: any = styled.div.attrs({ className: 'HeaderContent' })`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem 0;
`;

export const ErrorPageTitle: any = styled(TextXLargeBold).attrs({ className: 'FileUploadWrapper' })`
  color: ${Colors.accentPrimary};
`;

export const ErrorPageLink: any = styled(TextRegularMediumBold).attrs({ className: 'ErrorPageLink' })`
  color: ${Colors.accentSecondary};
  cursor: pointer;
`;

export const ErrorPageLinkThin: any = styled(ThinLink).attrs({ className: 'ErrorPageLinkThin' })`
  color: ${Colors.accentSecondary};
  cursor: pointer;
`;

export const SupportText: any = styled(TextRegularMedium).attrs({ className: 'SupportText' })`
  color: ${Colors.textSecondary};
`;

export const SupportContainer: any = styled.div.attrs({ className: 'SupportContainer' })`
  padding-top: 3.2rem;
  border-top: 0.1rem solid ${Colors.borderPrimary};
  max-width: 70rem;
`;
