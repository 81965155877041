import React from 'react';
import { GroupHeader } from './group-header';
import { GroupBody } from './group-body';
import { DefaultDropDownHeader, SubGroupDropDownHeader, ParentBody } from './filter-options-style';
import { Colors } from '../../styles/colors';
import { ShapedTagGroup } from '../../../../interfaces/tags';

interface FilterOptionsProps {
  group: ShapedTagGroup;
  subGroups: ShapedTagGroup[];
  onToggleGroup: (groupName: string) => void;
  onFilter: (name: string, value: string, index: number) => void;
}

export const FilterOptionsGroup = ({ group, subGroups, onToggleGroup, onFilter }: FilterOptionsProps) => {
  if (group.isParentGroup) {
    return (
      <React.Fragment>
        <DefaultDropDownHeader>
          <GroupHeader group={group} onToggleGroup={onToggleGroup} />
        </DefaultDropDownHeader>
        <ParentBody hidden={group.collapsed}>
          {subGroups.map((sGroup, index) => {
            return (
              <React.Fragment key={index}>
                <SubGroupDropDownHeader>
                  <GroupHeader group={sGroup} onToggleGroup={onToggleGroup} color={Colors.textSecondary} />
                </SubGroupDropDownHeader>
                <GroupBody group={sGroup} onFilter={onFilter} />
              </React.Fragment>
            );
          })}
        </ParentBody>
      </React.Fragment>
    );
  } else if (group.parentId === 0) {
    return (
      <React.Fragment>
        <DefaultDropDownHeader>
          <GroupHeader group={group} onToggleGroup={onToggleGroup} color={Colors.textPrimary} />
        </DefaultDropDownHeader>
        <GroupBody group={group} onFilter={onFilter} />
      </React.Fragment>
    );
  } else {
    return null;
  }
};
