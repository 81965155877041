import React from 'react';
import { SeLink } from './se-link';
import { SupportText } from './error-pages-style';
import { Margin } from '../styles/shared';

export const SupportSection = () => {
  return (
    <React.Fragment>
      <SupportText>
        If the problem persists and you want to get in contact with us, find support contact information in your local SE website, within
        the “Support” section tab.
      </SupportText>
      <Margin top="1.6rem">
        <SupportText>
          Visit <SeLink /> and select your country of origin.
        </SupportText>
      </Margin>
    </React.Fragment>
  );
};
