import React from 'react';
import { LoaderGreenSmall } from '../../../common/loaders/green-loader-small';
import { DownLoadZipContainer, ButtonText, DownLoadIconGrey, FileLoader } from './action-buttons-style';

interface DownLoadZipBtnProps {
  isDownloading: boolean;
  user: any; // PropTypes.object,
  onDownLoad: any; // PropTypes.func,
  noFiles: boolean;
}

export const DownLoadZipBtn = ({ isDownloading, user, onDownLoad, noFiles }: DownLoadZipBtnProps) => (
  <DownLoadZipContainer onClick={() => onDownLoad()} active={user && user.isDownLoader && !noFiles}>
    <DownLoadIconGrey></DownLoadIconGrey>
    <ButtonText>Download all (zip)</ButtonText>
    <FileLoader visible={isDownloading}>
      <LoaderGreenSmall />
    </FileLoader>
  </DownLoadZipContainer>
);
