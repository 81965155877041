import { Constants } from '../../../services/constant-service';
import { RadioButton } from '../../common/inputs/radiobutton/radiobutton';
import { TextInput } from '../../common/inputs/text-input/text-input';
import { FormSection, FormSectionFlex, FormSectionHeader, InputControlGroup, ControlHeadline } from './form-parts-style';
import { Margin, RowContainer, TextRegularMedium, Required } from '../../common/styles/shared';

const find = (id: any, form: any) => Array.from(form.values()).find((group: any) => group.id === id);

interface ModelProps {
  form: any;
  onChange: (elem: any, value?: string | undefined, index?: number | undefined) => void;
}

export const ModelFormPart = (props: ModelProps) => {
  const { form, onChange } = props;

  const models: any = find(Constants.modelGroupId, form);
  const firmWare: any = form.get(Constants.uploadForm.firmWare.name);
  const customType: any = form.get(Constants.uploadForm.customType.name);

  return (
    <FormSection>
      <FormSectionFlex>
        <InputControlGroup width="40%">
          <FormSectionHeader>
            {models.label}
            <Required>*</Required>
          </FormSectionHeader>

          {models.options.map((option, index) => {
            return (
              <Margin top="1.6rem" key={index}>
                <RowContainer onClick={() => onChange(models.name, option.value, index)}>
                  <RadioButton checked={models.selected == option.value} />
                  <TextRegularMedium>{option.label}</TextRegularMedium>
                </RowContainer>
              </Margin>
            );
          })}
        </InputControlGroup>
        <InputControlGroup>
          <FormSectionHeader>Hardware Firmware Requirement</FormSectionHeader>
          <ControlHeadline>
            Minimum {firmWare.label}
            <Required>*</Required>
          </ControlHeadline>
          <TextInput
            type="text"
            value={firmWare.value}
            name={firmWare.name}
            width="100%"
            placeholder={firmWare.placeHolder}
            onChange={(e) => onChange(e)}
          />
        </InputControlGroup>
        <InputControlGroup>
          <FormSectionHeader>Custom Type Requirement</FormSectionHeader>
          <ControlHeadline>
            Minimum {customType.label}
            <Required>*</Required>
          </ControlHeadline>
          <TextInput
            type="text"
            value={customType.value}
            name={customType.name}
            width="100%"
            placeholder={customType.placeHolder}
            onChange={(e) => onChange(e)}
          />
        </InputControlGroup>
      </FormSectionFlex>
    </FormSection>
  );
};
