import React from 'react';
import { Card, CardTitle, List, ListItem, AppFeatures, AppName, TagList } from './application-card-style';
import { TextRegularDefault, TextRegularDefaultBold } from '../../styles/shared';
import { isStandardApplication } from '../../../../services/constant-service';

export const ApplicationCard = ({ app, onAppClick, selectedAppType }: { app: any; onAppClick: any; selectedAppType: any }) => {
  return (
    <Card onClick={() => onAppClick(app.id)}>
      <CardTitle>{app.applicationCode}</CardTitle>
      <ApplicationInformation selectedAppType={selectedAppType} app={app} />
      <FilterTags app={app} />
    </Card>
  );
};

const ApplicationInformation = (props) => {
  return (
    <>
      <SubHeader {...props} />
      <Features {...props} />
    </>
  );
};

const SubHeader = ({ selectedAppType, app }: { selectedAppType: any; app: any }) => {
  const subHeader = isStandardApplication(selectedAppType.id) && app.model ? app.model.name : app.name;

  return (
    <AppName>
      <TextRegularDefault>{subHeader}</TextRegularDefault>
    </AppName>
  );
};

const Features = ({ app }: { app: any }) => {
  if (app.features?.length > 0) {
    const featureTags = app.features.flatMap((feature) => {
      return feature.filterTags;
    });

    return (
      <AppFeatures>
        <List>
          {featureTags.map((tag, index) => {
            return (
              <ListItem key={index}>
                <TextRegularDefaultBold>
                  {tag.name}
                  {index !== featureTags.length - 1 && <span>,</span>}
                </TextRegularDefaultBold>
              </ListItem>
            );
          })}
        </List>
      </AppFeatures>
    );
  }
  return null;
};

const getFilterTags = (app, feature, parentIndex) => {
  return feature.filterTags.map((tag, index) => {
    return (
      <ListItem key={index}>
        <TextRegularDefault>
          {tag.name}
          {(parentIndex !== app.filterTags.length - 1 ||
            (parentIndex === app.filterTags.length - 1 && index !== feature.filterTags.length - 1)) && <span>,</span>}
        </TextRegularDefault>
      </ListItem>
    );
  });
};

const FilterTags = ({ app }: { app: any }) => {
  if (app.filterTags?.length > 0) {
    return (
      <TagList>
        {app.filterTags.map((feature, index) => (
          <React.Fragment key={index}>{getFilterTags(app, feature, index)}</React.Fragment>
        ))}
      </TagList>
    );
  }
  return null;
};
