import styled from 'styled-components';
import { Colors } from '../styles/colors';
import { IconButton, TextRegularLargeBold, BorderButton, BackgroundButton } from '../styles/shared';
import CloseIcon from '../../../assets/images/close-white.svg';

export const PopUpContainer: any = styled.div.attrs({ className: 'PopupContainer' })`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 60rem;
  min-width: 28rem;
  background: ${Colors.bgSecondary};
  z-index: 2;
`;

export const PopUpHeader: any = styled.div.attrs({ className: 'PopupHeader' })`
  width: 100%;
  padding: 1.4rem 1.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${Colors.accentPrimary};
`;

export const PopUpHeaderText: any = styled(TextRegularLargeBold).attrs({ className: 'PopUpHeaderText' })`
  color: ${Colors.textLight};
`;

export const PopUpCloseIcon: any = styled(IconButton).attrs({ className: 'PopUpCloseIcon' })`
  width: 1.2rem;
  height: 1.2rem;
  background: url(${CloseIcon}) center center;
  background-repeat: no-repeat;
`;

export const PopUpBody: any = styled.div.attrs({ className: 'PopUpBody' })`
  width: 100%;
  padding: 1.2rem 1.6rem;
`;

export const PopUpFooter: any = styled.div.attrs({ className: 'PopUpFooter' })`
  width: 100%;
  padding: 0.8rem 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${Colors.bgPrimary};
`;

export const PopUpCloseButton: any = styled(BorderButton).attrs({ className: 'PopUpCloseIcon' })`
  width: 100%;
  height: 3.4rem;
  background: ${Colors.bgPrimary};
`;

export const PopupActionButton: any = styled(BackgroundButton).attrs({ className: 'PopupActionButton' })`
  width: 100%;
  height: 3.4rem;
  background: ${Colors.accentSecondary};
`;
