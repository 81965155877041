const breakPoints = {
  xsmall: '320px',
  small: '500px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1200px',
  desktop: '1450px',
  large: '1023',
};

const largeSize = breakPoints.large;

export const Devices = {
  breakXSmall: `(min-width: ${breakPoints.xsmall})`,
  breakSmall: `(min-width: ${breakPoints.small})`,
  breakMedium: `(min-width: ${breakPoints.tablet})`,
  breakLarge: `(min-width: ${breakPoints.laptop})`,
  breakXLarge: `(min-width: ${breakPoints.laptopL})`,
  breakMegaLarge: `(min-width: ${breakPoints.desktop})`,
  large: largeSize,
};
