import styled from 'styled-components';
import { Input } from '../../styles/shared';
import Icon from '../../../../assets/images/search.svg';
import Delete from '../../../../assets/images/delete.svg';

export const SearchContent: any = styled.form.attrs({ className: 'SearchContent' })`
  width: ${(props: any) => (props.width ? props.width : '100%')};
  position: relative;
  display: flex;
  align-items: center;
`;

export const SearchHeader: any = styled.div.attrs({ className: 'SearchHeader' })`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.8rem;
  justify-content: space-between;
`;

export const SearchInputStyle: any = styled(Input).attrs({ className: 'SearchInput' })`
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 3.3rem;
  padding-right: 3.3rem;
`;

export const SearchIcon: any = styled.div.attrs({ className: 'SearchIcon' })`
  width: 2.6rem;
  height: 2.6rem;
  position: absolute;
  top: 0.6rem;
  left: 0.2rem;
  background: url(${Icon});
  background-repeat: no-repeat;
`;

export const EraseIcon: any = styled.div.attrs({ className: 'EraseIcon' })`
  width: 3rem;
  height: 3rem;
  position: absolute;
  top: 0.6rem;
  right: 0.2rem;
  background: url(${Delete}) center center;
  cursor: pointer;
`;
