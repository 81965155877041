import { FileTypeGroup } from '../interfaces/files';
import { Constants, isSoftwareGroup, isStandardApplication } from './constant-service';

export const ApplicationFormCreateService = {
  getForm: (tagGroups, fileGroups, selectedAppType) => {
    return getForm(tagGroups, fileGroups, selectedAppType);
  },
};

const getForm = (tagGroups, fileGroups, selectedAppType) => {
  let groups = removeParentGroups(tagGroups);
  groups = getTagGroups(groups);
  const formParts = isStandardApplication(selectedAppType.id) ? Constants.uploadForm : Constants.genericApplicationsUploadForm;

  let form = new Map();

  for (const prop in formParts) {
    if (formParts[prop].name !== formParts.active.name) {
      form.set(formParts[prop].name, getInputControl(formParts[prop]));
    } else {
      form.set(formParts[prop].name, getSingleChoiceGroup(formParts[prop]));
    }
  }

  fileGroups.forEach((group) => {
    form.set(group.name, getFileGroup(group));
  });

  groups.forEach((group) => {
    form.set(group.name, group);
  });
  return form;
};

const removeParentGroups = (groups) => {
  return groups.filter((group) => !group.isParentGroup);
};

const getInputControl = (control) => {
  return {
    name: control.name,
    placeHolder: control.placeHolder,
    label: control.label,
    value: '',
    valid: false,
    touched: false,
    validationRules: control.validationRules || {},
  };
};

const getFileGroup = (fileGroup: FileTypeGroup) => {
  const isRequiredFileGroup = isSoftwareGroup(fileGroup);

  fileGroup.fileTypes.forEach((fileType) => {
    //empty files array on reset
    if (fileType.multipleSelection) {
      fileType.files = [];
    } else {
      fileType.file = undefined;
    }
  });

  return {
    name: fileGroup.name,
    label: fileGroup.name,
    valid: isRequiredFileGroup ? false : true,
    touched: false,
    options: fileGroup.fileTypes,
    validationRules: Object.assign(isRequiredFileGroup ? { isRequiredFile: true } : {}),
  };
};

const getSingleChoiceGroup = (control) => {
  let validationRules = {};
  let valid = true;

  if (control.id === Constants.modelGroupId) {
    valid = false;
    validationRules = {
      notDefaultSelected: true,
    };
  }

  return {
    id: control.id || undefined,
    name: control.name,
    label: control.label || control.name,
    description: control.description,
    selected: control.selected || Constants.defaultSelected,
    valid,
    multipleSelection: false,
    options: control.options,
    validationRules,
  };
};

const getMultipleChoiceGroup = (control) => {
  return {
    id: control.id || undefined,
    name: control.name,
    label: control.name,
    description: control.description,
    valid: control.valid || true,
    multipleSelection: true,
    options: control.options,
  };
};

const getTagGroups = (tagGroups) => {
  return tagGroups.map((group) => {
    return group.multipleSelection ? shapeMultiple(group) : shapeSingle(group);
  });
};

const shapeMultiple = (group) => {
  group.options = getMultipleOptions(group);
  return getMultipleChoiceGroup(group);
};

const shapeSingle = (group) => {
  group.options = getSingleOptions(group);
  return getSingleChoiceGroup(group);
};

const getMultipleOptions = (group) => {
  return group.options.map((option) => {
    return {
      value: option.id,
      label: option.name,
      selected: false,
    };
  });
};

const getSingleOptions = (group) => {
  let options = group.options.map((option) => {
    return {
      value: option.id,
      label: option.name,
    };
  });

  options.unshift({ value: 'x', label: 'None' }); //radio buttons group need default none option
  return options;
};
