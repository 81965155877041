import { File } from '../interfaces/files';
import { Constants } from './constant-service';

export const AppFormChangeService = {
  isElementValid: (value: any, updatedElement: any, applications?: any, originalValue?: any) => {
    return validate(value, updatedElement, applications, originalValue);
  },
  isFormValid: (form: any) => {
    const formArray = Array.from(form.values());
    return formArray.every((group: any) => group.valid);
  },
  getChangedFileElem: (e: any, form: any, index: any) => {
    const name = e.target.name;
    const updatedFormGroup = form.get(name);
    return updatedFormGroup.options[index];
  },
  getChangedFileElemByFileName: (form, fileName, fileIndex: number | null = null) => {
    const updatedFormGroup = form.get(Constants.swFiles);

    if (fileIndex !== null) {
      return updatedFormGroup.options.find((option) => option.files.find((file) => file.name === fileName));
    }

    return updatedFormGroup.options.find((option) => option.file?.name === fileName);
  },
};

const validate = (value, group, applications, originalValue) => {
  let isValid = true;
  const rules = group.validationRules;
  for (let rule in rules) {
    if (isValid && Object.values(ValidationRules).includes(rule)) {
      if (rule == ValidationRules.isRequired) {
        isValid = requiredValidator(value);
      } else if (rule == ValidationRules.isRequiredFile) {
        isValid = requiredFileValidator(group);
      } else if (rule == ValidationRules.isUnique) {
        isValid = uniqueValidator(value, applications, originalValue);
      } else if (rule == ValidationRules.notDefaultSelected) {
        isValid = notDefaultSelectedValidator(group);
      } else {
        isValid = true;
      }
    }
  }
  return isValid;
};

const requiredValidator = (value: any) => {
  return value.trim() !== '';
};

const requiredFileValidator = (group) => {
  return group.options.every((option: any) => {
    // Make sure there are at least 1 required file when editing app
    if (option.multipleSelection) {
      return option.files.every((file: any) => isFile(file)) && option.files.some((file: any) => file.status !== Constants.status.deleted);
    }
    return isFile(option.file);
  });
};

const uniqueValidator = (value: any, applications: any, originalValue: any) => {
  return originalValue === value || !applications.find((app: any) => app.name.toLowerCase() === value.toLowerCase());
};

const notDefaultSelectedValidator = (group: any) => {
  return group.selected !== Constants.defaultSelected;
};

const isFile = (file: File) => {
  return file && (file instanceof File || file.fileTypeId != null);
};

const ValidationRules = {
  isRequired: 'isRequired',
  isRequiredFile: 'isRequiredFile',
  isUnique: 'isUnique',
  notDefaultSelected: 'notDefaultSelected',
};
