import styled from 'styled-components';
import { TextRegularDefault } from '../../../common/styles/shared';
import { Colors } from '../../../common/styles/colors';
import { Devices } from '../../../common/styles/devices';
import DownLoadGrey from '../../../../assets/images/download-grey.svg';
import DownLoadGreen from '../../../../assets/images/download-green.svg';

export const DownLoadZipContainer: any = styled.div.attrs({ className: 'DownLoadZipContainer' })`
  width: 150px;
  height: 34px;
  margin-top: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: ${Colors.bgPrimary};
  border-radius: 0.2rem;
  border: 0.1rem solid ${Colors.borderPrimary};
  opacity: ${(props: any) => (props.active ? 1 : 0.5)};
  cursor: pointer;

  @media ${Devices.breakMedium} {
    margin-top: 0;
  }
`;

export const DownLoadIconGrey: any = styled.svg.attrs({ className: 'DownLoadIconGrey' })`
  width: 2.1rem;
  height: 2.4rem;
  margin-right: 0.8rem;
  background: url(${DownLoadGrey}) center center;
  background-repeat: no-repeat;
`;

export const ButtonText: any = styled(TextRegularDefault).attrs({ className: 'ButtonText' })`
  color: ${Colors.textSecondary};
`;

export const DownLoadFileContainer: any = styled.div.attrs({ className: 'DownLoadFileContainer' })`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;

  :first-of-type {
    padding-left: 0;
  }

  :last-of-type {
    border-right: none;
    padding-right: 0;
  }
`;

export const DownLoadIconGreen: any = styled.svg.attrs({ className: 'DownLoadIconGreen' })`
  width: 2.1rem;
  height: 2.4rem;
  background: url(${DownLoadGreen}) center center;
  background-repeat: no-repeat;
`;

export const FileType: any = styled(TextRegularDefault).attrs({ className: 'FileType' })`
  padding: 0.2rem 0.4rem;
  color: ${Colors.textLight};
  border-radius: 0.2rem;
  background: ${Colors.accentPrimary};
  text-transform: uppercase;
`;

export const FileLoader: any = styled.div.attrs({ className: 'FileLoader' })`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background: ${Colors.opacityLight};
  display: ${(props: any) => (props.visible ? 'flex' : 'none')};
`;
