const colorCodes = {
  white: '#ffffff',
  black: '#333333',
  lightGrey: '#cccccc',
  alabaster: '#fafafa',
  alto: '#d3d3d3',
  dustyGrey: '#999999',
  shuttleGrey: '#65676B',
  emerald: '#3dcd58',
  darkEmerald: '#2e9a42',
  pictonBlue: '#42b4e6',
  slateGrey: '#626469',
  red: '#e0272b',
  firebush: '#e78d2a',
  abbey: '#474748',
  opacity: 'rgba(0, 0, 0, .3)',
  opacityWhite: 'rgba(255,255,255, .7)',
  opacityRed: 'rgba(224, 39, 43, 0.05)',
};

export const Colors = {
  bgPrimary: colorCodes.alabaster,
  bgSecondary: colorCodes.white,
  bgError: colorCodes.red,
  bgInputError: colorCodes.opacityRed,
  textPrimary: colorCodes.black,
  textSecondary: colorCodes.slateGrey,
  textLight: colorCodes.white,
  textAccentPrimary: colorCodes.emerald,
  textAccentSecondary: colorCodes.pictonBlue,
  textInput: colorCodes.dustyGrey,
  textError: colorCodes.red,
  iconColor: colorCodes.lightGrey,
  backdrop: colorCodes.opacity,
  accentPrimary: colorCodes.emerald,
  accentSecondary: colorCodes.pictonBlue,
  accentNoAccess: colorCodes.firebush,
  navBottom: colorCodes.darkEmerald,
  borderInput: colorCodes.alto,
  borderPrimary: colorCodes.alto,
  borderSecondary: colorCodes.slateGrey,
  borderPrimaryAccent: colorCodes.emerald,
  borderError: colorCodes.red,
  borderLight: colorCodes.white,
  borderDark: colorCodes.dustyGrey,
  opacityDark: colorCodes.opacity,
  opacityLight: colorCodes.opacityWhite,
  userMessage: colorCodes.abbey,
  updated: colorCodes.firebush,
  new: colorCodes.emerald,
  deleted: colorCodes.red,
};
