import styled from 'styled-components';
import { Colors } from '../../styles/colors';

export const ChechBoxIcon: any = styled.svg.attrs({ className: 'ChechBoxIcon' })`
  fill: none;
  stroke: ${Colors.accentPrimary};
  stroke-width: 0.2rem;
`;

export const StyledCheckbox: any = styled.div.attrs({ className: 'StyledCheckbox' })`
  width: 2rem;
  height: 2rem;
  margin-right: 0.8rem;
  display: inline-block;
  border: 0.1rem solid ${Colors.borderInput};

  ${ChechBoxIcon} {
    visibility: ${(props: any) => (props.checked ? 'visible' : 'hidden')};
  }
`;
