import React from 'react';
import ArrowIcon from '../../../../assets/images/chevron-right.svg';
import { ExtranetText } from '../../../common/texts/extranet-text';
import { ArrowRight, ExtranetTextWhite } from './extranet-style';

export const ExtranetWhite = () => (
  <React.Fragment>
    <ArrowRight img={ArrowIcon}></ArrowRight>
    <ExtranetTextWhite>
      <ExtranetText />
    </ExtranetTextWhite>
  </React.Fragment>
);
