import React from 'react';
import { PopUp } from '../../../common/popup/popup';
import { Constants } from '../../../../services/constant-service';
import { SearchContainer } from '../search-container';
import { EditResultList } from './edit-result-list';
import { BackDrop } from '../../../common/styles/shared';

interface EditSearchProps {
  onClose: any; // PropTypes.func,
  list: any; // PropTypes.array,
  onSelected: any; // PropTypes.func,
  onSearchBlur: any; // PropTypes.func,
  selectedAppType: any; // PropTypes.object,
}

export const EditSearch = ({ ...props }: EditSearchProps) => {
  const handleSelectedApp = (selected) => {
    const route = `/type/${props.selectedAppType.name}${Constants.routes.editApplication}${selected.id}`;
    props.onSelected(route);
    props.onClose();
  };

  return (
    <React.Fragment>
      <BackDrop visible="true" onClick={props.onClose}></BackDrop>
      <PopUp title="Edit item" onClose={props.onClose}>
        <div>
          <SearchContainer
            prepopulateList
            resultComponent={EditResultList}
            list={props.list}
            placeHolder="Search item ID or name"
            onSelected={handleSelectedApp}
            onSearchBlur={props.onSearchBlur}
            headerTitle={'Search for application to edit'}
          />
        </div>
      </PopUp>
    </React.Fragment>
  );
};
