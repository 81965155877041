import React from 'react';
import { SearchContent, SearchInputStyle, SearchIcon, EraseIcon } from './search-input-style';

interface SearchInputProps {
  placeHolder: string;
  showEraseButton: boolean;
  onSearchChange: (value: string) => void;
  onSearchBlur?: () => void;
  onSearchErase: () => void;
  onKeyDown?: (e: any) => void;
  type?: string;
  value?: string;
}

export const SearchInput = ({ ...props }: SearchInputProps) => {
  let searchInput: any = React.createRef();

  const onBlur = () => {
    const { onSearchBlur, placeHolder } = props;
    searchInput.current.placeholder = placeHolder;
    if (onSearchBlur) {
      onSearchBlur();
    }
  };

  const onFocus = () => {
    searchInput.current.placeholder = '';
  };

  const onErase = () => {
    const { placeHolder, onSearchErase } = props;
    searchInput.current.value = '';
    searchInput.current.placeholder = placeHolder;
    onSearchErase();
  };

  const handleChange = (e) => {
    const { onSearchChange } = props;
    onSearchChange(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      return false;
    } else if (e.keyCode === 27) {
      onErase();
    }
  };

  return (
    <SearchContent>
      <SearchIcon></SearchIcon>
      <SearchInputStyle
        ref={searchInput}
        type={props.type}
        value={props.value}
        placeholder={props.placeHolder}
        onChange={(e) => handleChange(e)}
        onFocus={() => onFocus()}
        onBlur={() => onBlur()}
        onKeyDown={(e) => (props.onKeyDown ? props.onKeyDown(e) : handleKeyDown(e))}
      ></SearchInputStyle>

      {props.showEraseButton && <EraseIcon onClick={() => onErase()}></EraseIcon>}
    </SearchContent>
  );
};
