import { TextArea } from '../../styles/shared';

interface TextAreaInputProps {
  value: string;
  width: string;
  onChange: any;
  placeholder: string;
  name?: string;
  error?: boolean;
  maxLength?: number;
}

export const TextAreaInput = ({ ...props }: TextAreaInputProps) => (
  <TextArea
    {...props}
    placeholder={props.placeholder}
    onFocus={(e) => (e.target.placeholder = '')}
    onBlur={(e) => (e.target.placeholder = props.placeholder)}
    maxLength={props.maxLength}
  ></TextArea>
);
