import React from 'react';
import { PageLoaderBlack } from './page-loader-black';
import { BGContainer } from '../styles/shared';
import {
  SplashContainer,
  LoaderContainer,
  BrandingContainer,
  SplashBranding,
  OpacitySection,
  EcoLogo,
  ApplicationName,
  PayOff,
  LifeIsOnContainer,
  LifeIsOnLogo,
} from './splash-style';

export const Splash = () => (
  <BGContainer>
    <SplashContainer>
      <LoaderContainer>
        <PageLoaderBlack text="" />
      </LoaderContainer>
      <BrandingContainer>
        <OpacitySection></OpacitySection>
        <SplashBranding>
          <EcoLogo></EcoLogo>
          <ApplicationName>BMS Applications</ApplicationName>
          <PayOff>Pre-configured software, documentation and settings files</PayOff>
        </SplashBranding>
        <LifeIsOnContainer>
          <LifeIsOnLogo></LifeIsOnLogo>
        </LifeIsOnContainer>
      </BrandingContainer>
    </SplashContainer>
  </BGContainer>
);
