import React from 'react';
import { Link } from 'react-router-dom';
import Star from '../../../assets/images/star.svg';
import { MyApps } from '../menu-items/my-apps/my-apps';
import { MyAppsBlack } from '../menu-items/my-apps/my-apps-black';
import { AddNew } from '../menu-items/add-new';
import { EditApp } from '../menu-items/edit-app';
import { DownloadFileSearch } from '../menu-items/download-file-search';
import { ViewStatistics } from '../menu-items/view-statistics';
import { Extranet } from '../menu-items/extranet/extranet';
import { ExtranetBlue } from '../menu-items/extranet/extranet-blue';
import { Logout } from '../menu-items/logout';
import { LogoutRedirect } from '../../common/idms/logout-redirect';
import { MyProfileRedirect } from '../menu-items/my-profile';
import { Login } from '../menu-items/login';
import { LoginRedirect } from '../../common/idms/login-redirect';
import { TextRegularMediumBold, TextLargeBold } from '../../common/styles/shared';
import {
  AcknowledgeIcon,
  MenuSmallContainer,
  MenuSmallInner,
  OuterMenuClose,
  MenuCloseContainer,
  MenuCloseBtn,
  MenuTextRow,
  MenuIconRow,
  MenuIcon,
} from './menu-style';
import { ViewFeedbackList } from '../menu-items/view-feedbackList';

interface MenuSmallProps {
  user: any; // PropTypes.object,
  onMenuClose: any; // PropTypes.func,
  onFeedBackClick: any; // PropTypes.func,
  toggleEditSearch: any; // PropTypes.func,
  toggleFileSearch: any; // PropTypes.func,
  selectedAppType: any; // PropTypes.object,
  applicationLinks: any; // PropTypes.array,
  setSelectedAppType: any; // PropTypes.func,
}

const renderApplicationLinks = (applicationLinks, onMenuClose, setSelectedAppType, selectedAppType) => {
  return applicationLinks.map((app) => {
    const activeIcon = selectedAppType.id === app.id ? <AcknowledgeIcon /> : null;

    return (
      <Link key={`${app.name}`} to={`/type/${app.name}`} /*push="true" REMOVE?*/ onClick={() => setSelectedAppType(app)}>
        <MenuTextRow onClick={onMenuClose}>
          <TextLargeBold>{`${app.appTypeGroupName}`}</TextLargeBold>
          {activeIcon}
        </MenuTextRow>
      </Link>
    );
  });
};

export const MenuSmall = ({
  user,
  onMenuClose,
  onFeedBackClick,
  toggleEditSearch,
  toggleFileSearch,
  selectedAppType,
  applicationLinks,
  setSelectedAppType,
}: MenuSmallProps) => (
  <MenuSmallContainer>
    <MenuSmallInner>
      {renderApplicationLinks(applicationLinks, onMenuClose, setSelectedAppType, selectedAppType)}
      <Link to={`/type/${selectedAppType}/about`} /*push="true" REMOVE?*/>
        <MenuTextRow onClick={onMenuClose}>
          <TextLargeBold>About</TextLargeBold>
        </MenuTextRow>
      </Link>

      {user && user.isDownLoader && (
        <MenuTextRow onClick={onFeedBackClick}>
          <TextLargeBold>Send Feedback</TextLargeBold>
        </MenuTextRow>
      )}

      <MenuIconRow>{user ? <TextRegularMediumBold>{user.name}</TextRegularMediumBold> : <LoginRedirect component={Login} />}</MenuIconRow>

      {user && user.isDownLoader && (
        <React.Fragment>
          <MenuIconRow>
            <MyProfileRedirect />
          </MenuIconRow>
          <MenuIconRow>
            <MenuIcon img={Star}></MenuIcon>
            <MyApps component={MyAppsBlack} />
          </MenuIconRow>
          {user && user.isUpLoader && (
            <React.Fragment>
              <AddNew selectedAppType={selectedAppType} onClick={onMenuClose} />
              <EditApp onClick={toggleEditSearch} />
            </React.Fragment>
          )}

          {user && user.isStatistician && (
            <React.Fragment>
              <ViewStatistics onClick={onMenuClose} />
            </React.Fragment>
          )}

          {user && user.isAdministrator && (
            <React.Fragment>
              <ViewFeedbackList onClick={onMenuClose} />
            </React.Fragment>
          )}

          {user && user.isDownLoader && (
            <React.Fragment>
              <DownloadFileSearch onClick={toggleFileSearch} />
            </React.Fragment>
          )}
        </React.Fragment>
      )}

      {user && user.isUploader && <AddNew selectedAppType={selectedAppType} onClick={onMenuClose} />}

      {user && <LogoutRedirect component={Logout} />}
      <MenuIconRow>
        <Extranet component={ExtranetBlue} />
      </MenuIconRow>
    </MenuSmallInner>
    <OuterMenuClose>
      <MenuCloseContainer onClick={onMenuClose}>
        <MenuCloseBtn></MenuCloseBtn>
      </MenuCloseContainer>
    </OuterMenuClose>
  </MenuSmallContainer>
);
