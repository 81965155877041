import React from 'react';
import { UserStateText, MarginTop } from './texts-style';

export const NoRightsText = () => {
  return (
    <React.Fragment>
      <UserStateText>Your account has insufficient permissions.</UserStateText>
      <MarginTop>
        <UserStateText>
          You need to be logged in to a Schneider Electric partner or employee account to access file downloads.
        </UserStateText>
      </MarginTop>
    </React.Fragment>
  );
};
