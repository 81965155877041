import React from 'react';
import { BorderButton, BackgroundButton } from '../../styles/shared';

interface FilterButtonProps {
  onClear: () => void;
  onToggleFilterOptions: () => void;
}

export const FilterButtons = ({ onClear, onToggleFilterOptions }: FilterButtonProps) => (
  <React.Fragment>
    <BorderButton width="11.6rem" height="3.4rem" onClick={onClear}>
      Clear
    </BorderButton>
    <BackgroundButton width="11.6rem" height="3.4rem" onClick={onToggleFilterOptions}>
      Apply filter
    </BackgroundButton>
  </React.Fragment>
);
