import { Response } from '../interfaces/common';
import { ApiService } from './api-service';
import { Constants, ErrorCodes } from './constant-service';

export const ApplicationFormSubmitService = {
  updateApplication: async (form, app, user) => {
    const appTypeId = app.applicationTypeId;
    let formData = getGeneralData(form, appTypeId, user);
    const deletedFiles = getDeletedFiles(form);

    deletedFiles.map((file) => {
      formData.append('DeletedFiles[]', file);
    });

    return await ApiService.updateApplication(formData, app.id);
  },
  updateFiles: async (appId, form) => {
    const files = getFiles(form);
    const newFiles = files.filter((obj) => isNewFile(obj.file.status));
    const updatedFiles = files.filter((obj) => obj.file.status == Constants.status.edited);

    updatedFiles.length && (await updateFileSearchTags(updatedFiles));
    newFiles.length && (await saveFiles(appId, newFiles, 'edit'));

    return;
  },
  saveApplication: async (form, appTypeId, user) => {
    let formData = getGeneralData(form, appTypeId, user);
    return await ApiService.saveApplication(formData);
  },
  saveFiles: async (appId, form) => {
    const files = getFiles(form);
    return await saveFiles(appId, files, 'add');
  },

  setErrorMessage: (error: ErrorCodes): Response => {
    switch (error) {
      case ErrorCodes.BadRequest:
        return {
          status: error,
          message: `Wrong file type.
    Following file types are allowed: ${Constants.extensions}`,
        };
      case ErrorCodes.Unauthorized:
      case ErrorCodes.Forbidden:
        return {
          status: error,
          message: '',
        };
      default:
        return {
          status: error,
          message: 'Files could not be uploaded',
        };
    }
  },
};

const getDeletedFiles = (form) => {
  const files = getFiles(form);
  return files
    .filter((obj) => isDeletedFile(obj.file.status))
    .map((item) => {
      return item.file.id;
    });
};

const getGeneralData = (form, appTypeId, user) => {
  let formData = new FormData();
  const name = form.get(Constants.uploadForm.name.name);
  const desc = form.get(Constants.uploadForm.description.name);
  const active = form.get(Constants.uploadForm.active.name);
  const version = form.get(Constants.uploadForm.version.name);
  const notes = form.get(Constants.uploadForm.releaseNotes.name);
  const firmWare = form.get(Constants.uploadForm.firmWare.name);
  const customType = form.get(Constants.uploadForm.customType.name);
  const tags = getFormTags(form);

  formData.append(name.name, name.value);
  formData.append(desc.name, desc.value);
  formData.append(active.name, active.selected);
  formData.append(version.name, version.value);
  formData.append(notes.name, notes.value);
  if (firmWare != null) {
    formData.append(firmWare.name, firmWare.value);
  }
  if (customType != null) {
    formData.append(customType.name, customType.value);
  }

  tags.map((tag) => {
    formData.append('Tags[]', tag);
  });

  formData.append('CountryTarget', user.countryCode);
  formData.append('CultureTarget', user.cultureCode);
  formData.append('ApplicationTypeId', appTypeId);

  return formData;
};

const getFormTags = (form) => {
  //id only exists in form parts built from api data that is not file upload parts
  const tagGroups = Array.from(form.values()).filter((group: any) => group.id !== undefined);
  let singleTags = tagGroups
    .filter((group: any) => group.selected !== Constants.defaultSelected && group.selected !== undefined)
    .map((group: any) => group.selected);

  const multipleTags = tagGroups
    .map((group: any) => group.options.map((option) => option))
    .reduce((acc, option) => acc.concat(option))
    .filter((option) => option.selected)
    .map((option) => option.value);

  return singleTags.concat(multipleTags);
};

const getFiles = (form) => {
  const swFiles = form.get(Constants.swFiles);
  const docFiles = form.get(Constants.docFiles);
  let files = getSwFiles(swFiles.options);
  files = files.concat(getDocFiles(docFiles.options));
  return files;
};

const getSwFiles = (options) => {
  if (options.some((option) => option.file != null)) {
    return options
      .filter((option) => option.file !== '')
      .map((obj) => {
        return {
          fileTypeId: obj.id,
          file: obj.file,
        };
      });
  }
  return getDocFiles(options);
};

const getDocFiles = (options) => {
  return options
    .map((option) => {
      return option.files.map((file) => {
        return {
          fileTypeId: option.id,
          file: file,
        };
      });
    })
    .filter((files) => files.length)
    .reduce((acc, curr) => concatenate(acc, curr), []);
};

const concatenate = (acc, curr) => {
  return acc.concat(curr);
};

const isNewFile = (fileStatus) => {
  const { status } = Constants;
  return fileStatus === status.new || fileStatus === status.updated;
};

const isDeletedFile = (status) => {
  return status === Constants.status.deleted;
};

const updateFileSearchTags = async (files) => {
  return await Promise.all(
    files.map(async (obj) => {
      const formData = new FormData();
      formData.append('Id', obj.file.id);
      formData.append('SearchTag', obj.file.searchTag);
      return await ApiService.updateFileSearchTag(formData);
    })
  );
};

const saveFiles = async (appId, files, action) => {
  return await Promise.all(
    files.map(async (obj) => {
      const formData = new FormData();
      formData.append('FileTypeId', obj.fileTypeId);
      formData.append('ApplicationId', appId);
      formData.append('File', obj.file);
      if (obj.file.searchTag) {
        formData.append('SearchTag', obj.file.searchTag);
      }
      return action === 'edit' ? await ApiService.updateFile(formData) : await ApiService.saveFile(formData);
    })
  );
};
