import styled from 'styled-components';
import { Colors } from '../styles/colors';
import { IconButton, Paragraph } from '../styles/shared';
import CloseIcon from '../../../assets/images/close-white.svg';
import { Devices } from '../styles/devices';

export const Close: any = styled(IconButton).attrs({ className: 'Close' })`
  width: 2rem;
  height: 2rem;
  background: url(${CloseIcon}) center center;
  background-repeat: no-repeat;
`;

export const ToastText: any = styled(Paragraph).attrs({ className: 'ToastText' })`
  color: ${Colors.textLight};
`;

export const ToastBackDrop: any = styled.div.attrs({ className: 'ToastBackDrop' })`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: transparent;
  z-index: 2;
`;

export const ToastBottomContainer: any = styled.div.attrs({ className: 'ToastBottomContainer' })`
  min-height: 7rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding: 1.6rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.24);
  background-color: ${(props: any) => (props.color ? props.color : Colors.userMessage)};

  @media ${Devices.breakLarge} {
    left: 15rem;
    right: 15rem;
  }
`;

export const AuthLoginContainer: any = styled.div.attrs({ className: 'AuthLoginContainer' })`
  cursor: pointer;
`;

export const AuthFlexRight: any = styled.div.attrs({ className: 'AuthFlexRight' })`
  display: flex;
  justify-content: flex-end;
  margin-top: 1.2rem;
`;
