export const CacheService = {
  cacheForm: (name, formData) => {
    return saveToStorage(name, formData);
  },
  getCachedForm: (name) => {
    return getFromStorage(name);
  },
  removeFromCache: (name) => {
    return window.localStorage.removeItem(name);
  },
  cacheItem: (name, user) => {
    return window.localStorage.setItem(name, JSON.stringify(user));
  },
  getCachedItem: (name) => {
    const item = window.localStorage.getItem(name);
    return item !== null ? JSON.parse(item) : null;
  },
  clearCache: () => {
    return window.localStorage.clear();
  },
};

const saveToStorage = (name, formData) => {
  const obj = {
    form: Array.from(formData.form.entries()),
    formIsValid: formData.formIsValid,
  };
  return window.localStorage.setItem(name, JSON.stringify(obj));
};

const getFromStorage = (name) => {
  const item = window.localStorage.getItem(name);
  const object = item !== null ? JSON.parse(item) : null;

  if (object != null) {
    object.formData = new Map(object.form);
    return object;
  }
  return undefined;
};
