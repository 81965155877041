import React, { PureComponent } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { StateContext } from '../../services/state-context';
import { Constants } from '../../services/constant-service';
import { CacheService } from '../../services/cache-service';
import { Extranet } from './menu-items/extranet/extranet';
import { ExtranetWhite } from './menu-items/extranet/extranet-white';
import { MenuSmall } from './menu/menu-small';
import { MenuLarge } from './menu/menu-large';
import { FeedBackContainer } from '../feedback-page/feedback-container';
import { LogoGreen } from '../common/logos/logo-green';
import { AppTitle } from '../common/texts/app-title';
import { ToastBottom } from '../common/user-messages/toast-bottom';
import { EditSearch } from './search/edit-search/edit-search';
import { FileSearch } from './search/file-search/file-search';
import { first } from '../../utilities/array-utilities';
import { DownloadService, getDownloadMessage } from '../../services/download-service';
import {
  HeaderContainer,
  BrandingSection,
  Hamburger,
  TitleContainer,
  BackGroundContainer,
  HeaderActionBar,
  FullScreenBackDrop,
  MenuActionBar,
  ActionBarItem,
  ActionBarTitle,
  ActionBarGroupName,
  TransparentBackDrop,
} from './page-header-style';

interface PageHeaderProps {
  getUser: any; // PropTypes.func,
  applications: any; // PropTypes.array,
  history: any; // PropTypes.object,
  applicationTypes: any; // PropTypes.array,
  applicationTypeGroups: any; // PropTypes.array,
  setSelectedAppType: any; // PropTypes.func,
  selectedAppType: any; // PropTypes.object,
}

interface PageHeaderState {
  user: any;
  showBackDrop: boolean;
  showSmallMenu: boolean;
  showSubMenu: boolean;
  showFeedBack: boolean;
  showEditSearch: boolean;
  showFileSearch: boolean;
  responseCode: number;
  responseMessage: string;
  allFiles: [];
  downloadingFileId: number;
}

class PageHeader extends PureComponent<PageHeaderProps, PageHeaderState> {
  constructor(props: PageHeaderProps) {
    super(props);

    this.state = {
      user: undefined,
      showBackDrop: false,
      showSmallMenu: false,
      showSubMenu: false,
      showFeedBack: false,
      showEditSearch: false,
      showFileSearch: false,
      responseCode: 0,
      responseMessage: '',
      allFiles: [],
      downloadingFileId: -1,
    };
  }

  componentDidMount() {
    this.setState({ user: this.props.getUser(), allFiles: this.getFilesList() });
  }

  componentDidUpdate(prevProps) {
    if (first(this.props.applications) !== first(prevProps.applications)) {
      this.setState({ allFiles: this.getFilesList() });
    }
  }

  getFilesList = () => {
    return this.props.applications.flatMap((application) =>
      application.files.map((file) => ({
        id: file.id,
        name: file.name,
        applicationCode: application.applicationCode,
        applicationName: application.name,
        searchTag: file.searchTag,
      }))
    );
  };

  toggleSmallMenu = () => {
    this.setState({
      showSmallMenu: !this.state.showSmallMenu,
      showSubMenu: false,
      showFeedBack: false,
      showEditSearch: false,
      showFileSearch: false,
    });
  };

  toggleSubMenu = () => {
    this.setState({
      showSmallMenu: false,
      showSubMenu: !this.state.showSubMenu,
      showFeedBack: false,
      showEditSearch: false,
      showFileSearch: false,
    });
  };

  toggleEditSearch = () => {
    this.setState({
      showSmallMenu: false,
      showSubMenu: false,
      showFeedBack: false,
      showEditSearch: !this.state.showEditSearch,
      showFileSearch: false,
    });
  };

  toggleFileSearch = () => {
    this.setState({
      showSmallMenu: false,
      showSubMenu: false,
      showFeedBack: false,
      showEditSearch: false,
      showFileSearch: !this.state.showFileSearch,
    });
  };

  toggleFeedBack = (_ev, responseCode = 0, responseMessage = '') => {
    this.setState({
      showSmallMenu: false,
      showSubMenu: false,
      showFeedBack: !this.state.showFeedBack,
      showEditSearch: false,
      showFileSearch: false,
      responseCode: responseCode,
      responseMessage: responseMessage,
    });
  };

  reloadApp = () => {
    CacheService.removeFromCache(Constants.cache.myAppsForm);
    CacheService.removeFromCache(Constants.cache.selectorForm);
    window.location.href = Constants.routes.root;
  };

  handleSelectedApp = (route) => {
    const { history } = this.props;
    history.push(route);
    return this.handleSearchBlur();
  };

  handleSelectedFile = async (file) => {
    this.setState({ downloadingFileId: file.id });
    let responseCode = 0;
    let responseMessage = '';
    try {
      const isZipped = await DownloadService.downLoadFile(file);
      responseCode = 200;
      responseMessage = getDownloadMessage(file.name, isZipped);
    } catch (error: any) {
      responseCode = error.response.status;
      responseMessage = getDownloadMessage(file.name, false, false);
    }

    this.setState({ responseCode, responseMessage, downloadingFileId: -1 });
  };

  handleSearchBlur = () => {
    this.setState({
      showBackDrop: false,
    });
  };

  hideToast = () => {
    this.setState({
      responseCode: 0,
      responseMessage: '',
    });
  };

  // Creates a link object which is basically just the application type with
  // belonging type group name added
  createLinkObjects() {
    const { applicationTypes, applicationTypeGroups } = this.props;

    return applicationTypeGroups
      .filter((appTypeGroup) => appTypeGroup.active === true)
      .map((appTypeGroup) => {
        const app = applicationTypes.find((appType) => appTypeGroup.applicationTypes[0] === appType.id);
        app.appTypeGroupName = appTypeGroup.name;
        return app;
      });
  }

  renderApplicationTypeLinks(appWithGroupName, isOnAbout) {
    return appWithGroupName.map((app) => {
      const isSelected = this.props.selectedAppType.id === app.id && !isOnAbout;
      const [title, ...rest] = app.appTypeGroupName.split(' ');

      return (
        <Link key={`${app.name}`} to={`/type/${app.name}`} /*push="true" REMOVE?*/ onClick={() => this.props.setSelectedAppType(app)}>
          <ActionBarItem isSelected={isSelected}>
            <ActionBarTitle>{title}</ActionBarTitle>
            <ActionBarGroupName>{`${rest.join(' ')}`}</ActionBarGroupName>
          </ActionBarItem>
        </Link>
      );
    });
  }

  render() {
    const { applications, selectedAppType, setSelectedAppType } = this.props;
    const {
      showSmallMenu,
      showSubMenu,
      showFeedBack,
      responseCode,
      responseMessage,
      showEditSearch,
      showFileSearch,
      allFiles,
      downloadingFileId,
    } = this.state;

    const linkObjects = this.createLinkObjects();
    const isOnAbout = window.location.href.includes('about');

    const commonSearchProps = {
      onSearchBlur: this.handleSearchBlur,
      // onSearchChange: this.handleSearchChange,
      selectedAppType: selectedAppType,
    };

    return (
      <React.Fragment>
        <FullScreenBackDrop visible={showFeedBack} onClick={this.toggleFeedBack}></FullScreenBackDrop>
        <FullScreenBackDrop visible={showSmallMenu} onClick={this.toggleSmallMenu}></FullScreenBackDrop>
        <FullScreenBackDrop visible={showEditSearch} onClick={this.toggleEditSearch}></FullScreenBackDrop>
        <FullScreenBackDrop visible={showFileSearch} onClick={this.toggleFileSearch}></FullScreenBackDrop>
        <TransparentBackDrop visible={showSubMenu} onClick={this.toggleSubMenu}></TransparentBackDrop>

        {showFeedBack && <FeedBackContainer onCloseFeedback={this.toggleFeedBack} />}

        {showEditSearch && (
          <EditSearch
            key={'editSearch'}
            list={applications}
            onClose={this.toggleEditSearch}
            onSelected={this.handleSelectedApp}
            {...commonSearchProps}
          />
        )}

        {showFileSearch && (
          <FileSearch
            key={'fileSearch'}
            list={allFiles}
            onClose={this.toggleFileSearch}
            onSelected={this.handleSelectedFile}
            downloadingFileId={downloadingFileId}
            {...commonSearchProps}
          />
        )}

        {responseCode !== 0 && <ToastBottom responseCode={responseCode} text={responseMessage} onClose={this.hideToast} />}
        <StateContext.Consumer>
          {(context) => {
            const { user } = context.state;
            const commonMenuProps = {
              selectedAppType: selectedAppType,
              toggleEditSearch: this.toggleEditSearch,
              toggleFileSearch: this.toggleFileSearch,
              user: user,
            };

            return (
              <>
                {showSmallMenu && (
                  <MenuSmall
                    applicationLinks={linkObjects}
                    onMenuClose={this.toggleSmallMenu}
                    onFeedBackClick={this.toggleFeedBack}
                    setSelectedAppType={setSelectedAppType}
                    {...commonMenuProps}
                  />
                )}

                <HeaderContainer>
                  <BrandingSection>
                    <Hamburger onClick={this.toggleSmallMenu}></Hamburger>
                    <TitleContainer onClick={this.reloadApp}>
                      <AppTitle />
                    </TitleContainer>

                    <MenuLarge showSubMenu={showSubMenu} toggleSubMenu={this.toggleSubMenu} {...commonMenuProps} />

                    <LogoGreen />
                  </BrandingSection>

                  <BackGroundContainer>
                    <HeaderActionBar>
                      <MenuActionBar>{this.renderApplicationTypeLinks(linkObjects, isOnAbout)}</MenuActionBar>

                      <MenuActionBar>
                        <Link to={`/type/${selectedAppType.name}/about`} /*push="true" REMOVE?*/>
                          <ActionBarItem isSelected={isOnAbout}>About</ActionBarItem>
                        </Link>

                        {user && user.isDownLoader && <ActionBarItem onClick={this.toggleFeedBack}>Send feedback</ActionBarItem>}

                        <ActionBarItem>
                          <Extranet component={ExtranetWhite} />
                        </ActionBarItem>
                      </MenuActionBar>
                    </HeaderActionBar>
                  </BackGroundContainer>
                </HeaderContainer>
              </>
            );
          }}
        </StateContext.Consumer>
      </React.Fragment>
    );
  }
}

export default withRouter((props: any) => <PageHeader {...props} />);
