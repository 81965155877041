import { Link } from 'react-router-dom';
import { Constants } from '../../../services/constant-service';
import { BackBtnContainer, BackIcon } from './back-button-style';
import { TextRegularDefault } from '../styles/shared';

export const ToSelectorButton = ({ selectedAppType }: { selectedAppType: any }) => {
  let selectorPath = Constants.routes.root;
  if (selectedAppType != null) {
    selectorPath = selectorPath.concat(`type/${selectedAppType.name}`);
  }

  return (
    <Link to={selectorPath} /*push="true REMOVE?"*/>
      <BackBtnContainer>
        <BackIcon></BackIcon>
        <TextRegularDefault>Filter Selection</TextRegularDefault>
      </BackBtnContainer>
    </Link>
  );
};
