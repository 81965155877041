import styled from 'styled-components';
import { Devices } from '../common/styles/devices';
import { Colors } from '../common/styles/colors';
import { Fonts } from '../common/styles/fonts';
import { BgContainer, BackDrop, BackDropTransparent } from '../common/styles/shared';
import HamburgerIcon from '../../assets/images/menu.svg';

export const HeaderContainer: any = styled.div.attrs({ className: 'HeaderContainer' })`
  width: 100%;
  background: ${Colors.bgSecondary};
`;

export const BrandingSection: any = styled.div.attrs({ className: 'BrandingSection' })`
  width: 100%;
  height: 5.6rem;
  display: flex;
  align-items: center;
  padding: 0 1.6rem;

  @media ${Devices.breakLarge} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-template-columns: auto auto;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }

  @media ${Devices.breakXLarge} {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }

  @media ${Devices.breakMegaLarge} {
    padding-left: 10%;
    padding-right: 10%;
  }
`;

export const TitleContainer: any = styled.div.attrs({ className: 'TitleContainer' })`
  flex: 1;
  cursor: pointer;
`;

export const Hamburger: any = styled.svg.attrs({ className: 'Hamburger' })`
  height: 6rem;
  width: 4rem;
  background: url(${HamburgerIcon}) center right;
  background-repeat: no-repeat;
  cursor: pointer;

  @media ${Devices.breakLarge} {
    display: none;
  }
`;

export const BackGroundContainer: any = styled(BgContainer).attrs({ className: 'BackGroundContainer' })`
  background: ${Colors.accentPrimary};
`;

export const HeaderActionBar: any = styled.div.attrs({ className: 'HeaderActionBar' })`
  width: 100%;
  height: 5.6rem;
  display: flex;
  align-items: center;
  position: relative;
  background: linear-gradient(transparent calc(100% - 4px), ${Colors.navBottom} calc(100% - 4px));
  justify-content: space-between;

  @media ${Devices.breakLarge} {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }

  @media ${Devices.breakXLarge} {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }

  @media ${Devices.breakMegaLarge} {
    padding-left: 10%;
    padding-right: 10%;
  }
`;

export const FullScreenBackDrop: any = styled(BackDrop).attrs({ className: 'FullScreenBackDrop' })`
  top: 0;
  z-index: 2;
`;

export const TransparentBackDrop: any = styled(BackDropTransparent).attrs({ className: 'TransparentBackDrop' })`
  top: 5.6rem;
  z-index: 2;
`;

export const MenuActionBar: any = styled.div.attrs({ className: 'MenuActionBar' })`
  display: none;

  @media ${Devices.breakLarge} {
    color: ${Colors.textLight};
    display: flex;
    color: ${Colors.textLight};
    height: 100%;
  }
`;

export const ActionBarItem: any = styled.div.attrs({ className: 'ActionBarItem' })`
  color: ${Colors.textLight};
  height: 100%;
  padding: 0 1.2rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 4px solid transparent;
  flex-direction: column;
  justify-content: center;

  :hover {
    border-bottom: 4px solid ${Colors.opacityLight};
  }

  border-bottom: ${(props: any) => (props.isSelected ? '4px solid white' : '4px solid transparent')};
`;

export const ActionBarTitle: any = styled.div.attrs({ className: 'ActionBarTitle' })`
  font-size: ${Fonts.fontSizeDefault};
`;

export const ActionBarGroupName: any = styled.div.attrs({ className: 'ActionBarGroupName' })`
  font-size: ${Fonts.fontSizeSmall};
  text-align: center;
`;
