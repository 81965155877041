import React from 'react';
import { LoginRedirect } from '../../../common/idms/login-redirect';
import { LoginText } from '../../../common/texts/login-text';
import { LoginContainer, LoginButtonContainer, LoginButton, ArrowRightIcon } from './userstate-style';

export const Login = () => {
  const loginComponent = () => {
    return (
      <LoginContainer>
        <LoginText />
        <LoginButtonContainer>
          <LoginButton>Login</LoginButton>
          <ArrowRightIcon></ArrowRightIcon>
        </LoginButtonContainer>
      </LoginContainer>
    );
  };

  return <LoginRedirect component={loginComponent} />;
};
