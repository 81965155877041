import { ApiService } from './api-service';
import React, { Component } from 'react';
import { CacheService } from './cache-service';
import { Constants } from './constant-service';
import { ShapedUser, User } from '../interfaces/users';

export const parseQueryString = (string) => {
  return string
    .slice(1)
    .split('&')
    .map((queryParam) => {
      let kvp = queryParam.split('=');
      return { key: kvp[0], value: kvp[1] };
    })
    .reduce((query, kvp) => {
      query[kvp.key] = kvp.value;
      return query;
    }, {});
};

interface DefaultStateContext {
  state: StateProviderState;
  userLogout: () => Promise<void>;
  login: (query: string) => void;
  setUser: (user: User) => void;
  getUser: () => ShapedUser;
  setCurrentRoute: (route: string) => void;
  getCurrentRoute: () => string;
  setLoginError: (erronous: boolean) => void;
  updateUser: () => void;
}

export const StateContext = React.createContext<DefaultStateContext>({} as DefaultStateContext);

interface StateProviderProps {
  children: any;
}

export interface StateProviderState {
  loginError: boolean;
  user: ShapedUser | undefined;
}

export class StateProvider extends Component<StateProviderProps, StateProviderState> {
  private idleCounter = 0;
  private sessionTimer: NodeJS.Timeout;

  constructor(props: StateProviderProps) {
    super(props);
    this.state = {
      loginError: false,
      user: undefined,
    };
  }

  getCurrentRoute = (): string => {
    return CacheService.getCachedItem(Constants.cache.route);
  };

  setCurrentRoute = (route: string) => {
    return CacheService.cacheItem(Constants.cache.route, route);
  };

  getUser = () => {
    const user: ShapedUser = CacheService.getCachedItem(Constants.cache.user);
    const validSession = this.isSessionValid(user);

    if (user && !validSession) {
      return this.softLogout();
    }
    return CacheService.getCachedItem(Constants.cache.user);
  };

  setUser = (user: User) => {
    const shapedUser: ShapedUser = {
      name: `${user.firstName} ${user.lastName}`,
      countryCode: user.countryCode,
      cultureCode: user.cultureCode,
      isDownLoader: this.checkIsDownLoader(user.ailPrograms),
      isUpLoader: this.checkIsUploader(user.userRoles),
      isStatistician: this.checkIsStatistician(user.userRoles),
      isAdministrator: this.checkIsAdministrator(user.userRoles),
      expire: this.updateExpireTime(),
    };

    this.setState({ user: shapedUser });
    CacheService.cacheItem(Constants.cache.user, shapedUser);

    return this.setUpInactivityTimer();
  };

  updateExpireTime = () => {
    let date = new Date();
    return date.setTime(date.getTime() + 45 * 60 * 1000);
  };

  isSessionValid = (user) => {
    if (user) {
      const now = new Date();
      if (user.expire < now.getTime()) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  };

  handleStorageChange = () => {
    const globalUser = this.getUser();
    const { user } = this.state;

    if (globalUser) {
      if (user && user.name !== globalUser.name) {
        return this.updateUser();
      }
    } else {
      return this.updateUser();
    }
  };

  updateUser = () => {
    const user: ShapedUser = this.getUser();
    if (user) {
      this.setUpInactivityTimer();
    } else {
      this.removeInactivityTimer();
    }

    return this.setState({ user });
  };

  userLogout = async () => {
    CacheService.clearCache();
    this.setState({ user: undefined });
    this.removeInactivityTimer();
    return await ApiService.logout();
  };

  softLogout = () => {
    CacheService.clearCache();
    this.removeInactivityTimer();
    return this.setState({ user: undefined });
  };

  login = async (query) => {
    const parsed = parseQueryString(query);
    return await ApiService.login(parsed.code);
  };

  setLoginError(bool) {
    return this.setState({ loginError: bool });
  }

  startSessionTimer = () => {
    this.sessionTimer = setInterval(this.checkUserInactivity, 60000);
  };

  resetIdleCounter = () => {
    this.idleCounter = 0;
  };

  checkUserInactivity = async () => {
    let user = CacheService.getCachedItem(Constants.cache.user);
    this.idleCounter += 1;
    if (this.idleCounter > 45) {
      return await this.userLogout();
    } else {
      user.expire = this.updateExpireTime();
      CacheService.cacheItem(Constants.cache.user, user);
      return this.setState({ user });
    }
  };

  stopSessionTimer = () => {
    window.clearInterval(this.sessionTimer);
    clearTimeout(this.sessionTimer);
  };

  checkIsUploader = (userRoles: string[]) => {
    return userRoles ? userRoles.includes('Administrator') || userRoles.includes('Uploader') : false;
  };

  checkIsStatistician = (userRoles) => {
    return userRoles ? userRoles.includes('Administrator') || userRoles.includes('Statistician') : false;
  };

  checkIsDownLoader = (ailPrograms) => {
    const pArray = ailPrograms.toLowerCase().split(',');

    return pArray ? pArray.includes('employee') || pArray.includes('schneider employee') || pArray.includes('bms partner') : false;
  };

  checkIsAdministrator = (userRoles) => {
    return userRoles ? userRoles.includes('Administrator') : false;
  };

  setUpInactivityTimer = () => {
    window.addEventListener('storage', this.handleStorageChange);
    document.addEventListener('onload', this.resetIdleCounter, false);
    document.addEventListener('mousemove', this.resetIdleCounter, false);
    document.addEventListener('mousedown', this.resetIdleCounter, false);
    document.addEventListener('keypress', this.resetIdleCounter, false);
    document.addEventListener('touchmove', this.resetIdleCounter, false);
    document.addEventListener('DOMMouseScroll', this.resetIdleCounter, false);
    document.addEventListener('mousewheel', this.resetIdleCounter, false);
    document.addEventListener('click', this.resetIdleCounter, false);
    return this.startSessionTimer();
  };

  removeInactivityTimer = () => {
    this.stopSessionTimer();
    window.removeEventListener('storage', this.handleStorageChange);
    document.removeEventListener('onload', this.resetIdleCounter);
    document.removeEventListener('mousemove', this.resetIdleCounter);
    document.removeEventListener('mousedown', this.resetIdleCounter);
    document.removeEventListener('keypress', this.resetIdleCounter);
    document.removeEventListener('touchmove', this.resetIdleCounter);
    document.removeEventListener('DOMMouseScroll', this.resetIdleCounter);
    document.removeEventListener('mousewheel', this.resetIdleCounter);
    document.removeEventListener('click', this.resetIdleCounter);
  };

  render() {
    return (
      <StateContext.Provider
        value={{
          state: this.state,
          userLogout: () => this.userLogout(),
          login: (query) => this.login(query),
          setUser: (user) => this.setUser(user),
          getUser: () => this.getUser(),
          setCurrentRoute: (route) => this.setCurrentRoute(route),
          getCurrentRoute: () => this.getCurrentRoute(),
          setLoginError: (bool) => this.setLoginError(bool),
          updateUser: () => this.updateUser(),
        }}
      >
        {this.props.children}
      </StateContext.Provider>
    );
  }
}
