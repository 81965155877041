import { Constants } from '../../../services/constant-service';
import { TextInput } from '../../common/inputs/text-input/text-input';
import { TextAreaInput } from '../../common/inputs/text-area/text-area';
import { Required } from '../../common/styles/shared';
import {
  FormSection,
  FormSectionHeader,
  FormSectionSubHeader,
  TextInputContainer,
  TextAreaContainer,
  InputControl,
  ControlHeadline,
} from './form-parts-style';

export const SoftWareFormPart = ({ form, onChange }: { form: any; onChange: (e: any, value?: string, index?: number) => void }) => {
  const version = form.get(Constants.uploadForm.version.name);
  const releaseNotes = form.get(Constants.uploadForm.releaseNotes.name);

  return (
    <FormSection>
      <FormSectionHeader>
        Software version
        <FormSectionSubHeader>
          Enter the version of the main file you are uploading together with a description for this version (e.g., “1.0” and “Released
          revision”.). Note that you can only edit the text for the current version and not old ones.
        </FormSectionSubHeader>
      </FormSectionHeader>
      <InputControl>
        <ControlHeadline>
          {version.label}
          <Required>*</Required>
        </ControlHeadline>
        <TextInputContainer>
          <TextInput
            type="text"
            value={version.value}
            name={version.name}
            width="100%"
            placeholder={version.placeHolder}
            onChange={(e: any) => onChange(e)}
          />
        </TextInputContainer>
      </InputControl>

      <InputControl>
        <ControlHeadline>
          {releaseNotes.label}
          <Required>*</Required>
        </ControlHeadline>
        <TextAreaContainer>
          <TextAreaInput
            name={releaseNotes.name}
            value={releaseNotes.value}
            width="100%"
            placeholder={releaseNotes.placeHolder}
            onChange={(e: any) => onChange(e)}
          />
        </TextAreaContainer>
      </InputControl>
    </FormSection>
  );
};
