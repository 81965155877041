import React from 'react';
import { Link } from 'react-router-dom';
import { Constants } from '../../../services/constant-service';
import Upload from '../../../assets/images/upload.svg';
import { MenuIconRow, MenuIcon } from '../menu/menu-style';
import { TextRegularMedium } from '../../common/styles/shared';

interface AddNewProps {
  onClick: any; // PropTypes.func,
  selectedAppType: any; // PropTypes.object,
}

export const AddNew = ({ selectedAppType, onClick }: AddNewProps) => (
  <Link to={`/type/${selectedAppType.name}${Constants.routes.addApplication}`} /*push="true" REMOVE?*/>
    <MenuIconRow onClick={onClick}>
      <MenuIcon img={Upload}></MenuIcon>
      <TextRegularMedium>Add new item</TextRegularMedium>
    </MenuIconRow>
  </Link>
);
