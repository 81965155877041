import React from 'react';
import { isEmpty } from 'lodash';
import { Constants } from '../../../services/constant-service';
import { FormFileCard } from './filecard/filecard';
import { FileUpload } from '../../common/file-upload/file-upload';
import { UploadNewVersion } from '../../common/file-upload/upload-new-version';
import { FormSection, FormSectionHeader, FileSection } from './form-parts-style';
import { TextButton, FileGroupContainer, FileGroupHeadLine, Required } from '../../common/styles/shared';

type FileUpdate = (e: any, groupIndex: number, fileIndex?: number) => void;

interface FilesFormProps {
  form: any;
  onAddFile: (e: any, optionIndex: number) => void;
  onUpdateFile: FileUpdate;
  onRemove: FileUpdate;
  onRevert: FileUpdate;
  fileType: string;
  onChangeSearchTag?: (e: any, index?: number) => void;
}

export const FilesFormPart = ({ form, onAddFile, onUpdateFile, onRemove, onRevert, fileType, onChangeSearchTag }: FilesFormProps) => {
  const files = form.get(fileType);

  const getMultipleFilesActionButtons = (group, groupIndex, option, optionIndex) => {
    const { status } = Constants;
    const showReplace = option.status === status.unchanged;
    const showUndo = option.status === status.updated || option.status === status.deleted || option.status === status.edited;
    const showRemove = option.status !== status.deleted && !showUndo;

    return (
      <React.Fragment>
        {showReplace && <UploadNewVersion name={group.name} onChange={(e) => onUpdateFile(e, groupIndex, optionIndex)} />}
        {showUndo && (
          <TextButton name={group.name} onClick={(e) => onRevert(e, groupIndex, optionIndex)}>
            Undo
          </TextButton>
        )}
        {showRemove && (
          <TextButton name={group.name} onClick={(e) => onRemove(e, groupIndex, optionIndex)}>
            Remove
          </TextButton>
        )}
      </React.Fragment>
    );
  };

  const getSingleFileActionButtons = (group, index, option) => {
    const { status } = Constants;
    const showReplace = option.file.status === status.unchanged;
    const showRemove = option.file.status === status.new;
    const showUndo = option.file.status === status.updated || option.file.status === status.edited;

    return (
      <React.Fragment>
        {showReplace && <UploadNewVersion name={group.name} onChange={(e) => onUpdateFile(e, index)} />}
        {showUndo && (
          <TextButton name={group.name} onClick={(e) => onRevert(e, index)}>
            Undo
          </TextButton>
        )}
        {showRemove && (
          <TextButton name={group.name} onClick={(e) => onRemove(e, index)}>
            Remove
          </TextButton>
        )}
      </React.Fragment>
    );
  };

  const renderFileList = (group, index, option) => {
    return option.files.map((item, i) => {
      return (
        <React.Fragment key={i}>
          {item && (
            <FormFileCard
              file={item}
              buttonComponent={() => getMultipleFilesActionButtons(group, index, item, i)}
              onChangeSearchTag={(e) => onChangeSearchTag?.(e, i)}
              tagGroup={group}
            />
          )}
        </React.Fragment>
      );
    });
  };

  const renderFileSection = (option, index) => {
    let fileList: any = null;
    if (option.multipleSelection) {
      fileList = (
        <>
          {renderFileList(files, index, option)}
          <FileUpload name={files.name} value={option.value} onChange={(e) => onAddFile(e, index)} />
        </>
      );
    } else {
      if (isEmpty(option.file)) {
        fileList = <FileUpload name={files.name} value={option.value} onChange={(e) => onAddFile(e, index)} />;
      } else {
        fileList = (
          <>
            <FormFileCard
              file={option.file}
              buttonComponent={() => getSingleFileActionButtons(files, index, option)}
              onChangeSearchTag={(e) => onChangeSearchTag?.(e)}
              tagGroup={files}
            />
          </>
        );
      }
    }

    return <FileSection>{fileList}</FileSection>;
  };

  return (
    <FormSection>
      <FormSectionHeader>{files.label}</FormSectionHeader>
      {files.options.map((option, index) => {
        return (
          <FileGroupContainer active="true" key={index}>
            <FileGroupHeadLine>
              {option.name}
              {files.validationRules.isRequiredFile && <Required>*</Required>}
            </FileGroupHeadLine>
            {renderFileSection(option, index)}
          </FileGroupContainer>
        );
      })}
    </FormSection>
  );
};
