import 'react-app-polyfill/ie11';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { StateProvider } from './app/services/state-context';
import { StateContext } from './app/services/state-context';
import App from './app/app';
import './app/assets/images/favicon.ico';
import './index.css';

const domNode: HTMLElement | null = document.getElementById('app');
if (domNode !== null) {
  const root = createRoot(domNode);
  root.render(
    <StateProvider>
      <StateContext.Consumer>
        {(context) => (
          <Router>
            <App {...context} />
          </Router>
        )}
      </StateContext.Consumer>
    </StateProvider>
  );
} else {
  console.log('No dom node found. Not great');
}
