import styled from 'styled-components';
import { Colors } from '../styles/colors';
import { BoxSection, TextRegularMedium } from '../styles/shared';

export const FileUploadInput: any = styled.input.attrs({ type: 'file', className: 'FileUploadInput' })`
  width: 0.1rem;
  height: 0.1rem;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  cursor: pointer;
`;

export const FileUploadButton: any = styled(BoxSection).attrs({ className: 'FileUploadButton' })`
  width: 30rem;
  display: flex;
  align-items: center;
  border-radius: 0.2rem;
  border: 0.1rem dotted ${Colors.accentSecondary};
  cursor: pointer;
`;

export const FileUploadText: any = styled(TextRegularMedium).attrs({ className: 'FileUploadText' })`
  color: ${Colors.accentSecondary};
  cursor: pointer;
`;
