import React from 'react';
import { Config } from '../../../config/config';
import { MenuIconRow, MenuIcon } from '../menu/menu-style';
import { TextRegularMedium } from '../../common/styles/shared';
import UserIcon from '../../../assets/images/user.svg';

export const MyProfileRedirect = () => {
  const url = Config.idmsCancel;

  return (
    <a href={url}>
      <MenuIconRow>
        <MenuIcon img={UserIcon}></MenuIcon>
        <TextRegularMedium>My Profile</TextRegularMedium>
      </MenuIconRow>
    </a>
  );
};
