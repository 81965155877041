import React from 'react';
import PageHeader from '../page-header/page-header-container';
import { AppContainer } from './default-layout-style';
import { Constants } from '../../services/constant-service';
import { ToastBottom } from '../common/user-messages/toast-bottom';
import { LoginMessage } from '../common/user-messages/login-message';
import { FailedLoginText } from '../common/texts/failed-login-text';

interface DefaultLayoutProps {
  component: any; // PropTypes.func,
  setCurrentRoute: (path: string) => void;
  setLoginError: (erronous: boolean) => void;
  state: any; // PropTypes.object,
  path?: any; // PropTypes.string,
  location?: Location; // PropTypes.object,
}

export const DefaultLayoutContainer = ({ component: Component, ...props }: DefaultLayoutProps) => {
  const path: string | undefined = props.location?.pathname;
  const customErrorCode = 0;
  const isIdmsRedirect = path === Constants.routes.login || path === Constants.routes.logout || path === Constants.routes.cancel;

  if (!isIdmsRedirect && path) {
    props.setCurrentRoute(path);
  }

  const getLoginErrorComponent = () => {
    return <LoginMessage component={FailedLoginText} />;
  };

  return (
    <AppContainer {...props}>
      {!isIdmsRedirect && <PageHeader {...props} />}
      <Component {...props} />

      {props.state.loginError && (
        <ToastBottom responseCode={customErrorCode} component={getLoginErrorComponent} onClose={() => props.setLoginError(false)} />
      )}
    </AppContainer>
  );
};
