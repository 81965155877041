import styled from 'styled-components';
import { Colors } from '../common/styles/colors';

export const AboutPageContainer: any = styled.div.attrs({ className: 'AboutPageContainer' })`
  width: 100%;
  padding: 1.2rem 0;
  max-width: 70rem;
`;

export const AboutTextContainer: any = styled.div.attrs({ className: 'AboutTextContainer' })`
  margin-top: 1.6rem;
`;

export const AboutTextSection: any = styled.div.attrs({ className: 'AboutTextSection' })`
  margin-top: 0.8rem;
`;

export const AboutSchneiderLink: any = styled.span.attrs({ className: 'AboutSchneiderLink' })`
  color: ${Colors.accentSecondary};
  cursor: pointer;
`;
