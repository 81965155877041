import React from 'react';
import { ToSelectorButton } from '../../common/back-button/to-selector-button';
import { MyAppsText } from '../../common/texts/my-apps-text';
import { SelectorPageHeader } from '../selector-page-style';
import { SectionTitle, Margin, TextRegularDefault } from '../../common/styles/shared';

export const MyAppsHeader = ({ selectedAppType }: { selectedAppType: any }) => (
  <SelectorPageHeader>
    <Margin bottom="1.6rem">
      <ToSelectorButton selectedAppType={selectedAppType} />
    </Margin>
    <SectionTitle>
      <MyAppsText />
    </SectionTitle>
    <TextRegularDefault>History of items of the currently selected type where you have downloaded software files from.</TextRegularDefault>
  </SelectorPageHeader>
);
