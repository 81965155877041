import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { cloneDeep as CloneDeep, last } from 'lodash';
import { AppFormChangeService } from '../../../services/application-form-change-service';
import { Constants, isStandardApplication } from '../../../services/constant-service';
import { ToastBottom } from '../../common/user-messages/toast-bottom';
import { BasicsFormPart } from '../application-form-parts/basics';
import { SoftWareFormPart } from '../application-form-parts/software';
import { ModelFormPart } from '../application-form-parts/model';
import { CertificationsFormPart } from '../application-form-parts/certifications';
import { TagGroupsFormPart } from '../application-form-parts/tagGroups';
import { FilesFormPart } from '../application-form-parts/files';
import { Colors } from '../../common/styles/colors';
import { SaveCancelResetButtons } from '../application-form-parts/saveCancel';
import { DeleteButtons } from '../application-form-parts/delete';
import { Response } from '../../../interfaces/common';
import { AppType, Application } from '../../../interfaces/applications';

interface AppFormProps {
  form: any; // PropTypes.object,
  uploading: boolean;
  applications: Application[];
  originalForm: any; // PropTypes.object,
  onSubmit: (form: any) => Promise<Response>;
  selectedAppType: AppType;
  redirectUpdate: () => Promise<void>;
  onDelete?: () => Promise<Response>;
  isEdit?: boolean;
}

interface AppFormState {
  form: any;
  formIsValid: boolean;
  originalForm: any;
  showDescExample: boolean;
  responseCode: number;
  responseMessage: string;
  redirect: boolean;
}

export class AppFormContainer extends Component<AppFormProps, AppFormState> {
  constructor(props: AppFormProps) {
    super(props);

    this.state = {
      form: undefined,
      formIsValid: false,
      originalForm: undefined,
      showDescExample: false,
      responseCode: 0,
      responseMessage: '',
      redirect: false,
    };
  }

  componentDidMount() {
    this.setState({
      form: this.props.form,
      formIsValid: false,
      originalForm: this.props.originalForm,
    });
  }

  componentDidUpdate(prevProps: AppFormProps) {
    if (prevProps.form !== this.props.form) {
      this.setState({ form: this.props.form });
    }

    if (prevProps.originalForm !== this.props.originalForm) {
      this.setState({ originalForm: this.props.originalForm });
    }
  }

  setSelected = (updatedFormElement: any, value: string, index: number) => {
    let option = updatedFormElement.options[index];

    if (updatedFormElement.selected !== undefined) {
      //single option choice
      updatedFormElement.selected = value;
    } else {
      //multiple option choice
      option.selected = !option.selected;
    }
  };

  handleFormChange = (elem: any, value?: string, index?: number) => {
    const { form } = this.state;
    const { applications } = this.props;

    //differ between radio/checkbox and regular input elements
    const name = elem.target ? elem.target.name : elem;
    const elemValue = elem.target ? elem.target.value : value;
    let updatedElement = form.get(name);
    const originalName = this.props.originalForm.get(Constants.uploadForm.name.name).value;

    if (index !== undefined) {
      //if input belongs to a multiple selection group
      this.setSelected(updatedElement, elemValue, index);
    } else {
      updatedElement.value = elemValue;
      updatedElement.touched = true;
    }

    updatedElement.valid = AppFormChangeService.isElementValid(elemValue, updatedElement, applications, originalName);

    this.setState({ form }, () => this.setFormIsValid(form));
  };

  addFile = (e: any, optionIndex: number) => {
    const { form } = this.state;
    const name = e.target.name;
    const formgroup = form.get(name);
    let updatedElement = formgroup.options[optionIndex];
    let existingFile = false;

    const file = e.target.files[0];

    if (!this.isAllowedFiletype(file)) {
      e.target.value = '';
      return this.setFiletypeMessage();
    }

    file.status = Constants.status.new;

    if (updatedElement.multipleSelection) {
      existingFile = updatedElement.files.find((f) => f.name === file.name);

      if (existingFile) {
        this.setFileExistMessage();
      } else {
        updatedElement.files = [...updatedElement.files, file];
      }
    } else {
      updatedElement.file = file;
    }
    formgroup.valid = AppFormChangeService.isElementValid(file, formgroup);

    this.setState({ form }, () => this.setFormIsValid(form));
    e.target.value = '';
  };

  updateFile = (e: any, groupIndex: number, fileIndex?: number) => {
    const { form } = this.state;
    let updatedElement = AppFormChangeService.getChangedFileElem(e, form, groupIndex);

    const file = e.target.files[0];

    if (!this.isAllowedFiletype(file)) {
      this.resetTarget(e);
      return this.setFiletypeMessage();
    }

    if (fileIndex !== undefined) {
      if (updatedElement.files[fileIndex].name != file.name) {
        return this.setSameNameRequiredMessage();
      }
      updatedElement.files[fileIndex] = file;
      updatedElement.files[fileIndex].status = Constants.status.updated;
    } else {
      updatedElement.file = file;
      updatedElement.file.status = Constants.status.updated;
    }

    this.setState({ form });

    this.resetTarget(e);
  };

  resetTarget = (e: any) => {
    e.target.value = '';
  };

  updateSearchTag = (e: any, fileIndex?: number) => {
    const formClone = CloneDeep(this.state.form);
    const { status } = Constants;

    let updatedElement = AppFormChangeService.getChangedFileElemByFileName(formClone, e.target.name, fileIndex);
    let originalElement = AppFormChangeService.getChangedFileElemByFileName(this.state.originalForm, e.target.name, fileIndex);

    const newSearchTag = e.target.value;

    if (fileIndex !== undefined) {
      updatedElement.files[fileIndex].searchTag = newSearchTag;
      if (updatedElement.files[fileIndex].status != status.new && updatedElement.files[fileIndex].status != status.updated) {
        updatedElement.files[fileIndex].status =
          originalElement?.files[fileIndex].searchTag != newSearchTag ? status.edited : status.unchanged;
      }
    } else {
      updatedElement.file.searchTag = newSearchTag;
      if (updatedElement.file.status != status.new && updatedElement.file.status != status.updated) {
        updatedElement.file.status = originalElement?.file.searchTag != newSearchTag ? status.edited : status.unchanged;
      }
    }

    this.setState({ form: formClone });
  };

  revertFile = (e: any, groupIndex: number, fileIndex?: number) => {
    const { form } = this.state;
    const { originalForm } = this.props;
    let updatedElement = AppFormChangeService.getChangedFileElem(e, form, groupIndex);

    const originalFormGroup = originalForm.get(e.target.name);
    let originalFormElement = originalFormGroup.options[groupIndex];

    if (fileIndex !== undefined) {
      updatedElement.files[fileIndex] = { ...originalFormElement.files[fileIndex], status: Constants.status.unchanged };
    } else {
      updatedElement.file = { ...originalFormElement.file, status: Constants.status.unchanged };
    }

    this.setStateAndValidity(e, fileIndex !== undefined ? updatedElement.files[fileIndex] : updatedElement.file, form);
  };

  removeFile = (e: any, groupIndex: number, fileIndex?: number) => {
    const { form } = this.state;
    let updatedElement = AppFormChangeService.getChangedFileElem(e, form, groupIndex);

    const file = fileIndex !== undefined ? updatedElement.files[fileIndex] : updatedElement.file;

    const isNewFile = file.status === Constants.status.new;

    if (isNewFile) {
      this.deleteFile(updatedElement, file, fileIndex);
    } else {
      file.status = Constants.status.deleted;
    }

    this.setStateAndValidity(e, file, form);
  };

  deleteFile = (updatedElement: any, file: File, fileIndex?: number) => {
    if (fileIndex !== undefined) {
      return (updatedElement.files = updatedElement.files.filter((item: File) => item.name !== file.name));
    } else {
      return (updatedElement.file = '');
    }
  };

  setFileExistMessage = () => {
    return this.setState({
      responseCode: 400,
      responseMessage: `Filename already exists.
      Try to remove the existing file or choose "Replace".`,
    });
  };

  setFormIsValid = (form: any) => {
    const formIsValid = AppFormChangeService.isFormValid(form);
    return this.setState({ formIsValid });
  };

  setStateAndValidity = (e: any, file: File, form: any) => {
    const group = form.get(e.target.name);
    group.valid = AppFormChangeService.isElementValid(file, group);

    this.setState({ form }, () => this.setFormIsValid(form));
  };

  submit = async () => {
    const { onSubmit } = this.props;
    const { form } = this.state;
    const resp = await onSubmit(form);

    if (resp) {
      if (resp.status === 200) {
        this.updateAndRelocate();
      } else {
        this.setState({
          responseCode: resp.status,
          responseMessage: resp.message,
        });
      }
    }
  };

  delete = async () => {
    const { onDelete } = this.props;
    const resp = await onDelete?.();

    if (resp) {
      if (resp.status === 200) {
        this.updateAndRelocate();
      } else {
        this.setState({
          responseCode: resp.status,
          responseMessage: resp.message,
        });
      }
    }
  };

  updateAndRelocate = async () => {
    await this.props.redirectUpdate();
    this.setState({ redirect: true });
  };

  reset = () => {
    const { originalForm } = this.props;
    return this.setState({ form: CloneDeep(originalForm), formIsValid: false });
  };

  cancel = () => {
    const { originalForm } = this.props;
    this.setState({ form: CloneDeep(originalForm), formIsValid: false, redirect: true });
  };

  toggleDescExample = () => {
    return this.setState({
      showDescExample: !this.state.showDescExample,
    });
  };

  hideToast = () => {
    return this.setState({
      responseCode: 0,
      responseMessage: '',
    });
  };

  isAllowedFiletype = (file: File) => {
    if (file.type === undefined) {
      return true;
    } else {
      const ext = this.getFileExtension(file.name).toLowerCase();
      return Constants.extensions.find((e) => e === ext);
    }
  };

  getFileExtension = (fileName: string): string => {
    const parts = fileName.split('.');
    return last(parts) as string;
  };

  setFiletypeMessage = () => {
    return this.setState({
      responseCode: 500,
      responseMessage: `Wrong file type.
        Following file types are allowed: ${Constants.extensions}`,
    });
  };

  setSameNameRequiredMessage = () => {
    return this.setState({
      responseCode: 500,
      responseMessage: `When replacing a file, the new file must have the same name for versioning to be correct.`,
    });
  };

  renderStandardApplicationForms(form: any) {
    return (
      <>
        <ModelFormPart form={form} onChange={this.handleFormChange} />
        <CertificationsFormPart form={form} onChange={this.handleFormChange} />
      </>
    );
  }

  render() {
    const { uploading, selectedAppType, isEdit } = this.props;
    const { form, formIsValid, showDescExample, responseCode, responseMessage, redirect } = this.state;
    const isStandardApp = isStandardApplication(selectedAppType.id);

    if (redirect) {
      return <Redirect to={{ pathname: `/type/${selectedAppType.name}` }} push={true} /*reload={true}*/ />;
    }

    return (
      <React.Fragment>
        {form && (
          <React.Fragment>
            {responseCode !== 0 && (
              <React.Fragment>
                {(responseCode === 500 || responseCode === 400) && (
                  <ToastBottom responseCode={responseCode} text={responseMessage} color={Colors.bgError} onClose={this.hideToast} />
                )}
                {responseCode === 200 && <ToastBottom responseCode={responseCode} text={responseMessage} onClose={this.hideToast} />}
                {(responseCode === 401 || responseCode === 403) && <ToastBottom responseCode={responseCode} onClose={this.hideToast} />}
              </React.Fragment>
            )}

            <BasicsFormPart
              selectedAppType={selectedAppType}
              form={form}
              onChange={this.handleFormChange}
              onToggleDescExemple={this.toggleDescExample}
              showDescExample={showDescExample}
            />
            <SoftWareFormPart form={form} onChange={this.handleFormChange} />
            {isStandardApp && this.renderStandardApplicationForms(form)}
            <TagGroupsFormPart form={form} onChange={this.handleFormChange} />
            <FilesFormPart
              form={form}
              onAddFile={this.addFile}
              onUpdateFile={this.updateFile}
              onRevert={this.revertFile}
              onRemove={this.removeFile}
              onChangeSearchTag={this.updateSearchTag}
              fileType={Constants.swFiles}
            />
            <FilesFormPart
              form={form}
              onAddFile={this.addFile}
              onUpdateFile={this.updateFile}
              onRevert={this.revertFile}
              onRemove={this.removeFile}
              fileType={Constants.docFiles}
            />

            {isEdit && <DeleteButtons onDelete={this.delete} />}

            <SaveCancelResetButtons
              uploading={uploading}
              onCancel={this.cancel}
              onSave={this.submit}
              onReset={this.reset}
              formIsValid={formIsValid}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
