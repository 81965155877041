import React from 'react';
import { AppFormContainer } from '../application-form/application-form-container';
import { PageLoaderContainer } from '../admin-style';
import { PageNotFound } from '../../common/error-pages/page-not-found';
import { Colors } from '../../common/styles/colors';
import { PageLoaderWhite } from '../../common/loaders/page-loader-white';
import { PageTitle, PageScroll, AdminPageHeader, BackDropTransparent } from '../../common/styles/shared';
import { Response } from '../../../interfaces/common';
import { AppType, Application } from '../../../interfaces/applications';

interface EditAppPageProps {
  app: Application;
  form: any;
  applications: Application[];
  originalForm: any;
  loading: boolean;
  uploading: boolean;
  deleting: boolean;
  onSubmit: (form: any) => Promise<Response>;
  responseCode: number;
  responseMessage?: string;
  selectedAppType: AppType;
  redirectUpdate: () => Promise<void>;
  onDelete: () => Promise<Response>;
}

export const EditAppPage = ({
  app,
  form,
  loading,
  uploading,
  deleting,
  applications,
  originalForm,
  onSubmit,
  responseCode,
  selectedAppType,
  redirectUpdate,
  onDelete,
}: EditAppPageProps) => {
  return (
    <React.Fragment>
      {(loading || uploading || deleting) && (
        <React.Fragment>
          <BackDropTransparent visible={loading || uploading || deleting}></BackDropTransparent>
          <PageLoaderContainer>
            <PageLoaderWhite text={uploading ? 'Uploading...' : deleting ? 'Deleting...' : ''} />
          </PageLoaderContainer>
        </React.Fragment>
      )}

      {responseCode !== 0 && responseCode === 404 && <PageNotFound />}

      {form && (
        <PageScroll>
          <AdminPageHeader>
            <div>
              <PageTitle color={Colors.textAccentPrimary}>Edit {app.applicationCode}</PageTitle>* required fields
            </div>
          </AdminPageHeader>
          <AppFormContainer
            form={form}
            uploading={uploading}
            applications={applications}
            originalForm={originalForm}
            onSubmit={onSubmit}
            selectedAppType={selectedAppType}
            redirectUpdate={redirectUpdate}
            isEdit={true}
            onDelete={onDelete}
          />
        </PageScroll>
      )}
    </React.Fragment>
  );
};
