import { last } from 'lodash';
import { Constants } from '../../../../services/constant-service';
import { DownLoadFileBtn } from '../action-buttons/download-file';
import { FileCardContainer, FileCardText, DownLoadButtons } from './file-card-style';
import { File } from '../../../../interfaces/files';
import { ShapedUser } from '../../../../interfaces/users';

const getLastPart = (array) => {
  return last(array);
};

const getFileExtension = (fileName) => {
  const parts = fileName.split('.');
  const lastPart = getLastPart(parts);
  return setFileExtension(lastPart);
};

const setFileExtension = (lastPart) => {
  return extensionExists(lastPart) ? lastPart : 'file';
};

const removeExtension = (fileName) => {
  let parts = fileName.split('.');
  const lastPart = getLastPart(parts);

  if (extensionExists(lastPart)) {
    return parts.slice(0, -1).join('.');
  } else {
    return fileName;
  }
};

const extensionExists = (extension) => {
  return Constants.extensions.includes(extension.toLowerCase());
};

interface FileCardProps {
  file: File;
  groupIndex: number;
  user: ShapedUser;
  onDownload: (groupIndex: number, file: File) => void;
  isDownloading: boolean;
}

export const FileCard = ({ file, groupIndex, user, isDownloading, onDownload }: FileCardProps) => (
  <FileCardContainer onClick={() => onDownload(groupIndex, file)} active={user && user.isDownLoader}>
    <FileCardText>{removeExtension(file.name)}</FileCardText>
    <DownLoadButtons>
      <DownLoadFileBtn isDownloading={isDownloading} type={getFileExtension(file.name)} />
    </DownLoadButtons>
  </FileCardContainer>
);
