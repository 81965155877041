import React from 'react';
import { ResultList, ResultListRow } from '../search-result-style';
import { TextRegularDefault, TextRegularMediumBold } from '../../../common/styles/shared';

interface DownloadResultProps {
  filteredList: any; // PropTypes.array,
  onResultSelect: any; // PropTypes.func,
  cursor: any; // PropTypes.number,
  onRowHover: any; // PropTypes.func,
}

export const DownLoadResultList = ({ ...props }: DownloadResultProps) => (
  <ResultList>
    {props.filteredList.map((app, index) => {
      return (
        <ResultListRow
          key={index}
          tabindex={index}
          onMouseDown={() => props.onResultSelect(app)}
          active={index === props.cursor}
          onmouseover={props.onRowHover}
        >
          <TextRegularMediumBold>{app.applicationCode}</TextRegularMediumBold>
          <TextRegularDefault>{app.name}</TextRegularDefault>
        </ResultListRow>
      );
    })}
  </ResultList>
);
