import styled from 'styled-components';
import { Colors } from '../../common/styles/colors';
import { Devices } from '../../common/styles/devices';
import { Paragraph } from '../../common/styles/shared';

export const DetailsContainer: any = styled.div.attrs({ className: 'DetailsContainer' })`
  @media ${Devices.breakMedium} {
    padding: 0 1.6rem;
  }
`;

export const DescriptionText: any = styled(Paragraph).attrs({ className: 'DescriptionText' })`
  margin-bottom: 1.6rem;
  white-space: pre-line;
`;

export const Flex: any = styled.div.attrs({ className: 'Flex' })`
  display: flex;
  flex-wrap: wrap;
`;

export const RequirementsContent: any = styled.div.attrs({ className: 'RequirementsContent' })`
  padding: 0.8rem 0 1.6rem 0;
  border-bottom: 0.1rem solid ${Colors.borderPrimary};
`;

export const VersionContent: any = styled.div.attrs({ className: 'VersionContent' })`
  padding: 1.6rem 0;
  border-bottom: 0.1rem solid ${Colors.borderPrimary};
`;

export const TextButtonContainer: any = styled.div.attrs({ className: 'TextButtonContainer' })`
  margin-top: 1.6rem;

  span {
    &:first-of-type {
      padding-right: 1.2rem;
      border-right: 0.1rem solid ${Colors.borderPrimary};
    }

    &:last-of-type {
      padding-left: 1.2rem;
    }
  }
`;
