import { Constants } from '../../../services/constant-service';
import { CheckBox } from '../../common/inputs/checkbox/checkbox';
import { RadioButton } from '../../common/inputs/radiobutton/radiobutton';
import { FormSection, FormSectionFlex, FormSectionHeader, FormSectionSubHeader, InputControlGroup } from './form-parts-style';
import { Margin, RowContainer, TextRegularMedium } from '../../common/styles/shared';
import { ShapedTagGroup } from '../../../interfaces/tags';

const filter = (modelGroupId: number, certificationId: number, form: any) => {
  return Array.from(form.values()).filter(
    (group: any) => group.id !== modelGroupId && group.id !== certificationId && group.id !== undefined
  ) as ShapedTagGroup[];
};

interface TagGroupsProps {
  form: any;
  onChange: (elem: any, value?: string, index?: number) => void;
}

export const TagGroupsFormPart = (props: TagGroupsProps) => {
  const { form, onChange } = props;
  const tagGroups: ShapedTagGroup[] = filter(Constants.modelGroupId, Constants.certificationsId, form);

  return (
    <FormSection>
      <FormSectionFlex>
        {tagGroups.map((group, index) => {
          return (
            <InputControlGroup key={index}>
              <FormSectionHeader>
                {group.label}
                <FormSectionSubHeader>{group.description}</FormSectionSubHeader>
              </FormSectionHeader>
              {group.options.map((option, i: number) => {
                return (
                  <Margin top="1.6rem" key={i}>
                    <RowContainer onClick={() => onChange(group.name, option.value, i)}>
                      {group.multipleSelection ? (
                        <CheckBox checked={option.selected ?? false} />
                      ) : (
                        <RadioButton checked={group.selected == option.value} />
                      )}

                      <TextRegularMedium>{option.label}</TextRegularMedium>
                    </RowContainer>
                  </Margin>
                );
              })}
            </InputControlGroup>
          );
        })}
      </FormSectionFlex>
    </FormSection>
  );
};
