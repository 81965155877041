import { Component } from 'react';
import { Redirect } from 'react-router-dom';

interface IDMSCancelProps {
  getCurrentRoute: any;
}

/*Idms redirects to this path when user is visiting idms my profile page an clicks cancel */
export class IDMSCancelContainer extends Component<IDMSCancelProps, {}> {
  constructor(props: IDMSCancelProps) {
    super(props);
  }

  render() {
    const { getCurrentRoute } = this.props;

    return <Redirect to={{ pathname: getCurrentRoute() }} push={true} />;
  }
}
