import styled from 'styled-components';
import { Devices } from '../common/styles/devices';
import { BackDrop } from '../common/styles/shared';

export const PopUpBackDrop: any = styled(BackDrop).attrs({ className: 'PopUpBackDrop' })`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
`;

export const PageHeader: any = styled.div.attrs({ className: 'PageHeader' })`
  padding: 1.2rem 0;

  @media ${Devices.breakMedium} {
    margin: 1.2rem 1.6rem;
  }
`;

export const FlexStretch: any = styled.div.attrs({ className: 'FlexStretch' })`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${Devices.breakMedium} {
    width: 85%;
  }
`;
