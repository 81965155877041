import { Deleted, New, NewVersion, FileStatusText } from './status-style';

export const NewVersionStatus = () => (
  <NewVersion>
    <FileStatusText>New version</FileStatusText>
  </NewVersion>
);

export const EditedStatus = () => (
  <NewVersion>
    <FileStatusText>Edited</FileStatusText>
  </NewVersion>
);

export const DeletedStatus = () => (
  <Deleted>
    <FileStatusText>Removed</FileStatusText>
  </Deleted>
);

export const NewStatus = () => (
  <New>
    <FileStatusText>New</FileStatusText>
  </New>
);
