import styled from 'styled-components';
import { Colors } from '../common/styles/colors';

export const AppContainer: any = styled.div.attrs({ className: 'AppContainer' })`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  grid-template-rows: 11.2rem 88.2rem;
  color: ${Colors.textPrimary};
  background: ${(props: any) => (props.color ? props.color : Colors.bgPrimary)};
`;
