import { ApiService } from '../../services/api-service';
import { Constants } from '../../services/constant-service';
import { Colors } from '../common/styles/colors';

export const getListItems = async (path) => {
  if (isStatisticsPage(path)) {
    return shapeStatisticsApp(await ApiService.getUsageStatistics());
  } else if (isCAValuesPage(path)) {
    return await ApiService.getCAValues();
  }

  return null;
};

export const getColumns = (path) => {
  if (isStatisticsPage(path)) {
    return getStatisticsColumns();
  } else if (isCAValuesPage(path)) {
    return getCAValuesColumns();
  }

  return null;
};

export const getDefaultColumnName = (columns) => {
  return columns.find((col) => col.default).name;
};

export const getPageInformation = (path) => {
  if (isStatisticsPage(path)) {
    return getStatisticsPageInformation();
  } else if (isCAValuesPage(path)) {
    return getCAValuesPageInformation();
  }

  return null;
};

const isCAValuesPage = (path) => {
  return path.includes(Constants.routes.caValues);
};

export const isStatisticsPage = (path) => {
  return path.includes(Constants.routes.statistics);
};

const getCAValuesColumns = () => {
  return [
    {
      name: 'testedApplication',
      display: 'Tested Application',
      enableSort: true,
      default: true,
      active: true,
      desc: false,
      searchable: true,
    },
    {
      name: 'productIdentification',
      display: 'Product Identification',
      enableSort: true,
      default: false,
      active: false,
      desc: false,
      searchable: true,
    },
    {
      name: 'heatingValue',
      display: 'Heating value (K)',
      enableSort: true,
      default: false,
      active: false,
      desc: false,
      searchable: true,
      bold: true,
    },
    {
      name: 'coolingValue',
      display: 'Cooling value (K)',
      enableSort: true,
      default: false,
      active: false,
      desc: false,
      searchable: true,
      bold: true,
    },
  ];
};

const getStatisticsColumns = () => {
  return [
    {
      name: 'applicationCode',
      display: 'Application ID',
      enableSort: false,
      default: false,
      active: false,
      color: Colors.textAccentPrimary,
      searchable: true,
    },
    { name: 'applicationName', display: 'Application name', enableSort: false, default: false, active: false, searchable: true },
    { name: 'imageFileCount', display: 'SW Image', desc: false, enableSort: true, default: false, active: false, bold: true },
    { name: 'exportFileCount', display: 'EBO Export', desc: false, enableSort: true, default: false, active: false, bold: true },
    {
      name: 'otherSoftwareFileCount',
      display: 'Other software',
      desc: false,
      enableSort: true,
      default: false,
      active: false,
      bold: true,
    },
    { name: 'formattedDate', display: 'Last published', desc: false, enableSort: true, default: true, active: true, isDate: true },
  ];
};

const getStatisticsPageInformation = () => {
  return {
    header: 'Statistics',
    description: 'Download statistics for software image and export files.',
  };
};

const getCAValuesPageInformation = () => {
  return {
    header: 'Ca Values',
    description: `Heating and cooling values of individual zone controller products licensed by Schneider Electric at https://www.eubaccert.eu/`,
  };
};

const shapeStatisticsApp = (applications) => {
  return applications.map((app) => {
    app.formattedDate = new Intl.DateTimeFormat(Constants.defaultDateFormat).format(new Date(app.lastPublished));
    return app;
  });
};
