import React from 'react';
import { NoRightsText } from '../../../common/texts/no-rights-text';
import { NoRightsContainer } from './userstate-style';

export const NoRights = () => {
  return (
    <NoRightsContainer>
      <NoRightsText />
    </NoRightsContainer>
  );
};
