import { ApplicationsContainer, ApplicationsInner } from './applications-list-style';
import { ApplicationCard } from '../application-card/application-card';
import { InformationCard } from './applications-list-info-card';
import { AppType, AppTypeGroup, Application } from '../../../../interfaces/applications';

interface ApplicationsListProps {
  filteredApplications: Application[];
  selectedAppType: AppType;
  appTypeGroup: AppTypeGroup;
  onAppClick: (id: number) => void;
}

export const ApplicationsList = (props: ApplicationsListProps) => (
  <ApplicationsContainer>
    <ApplicationsInner>
      <InformationCard appTypeGroup={props.appTypeGroup} />
      {props.filteredApplications.map((app, index) => (
        <ApplicationCard key={index} app={app} onAppClick={props.onAppClick} selectedAppType={props.selectedAppType} />
      ))}
    </ApplicationsInner>
  </ApplicationsContainer>
);
