import { BmsStartPageLink } from './bms-start-page-link';
import { SupportSection } from './support-section';
import { LogoGreen } from '../logos/logo-green';
import { AppTitle } from '../texts/app-title';
import {
  ErrorPageContainer,
  ErrorPageContent,
  ErrorUnavailableHeader,
  HeaderContent,
  ErrorPageTitle,
  SupportContainer,
} from './error-pages-style';
import { TextRegularMedium, TextRegularMediumBold, Margin } from '../styles/shared';

interface PageUnavailableProps {
  top?: string;
  errorMessage?: string;
  customErrorMessage?: string;
}

export const PageUnavailable = ({ top, errorMessage, customErrorMessage = '' }: PageUnavailableProps) => {
  return (
    <ErrorPageContainer top={top ? top : '0'}>
      <ErrorUnavailableHeader visible={!top}>
        <HeaderContent>
          <AppTitle />
          <LogoGreen />
        </HeaderContent>
      </ErrorUnavailableHeader>

      <ErrorPageContent>
        <Margin top="1.6rem" bottom="1.6rem">
          <ErrorPageTitle>Page currently unavailable</ErrorPageTitle>
        </Margin>
        <Margin top="1.6rem" bottom="1.6rem">
          <TextRegularMediumBold color={'red'}>{customErrorMessage}</TextRegularMediumBold>
        </Margin>
        <ErrorInformation errorMessage={errorMessage} />
        <SupportContainer>
          <SupportSection />
        </SupportContainer>
      </ErrorPageContent>
    </ErrorPageContainer>
  );
};

const ErrorInformation = ({ errorMessage }: { errorMessage?: string }) => {
  if (errorMessage === 'Network Error') {
    return (
      <>
        <Margin top="1.6rem" bottom="1.6rem">
          <TextRegularMedium>
            Sorry, you encountered a Network Error. This could be caused by several things, like firewall settings, network policies or site
            problems.
          </TextRegularMedium>
        </Margin>
        <Margin top="3.2rem" bottom="3.2rem">
          <TextRegularMedium>
            NOTE: The BMS Applications site uses ports 443 and 5001 to communicate over HTTPS, so make sure that they are not blocked on
            your network.
          </TextRegularMedium>
        </Margin>
      </>
    );
  }
  return (
    <>
      <Margin top="1.6rem" bottom="1.6rem">
        <pre>
          <TextRegularMedium>{errorMessage}</TextRegularMedium>
        </pre>
      </Margin>
      <Margin top="1.6rem" bottom="3.2rem">
        <TextRegularMedium>Here are som things you may try:</TextRegularMedium>
      </Margin>

      <Margin bottom="3.2rem">
        <BmsStartPageLink />
      </Margin>
    </>
  );
};
