import styled from 'styled-components';
import { TextLargeBoldItalic, TextMediumRegularItalic } from '../../common/styles/shared';
import { Colors } from '../../common/styles/colors';
import History from '../../../assets/images/history.svg';

export const NoAppsContainer: any = styled.div.attrs({ className: 'NoAppsContainer' })`
  padding: 1.6rem 3.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const NoAppsText: any = styled(TextLargeBoldItalic).attrs({ className: 'NoAppsText' })`
  color: ${Colors.textInput};
  margin-bottom: 0.8rem;
`;

export const NoAppsInfoText: any = styled(TextMediumRegularItalic).attrs({ className: 'NoAppsInfoText' })`
  color: ${Colors.textInput};
  text-align: center;
`;

export const HistoryIcon: any = styled.div.attrs({ className: 'HistoryIcon' })`
  width: 7.6rem;
  height: 7.6rem;
  background: url(${History}) center center;
  background-repeat: no-repeat;
`;
