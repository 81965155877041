import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { DefaultLayoutContainer } from '../layouts/default-layout-container';
import { StateContext } from '../../services/state-context';
import { Constants } from '../../services/constant-service';

const userHasAccess = (user, role) => {
  switch (role) {
    case 'isUpLoader':
      return user.isUpLoader;
    case 'isDownLoader':
      return user.isDownLoader;
    case 'isStatistician':
      return user.isStatistician;
    case 'isAdministrator':
      return user.isAdministrator;
    default:
      return true;
  }
};

export const ProtectedRoute = ({ component: Component, ...props }) => {
  return (
    <Route
      {...props}
      render={() => {
        return (
          <StateContext.Consumer>
            {(context) => {
              const user = context.getUser();
              if (user && userHasAccess(user, props.hasAccess)) {
                return (
                  <React.Fragment>
                    <DefaultLayoutContainer component={Component} {...props} {...context} />
                  </React.Fragment>
                );
              } else {
                return <Redirect to={{ pathname: Constants.routes.root, state: { from: props.location } }} />;
              }
            }}
          </StateContext.Consumer>
        );
      }}
    />
  );
};
