import { Constants } from '../../../../services/constant-service';
import { RadioButton } from '../../inputs/radiobutton/radiobutton';
import { CheckBox } from '../../inputs/checkbox/checkbox';
import { OptionsGroupDropDownBody, OptionsRow } from './filter-options-style';
import { TextRegularMedium } from '../../styles/shared';
import { ShapedTagGroup } from '../../../../interfaces/tags';

interface GroupBodyProps {
  group: ShapedTagGroup;
  onFilter: (name: string, value: string, index: number) => void;
}

export const GroupBody = ({ group, onFilter }: GroupBodyProps) => {
  return (
    <OptionsGroupDropDownBody hidden={group.collapsed}>
      {group.options.map((option, index) => {
        if (option.viewType === Constants.viewType.hidden) {
          return null;
        }
        return (
          <OptionsRow
            key={index}
            disabled={option.value !== Constants.defaultSelected && option.total === 0}
            onClick={() => onFilter(group.name, option.value, index)}
          >
            {group.multipleSelection ? (
              <CheckBox checked={option.selected ? option.selected : false} />
            ) : (
              <RadioButton checked={group.selected == option.value} />
            )}

            <TextRegularMedium>
              {`${option.label} (${option.value == Constants.defaultSelected ? group.total : option.total})`}
            </TextRegularMedium>
          </OptionsRow>
        );
      })}
    </OptionsGroupDropDownBody>
  );
};
