import React from 'react';
import { Link } from 'react-router-dom';
import { Constants } from '../../../services/constant-service';
import { MenuIconRow, MenuIcon } from '../menu/menu-style';
import { TextRegularMedium } from '../../common/styles/shared';
import Statistics from '../../../assets/images/statistics.svg';

export const ViewStatistics = ({ onClick }: { onClick: any }) => (
  <Link to={Constants.routes.statistics} /*push="true" REMOVE?*/>
    <MenuIconRow onClick={onClick}>
      <MenuIcon img={Statistics}></MenuIcon>
      <TextRegularMedium>View download statistics</TextRegularMedium>
    </MenuIconRow>
  </Link>
);
