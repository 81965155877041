import React, { Component, ChangeEvent } from 'react';
import { ApiService } from '../../services/api-service';
import { ErrorMessage, TextRegularDefault, TextRegularMedium, TextRegularMediumBold, TextRegularSmall } from '../common/styles/shared';
import { TextAreaInput } from '../common/inputs/text-area/text-area';
import { ToastBottom } from '../common/user-messages/toast-bottom';
import { FeedBackInputLabel, FeedBackInput, SchneiderLink, MenuSmallInner } from './feedback-style';
import { PopUpAction } from '../common/popup/popup-action';

interface FeedBackContainerProps {
  onCloseFeedback?: (event?: React.MouseEvent<HTMLButtonElement>, responseCode?: number, responseMessage?: string) => void;
}

interface FeedBackContainerState {
  feedBackValue: string | undefined;
  error: boolean;
  responseCode: number;
  responseMessage: string;
}

export class FeedBackContainer extends Component<FeedBackContainerProps, FeedBackContainerState> {
  private feedBackMaxLength = 500;

  constructor(props: FeedBackContainerProps) {
    super(props);

    this.state = {
      feedBackValue: undefined,
      error: false,
      responseCode: 0,
      responseMessage: '',
    };
  }

  handleFeedBack = () => {
    const { feedBackValue } = this.state;
    feedBackValue && feedBackValue.trim() !== '' ? this.submitFeedBack(feedBackValue) : this.setError();
  };

  async submitFeedBack(feedBackValue: string) {
    try {
      await ApiService.sendFeedBack(feedBackValue);
      this.setState({
        responseCode: 200,
        responseMessage: 'Thank you for your feedback!',
      });

      return this.close();
    } catch (error: any) {
      return error.response.status === 500
        ? this.setState({
            responseCode: error.response.status,
            responseMessage: 'Something went wrong sending your feedback. Please try again later.',
          })
        : this.setState({ responseCode: error.response.status });
    }
  }

  close = () => {
    const { onCloseFeedback } = this.props;
    const { responseCode, responseMessage } = this.state;
    onCloseFeedback && onCloseFeedback(undefined, responseCode, responseMessage);

    this.setState({
      feedBackValue: undefined,
      error: false,
      responseCode: 0,
      responseMessage: '',
    });
  };

  setError = () => {
    this.setState({ error: true });
  };

  onFeedbackChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({
      feedBackValue: e.target.value,
      error: false,
    });
  };

  hideToast = () => {
    const { responseCode } = this.state;

    responseCode === 200
      ? this.close()
      : this.setState({
          responseCode: 0,
          responseMessage: '',
        });
  };

  feedBackBody = () => {
    const { error, feedBackValue } = this.state;
    const feedBackLength = feedBackValue == null ? 0 : feedBackValue.length;
    return (
      <MenuSmallInner>
        <TextRegularMedium>Let us know how we can improve BMS Applications.</TextRegularMedium>
        <FeedBackInputLabel>
          <TextRegularMediumBold>Comments</TextRegularMediumBold>
          <TextRegularSmall>{`${feedBackLength} / ${this.feedBackMaxLength}`}</TextRegularSmall>
        </FeedBackInputLabel>
        <FeedBackInput>
          <TextAreaInput
            error={error}
            value={feedBackValue || ''}
            onChange={(e) => this.onFeedbackChange(e)}
            width="100%"
            placeholder="Type your comment or idea here..."
            maxLength={this.feedBackMaxLength}
          />
          <ErrorMessage visible={error}>Comment is required</ErrorMessage>
        </FeedBackInput>
        <TextRegularDefault>
          Thanks for sending us feedback. Though we can’t respond to you directly, we do use feedback like yours to improve the experience.
          <br></br>
          <br></br>
          If you have an immediate issue with a Schneider Electric product, find support contact information in your local SE website,
          within the “Support” section tab. Visit<br></br>
          <a href="http://www.schneider-electric.com">
            <SchneiderLink>http://www.schneider-electric.com&nbsp;</SchneiderLink>
          </a>
          and select your country of origin.
        </TextRegularDefault>
      </MenuSmallInner>
    );
  };

  render() {
    const { responseCode, responseMessage } = this.state;

    return (
      <React.Fragment>
        {responseCode !== 0 && <ToastBottom responseCode={responseCode} text={responseMessage} onClose={this.hideToast} />}

        <PopUpAction title="Send Feedback" onClose={this.close} onAction={this.handleFeedBack} component={this.feedBackBody} />
      </React.Fragment>
    );
  }
}
