import React, { Component } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { Splash } from '../common/loaders/splash';

interface IDMSLogoutProps {
  userLogout: any; // PropTypes.func,
  redirectPath: any; // PropTypes.string,
}

interface IDMSLogoutState {
  loading: boolean;
  redirect: boolean;
}

/*Idms redirects to this path when user is successfully logged out */
export class IDMSLogoutContainer extends Component<IDMSLogoutProps, IDMSLogoutState> {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      redirect: false,
    };
  }

  componentDidMount() {
    this.handleIdmsLogout();
  }

  handleIdmsLogout = async () => {
    const { userLogout } = this.props;
    try {
      await userLogout();
    } catch (error: any) {
      //TODO: show error message
    }
    this.setState({
      loading: false,
      redirect: true,
    });
  };

  render() {
    const { loading, redirect } = this.state;

    return (
      <React.Fragment>
        {loading && <Splash />}
        {!loading && redirect && (
          <Switch>
            <Redirect to={{ pathname: this.props.redirectPath }} /*reload={true}*/ />
          </Switch>
        )}
      </React.Fragment>
    );
  }
}
