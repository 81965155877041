import styled from 'styled-components';
import { Devices } from '../common/styles/devices';
import { Colors } from '../common/styles/colors';
import { BackDrop } from '../common/styles/shared';

export const SmallDeviceScroll: any = styled.div.attrs({ className: 'SmallDeviceScroll' })`
  position: absolute;
  top: 11.2rem;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;

  @media ${Devices.breakLarge} {
    position: static;
    overflow-y: hidden;
  }
`;

export const ResultBar: any = styled.div.attrs({ className: 'ResultBar' })`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.6rem 1.6rem 0 1.6rem;
  border-top: 0.1rem solid ${Colors.borderPrimary};

  @media ${Devices.breakLarge} {
    display: flex;
    align-items: center;

    .filter {
      display: none;
    }
  }

  @media ${Devices.breakMegaLarge} {
    padding: 1.6rem 10% 0;
  }
`;

export const PageLoaderContainer: any = styled.div.attrs({ className: 'PageLoaderContainer' })`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: 4.4rem;
`;

export const SelectorPageHeader: any = styled.div.attrs({ className: 'SelectorPageHeader' })`
  width: 100%;
  padding: 1.6rem;
  display: flex;
  flex-direction: column;

  @media ${Devices.breakMedium} {
    min-height: 15rem;
  }

  @media ${Devices.breakMegaLarge} {
    margin-left: 10%;
    margin-right: 10%;
    padding: 1.6rem 0;
  }
`;

export const SearchBackDrop: any = styled(BackDrop).attrs({ className: 'SearchBackDrop' })`
  @media ${Devices.breakLarge} {
    top: 11.2rem;
  }
`;

export const SearchContainer: any = styled.div.attrs({ className: 'SearchContainer' })`
  position: relative;
  z-index:1;
  background: ${Colors.bgSecondary}
  margin: 1.6rem;

  @media ${Devices.breakMedium} {
    position: absolute;
    right: 1.6rem;
    top: 9rem;
    width: 30rem;
    margin:0;
  }

  @media ${Devices.breakLarge} {
    top: 20rem;
  }

  @media ${Devices.breakMegaLarge} {
    right: 10%;
  }
`;
