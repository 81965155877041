import styled from 'styled-components';
import { Colors } from '../../../common/styles/colors';
import { TextRegularDefault, TextRegularMedium } from '../../../common/styles/shared';

export const LinkRow: any = styled.a.attrs({ className: 'LinkRow' })`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const ExtranetTextWhite: any = styled(TextRegularDefault).attrs({ className: 'ExtranetTextWhite' })`
  color: ${Colors.textLight};
`;

export const ExtranetTextBlue: any = styled(TextRegularMedium).attrs({ className: 'ExtranetTextBlue' })`
  color: ${Colors.accentSecondary};
`;

export const ArrowRight: any = styled.svg.attrs({ className: 'ArrowRight' })`
  width: 1.1rem;
  height: 1.4rem;
  background: url(${(props: any) => props.img}) center right;
  background-repeat: no-repeat;
`;
