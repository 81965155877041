import styled from 'styled-components';
import { Colors } from '../common/styles/colors';

export const MenuSmallInner: any = styled.div.attrs({ className: 'MenuSmallInner' })`
  width: auto;
  height: auto;
  position: relative;
  background: ${Colors.bgSecondary};
`;

export const FeedBackInput: any = styled.div.attrs({ className: 'FeedBackInput' })`
  margin-top: 0.4rem;
  margin-bottom: 0.8rem;
  min-height: 11.4rem;
`;

export const FeedBackInputLabel: any = styled.div.attrs({ className: 'FeedBackInputLabel' })`
  margin-top: 0.8rem;
`;

export const SchneiderLink: any = styled.span.attrs({ className: 'SchneiderLink' })`
  color: ${Colors.accentSecondary};
  cursor: pointer;
`;
