import React from 'react';
import { Login } from './userstate/login';
import { NoRights } from './userstate/no-rights';
import { DownLoadZipBtn } from './action-buttons/download-zip';
import { FileGroup } from './file-group/file-group';
import { FileSelectorContainer, FileSelectorMenu, FileSelectorHeadLine } from './file-selector-style';
import { File, FileType } from '../../../interfaces/files';
import { ShapedUser } from '../../../interfaces/users';
import { Application } from '../../../interfaces/applications';

interface FileSelectorProps {
  app: Application;
  user: ShapedUser;
  fileGroups: FileType[];
  onDownload: (groupIndex: number, file: File) => void;
}

export const FileSelector = ({ app, user, fileGroups, onDownload }: FileSelectorProps) => (
  <FileSelectorContainer>
    <FileSelectorHeadLine>Downloads</FileSelectorHeadLine>
    <FileSelectorMenu>
      {!user && <Login />}
      {user && !user.isDownLoader && <NoRights />}
      <DownLoadZipBtn isDownloading={app.isDownloading || false} user={user} onDownLoad={onDownload} noFiles={app.files.length === 0} />
    </FileSelectorMenu>
    {fileGroups.map((group, index) => {
      return <FileGroup key={index} user={user} groupIndex={index} fileGroup={group} onDownload={onDownload} />;
    })}
  </FileSelectorContainer>
);
