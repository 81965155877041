import React from 'react';
import { MenuIconRow, MenuIcon } from '../menu/menu-style';
import { TextRegularMedium } from '../../common/styles/shared';
import LogoutIcon from '../../../assets/images/logout.svg';

export const Logout = () => (
  <MenuIconRow>
    <MenuIcon img={LogoutIcon}></MenuIcon>
    <TextRegularMedium>Logout</TextRegularMedium>
  </MenuIconRow>
);
