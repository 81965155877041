import React from 'react';
import { FilterOptionsGroup } from './filter-options-group';
import { OptionsHeadLine, Close, Clear } from './filter-options-style';
import { PageTitle, TextButton } from '../../styles/shared';
import { ShapedTagGroup } from '../../../../interfaces/tags';

interface FilterOptionsProps {
  formData: any;
  onFilter: (name: string, value: string, index: number) => void;
  onClear: () => void;
  onToggleGroup: (name: string) => void;
  onToggleFilterOptions: () => void;
}

//TODO: test
const getChildren = (formData, parentId): ShapedTagGroup[] => {
  return Array.from(formData).filter((group: any) => group.parentId === parentId) as ShapedTagGroup[];
};

export const FilterOptions = ({ formData, onFilter, onClear, onToggleGroup, onToggleFilterOptions }: FilterOptionsProps) => {
  return (
    <React.Fragment>
      <OptionsHeadLine>
        <PageTitle>FILTER</PageTitle>
        <Close onClick={onToggleFilterOptions}></Close>
        <Clear>
          <TextButton onClick={onClear}>Clear all</TextButton>
        </Clear>
      </OptionsHeadLine>

      {formData.map((group) => {
        if (!group.hidden) {
          return (
            <FilterOptionsGroup
              key={group.id}
              group={group}
              onFilter={onFilter}
              subGroups={group.isParentGroup ? getChildren(formData, group.id) : []}
              onToggleGroup={onToggleGroup}
            ></FilterOptionsGroup>
          );
        }
      })}
    </React.Fragment>
  );
};
