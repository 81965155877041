import React from 'react';
import { LoginRedirect } from '../idms/login-redirect';
import { AuthLoginContainer, AuthFlexRight } from './user-messages-style';
import { TextButtonLargeBold } from '../styles/shared';

export const LoginMessage = ({ component: Component }: { component: any }) => {
  const loginComponent = () => {
    return (
      <AuthLoginContainer>
        <Component />
        <AuthFlexRight>
          <TextButtonLargeBold>Login</TextButtonLargeBold>
        </AuthFlexRight>
      </AuthLoginContainer>
    );
  };

  return <LoginRedirect component={loginComponent} />;
};
