import React from 'react';
import { MenuIconRow, MenuIcon } from '../menu/menu-style';
import { TextRegularMedium } from '../../common/styles/shared';
import Edit from '../../../assets/images/edit.svg';

export const EditApp = ({ onClick }: { onClick: any }) => (
  <MenuIconRow onClick={onClick}>
    <MenuIcon img={Edit}></MenuIcon>
    <TextRegularMedium>Edit item</TextRegularMedium>
  </MenuIconRow>
);
