import React from 'react';
import { DetailsContainer, DescriptionText, RequirementsContent, VersionContent, Flex, TextButtonContainer } from './details-style';
import { TextRegularDefaultBold, TextRegularDefault, TextButtonSmall } from '../../common/styles/shared';

interface DetailsProps {
  details: any; // PropTypes.object,
  showDetails: any; // PropTypes.func,
  showReleaseNotes: any; // PropTypes.func,
  showRequirements: any; // PropTypes.bool,
}

export const Details = ({ details, showDetails, showReleaseNotes, showRequirements }: DetailsProps) => (
  <div>
    <DetailsContainer>
      <DescriptionText>{details.description}</DescriptionText>
      {showRequirements && <Requirements details={details} />}

      <VersionContent>
        <Flex>
          <TextRegularDefault>Latest release:&nbsp;</TextRegularDefault>
          <TextRegularDefault>{details.lastPublished}</TextRegularDefault>
        </Flex>
        <Flex>
          <TextRegularDefault>Version:&nbsp;</TextRegularDefault>
          <TextRegularDefault>{details.version}</TextRegularDefault>
        </Flex>
        <TextButtonContainer>
          <span>
            <TextButtonSmall onClick={showReleaseNotes}>Release Notes</TextButtonSmall>
          </span>
          <span>
            <TextButtonSmall onClick={showDetails}>View Details</TextButtonSmall>
          </span>
        </TextButtonContainer>
      </VersionContent>
    </DetailsContainer>
  </div>
);

const Requirements = ({ details }: { details: any }) => (
  <>
    <TextRegularDefaultBold>Requirements</TextRegularDefaultBold>
    <RequirementsContent>
      <Flex>
        <TextRegularDefault>Model:&nbsp;</TextRegularDefault>
        {details.model && <TextRegularDefault>{details.model.name}</TextRegularDefault>}
      </Flex>
      <Flex>
        <TextRegularDefault>Firmware version:&nbsp;</TextRegularDefault>
        <TextRegularDefault>{details.firmware}&nbsp;or later</TextRegularDefault>
      </Flex>
      <Flex>
        <TextRegularDefault>Custom Type version:&nbsp;</TextRegularDefault>
        <TextRegularDefault>{details.customType}&nbsp;or later</TextRegularDefault>
      </Flex>
    </RequirementsContent>
  </>
);
