import styled from 'styled-components';
import { Devices } from '../../common/styles/devices';
import { Colors } from '../../common/styles/colors';
import { TextRegularMedium, TextRegularDefault, SectionTitle, BoxSection, TextButton } from '../../common/styles/shared';

export const FormSection: any = styled(BoxSection).attrs({ className: 'FormSection' })`
  width: 100%;
  margin-bottom: 3.2rem;
`;

export const FormSectionFlex: any = styled.div.attrs({ className: 'FormSectionFlex' })`
  display: flex;
  flex-wrap: wrap;
`;

export const FormSectionHeader: any = styled(SectionTitle).attrs({ className: 'FormSectionHeader' })`
  margin-bottom: 1.6rem;
`;

export const FormSectionSubHeader: any = styled(TextRegularDefault).attrs({ className: 'FormSectionSubHeader' })`
  color: ${Colors.textSecondary};
`;

export const InputControl: any = styled.div.attrs({ className: 'InputControl' })`
  padding-bottom: 3.2rem;
`;

export const InputControlGroup: any = styled.div.attrs({ className: 'InputControlGroup' })`
  min-width: ${(props: any) => props.width || '100%'};
  padding: 0 1.6rem 3.2rem 0;

  @media ${Devices.breakSmall} {
    min-width: ${(props: any) => props.width || '33.3%'};
    max-width: ${(props: any) => props.width || '33.3%'};
  }

  :last-of-type {
    padding: 0 0 3.2rem 0;
  }
`;

export const TextInputContainer: any = styled.div.attrs({ className: 'TextInputContainer' })`
  width: 100%;

  @media ${Devices.breakSmall} {
    width: 40%;
  }
`;

export const TextAreaContainer: any = styled.div.attrs({ className: 'TextAreaContainer' })`
  width: 100%;

  @media ${Devices.breakSmall} {
    width: 60%;
  }
`;

export const ControlHeadline: any = styled(TextRegularMedium).attrs({ className: 'ControlHeadline' })`
  margin-bottom: 0.8rem;
  font-weight: bold;
`;

export const FormColumnWrapper: any = styled.div.attrs({ className: 'FormColumnWrapper' })`
  display: flex;
`;

export const TagGroupsContainer: any = styled.div.attrs({ className: 'TagGroupsContainer' })`
  display: flex;
  flex-direction: row;
`;

export const GroupContainer: any = styled.div.attrs({ className: 'GroupContainer' })`
  display: flex;
  flex-direction: column;
`;

export const FileSection: any = styled.div.attrs({ className: 'FileSection' })`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 1.2rem 1.6rem 0 0;
`;

export const FileUploadInput: any = styled.input.attrs({ type: 'file', className: 'FileUploadInput' })`
  width: 0.1rem;
  height: 0.1rem;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  cursor: ${(props: any) => (props.disabled ? 'none' : 'pointer')};
  opacity: ${(props: any) => (props.disabled ? '.5' : '1')};
`;

export const FlexSpaceBetween: any = styled.div.attrs({ className: 'FlexSpaceBetween' })`
  display: flex;
  justify-content: space-between;
`;

export const TemplateButton: any = styled(TextButton).attrs({ className: 'TemplateButton' })`
  display: flex;
  align-items: center;
  margin-bottom: 0.8rem;
`;

export const ArrowIcon: any = styled.svg.attrs({ className: 'ArrowIcon' })`
  width: 2.1rem;
  height: 1.2rem;
  background: url(${(props: any) => props.img});
  background-repeat: no-repeat;
`;

export const DescriptionExampleContainer: any = styled.div.attrs({ className: 'DescriptionExampleContainer' })`
  background: ${Colors.bgPrimary};
  padding: 1.2rem;
  margin-bottom: 1.6rem;
`;

export const FormStickyBottom: any = styled.div.attrs({ className: 'FormStickyBottom' })`
  position: sticky;
  bottom: 0px;
  padding-bottom: 15px;
  background: rgb(250, 250, 250);
  outline: rgb(250, 250, 250) 15px solid;
  display: flex;
`;

export const FormDeleteBottom: any = styled.div.attrs({ className: 'FormDeleteBottom' })`
  position: inherit;
  bottom: 0px;
  padding-bottom: 15px;
  background: rgb(250, 250, 250);
  outline: rgb(250, 250, 250) 15px solid;
  display: flex;
`;
