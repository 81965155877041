import { PureComponent } from 'react';
import { BrowserRouter as Router, Switch, withRouter } from 'react-router-dom';
import { CacheService } from './services/cache-service';
import { Constants } from './services/constant-service';
import { TagGroupService } from './services/tag-group-service';
import { ApiService } from './services/api-service';
import { ApplicationService } from './services/application-service';
import { FileGroupService } from './services/file-group-service';
import { ProtectedRoute } from './components/routes/protected-route';
import { NotProtectedRoute } from './components/routes/not-protected-route';
import { SelectorContainer } from './components/selector-page/selector-container';
import { DownloadContainer } from './components/download-page/download-container';
import { AddAppContainer } from './components/admin/add-app-page/add-app-container';
import { EditAppContainer } from './components/admin/edit-app-page/edit-app-container';
import { AboutContainer } from './components/about-page/about-container';
import { IDMSLoginContainer } from './components/idms/idms-login-container';
import { IDMSLogoutContainer } from './components/idms/idms-logout-container';
import { IDMSCancelContainer } from './components/idms/idms-cancel-container';
import { PageUnavailable } from './components/common/error-pages/page-unavailable';
import { Splash } from './components/common/loaders/splash';
import { Colors } from './components/common/styles/colors';
import { ListContainer } from './components/sortable-list/sortable-list-container';
import { FeedbackListContainer } from './components/feedback-page/view-feedback-container';
import { StateProviderState } from './services/state-context';
import { AppType, AppTypeGroup, Application } from './interfaces/applications';
import { FileTypeGroup } from './interfaces/files';
import { TagGroup } from './interfaces/tags';

interface AppProps {
  location: Location;
  state: StateProviderState;
  updateUser: () => void;
  getCurrentRoute: () => string;
}

interface AppState {
  initializing: boolean;
  error: boolean;
  selectedAppType?: AppType;
  updating: boolean;
}

export class App extends PureComponent<AppProps, AppState> {
  private allApplications: Application[] = [];
  private fileGroups: FileTypeGroup[] = [];
  private tagGroups: TagGroup[] = [];
  private customErrorMessage = '';
  private errorMessage = 'Unable to load item.';
  private applicationTypes: AppType[] = [];
  private applicationTypeGroups: AppTypeGroup[] = [];
  private logoutRedirectPath = Constants.routes.root;

  constructor(props: AppProps) {
    super(props);

    this.state = {
      initializing: true,
      error: false,
      selectedAppType: undefined,
      updating: false,
    };
  }

  async componentDidMount() {
    await this.initApp();
    await this.updateApplications();

    this.props.updateUser();

    this.setState({
      initializing: false,
    });
  }

  async initApp() {
    const { pathname } = this.props.location;
    [this.applicationTypes, this.applicationTypeGroups] = await Promise.all([
      ApiService.getApplicationTypes(),
      ApiService.getApplicationTypeGroups(),
    ]);

    let selectedAppType: AppType = this.applicationTypes[0];

    if (pathname === Constants.routes.root) {
      window.location.href = `/type/${selectedAppType.name}`;
    } else {
      let currentPath = pathname;
      if (pathname === Constants.routes.login || pathname === Constants.routes.logout) {
        currentPath = this.props.getCurrentRoute();
      }
      const foundApp = this.applicationTypes.find((type: AppType) => currentPath.includes(type.name));
      if (foundApp) {
        selectedAppType = foundApp;
        this.logoutRedirectPath = `/type/${selectedAppType.name}`;
      }
    }

    return new Promise<void>((done) => {
      this.setState({ selectedAppType }, done);
    });
  }

  updateApplications = async () => {
    const { selectedAppType } = this.state;

    try {
      this.tagGroups = await TagGroupService.get(selectedAppType?.tagsGroupsId);
      [this.allApplications, this.fileGroups] = await Promise.all([
        ApplicationService.getApplicationsByType(this.tagGroups, selectedAppType?.id),
        FileGroupService.get(selectedAppType?.fileTypeId),
      ]);

      return new Promise<void>((done) => {
        this.setState({ updating: false }, done);
      });
    } catch (ex: any) {
      const jsonFile = await fetch('./page-unavailable-message.json');
      const response = await jsonFile.json();
      this.customErrorMessage = response != null && response[0] != null ? response[0].pageUnavailable : '';
      this.errorMessage = ex.response != null ? `${ex.response.status}: ${ex.response.data.error}` : ex.message;

      return new Promise<void>((done) => {
        this.setState({ updating: false, error: true }, done);
      });
    }
  };

  setSelectedAppType = (appType: AppType) => {
    if (appType === this.state.selectedAppType) {
      return;
    }
    CacheService.removeFromCache(Constants.cache.myAppsForm);
    CacheService.removeFromCache(Constants.cache.selectorForm);
    this.setState({ selectedAppType: appType, updating: true }, () => this.updateApplications());
  };

  redirectUpdate = () => {
    CacheService.removeFromCache(Constants.cache.myAppsForm);
    CacheService.removeFromCache(Constants.cache.selectorForm);
    this.setState({ updating: true }, () => this.updateApplications());
  };

  render() {
    const { error, initializing, selectedAppType, updating } = this.state;

    if (initializing) {
      return <Splash />;
    }
    if (error) {
      return <PageUnavailable errorMessage={this.errorMessage} customErrorMessage={this.customErrorMessage} />;
    }

    const commonProps = {
      applications: this.allApplications,
      applicationTypes: this.applicationTypes,
      setSelectedAppType: this.setSelectedAppType,
      applicationTypeGroups: this.applicationTypeGroups,
      updatingApplications: updating,
      selectedAppType,
    };

    return (
      <Router>
        <Switch>
          <NotProtectedRoute {...commonProps} path={Constants.routes.root} exact groups={this.tagGroups} component={SelectorContainer} />
          <NotProtectedRoute
            {...commonProps}
            path={Constants.routes.root + 'type/:type'}
            exact
            tagGroups={this.tagGroups}
            component={SelectorContainer}
          />
          <NotProtectedRoute
            {...commonProps}
            path={Constants.routes.downloadWithId}
            exact
            fileGroups={this.fileGroups}
            color={Colors.bgSecondary}
            component={DownloadContainer}
          />
          <ProtectedRoute
            {...commonProps}
            path={Constants.routes.myApplications}
            exact
            tagGroups={this.tagGroups}
            hasAccess="isDownLoader"
            component={SelectorContainer}
          />
          <ProtectedRoute
            {...commonProps}
            path={`/type/:type${Constants.routes.addApplication}`}
            exact
            tagGroups={this.tagGroups}
            fileGroups={this.fileGroups}
            hasAccess="isUpLoader"
            redirectUpdate={this.redirectUpdate}
            component={AddAppContainer}
          />
          <ProtectedRoute
            {...commonProps}
            path={Constants.routes.editApplicationWithId}
            exact
            tagGroups={this.tagGroups}
            fileGroups={this.fileGroups}
            hasAccess="isUpLoader"
            redirectUpdate={this.redirectUpdate}
            component={EditAppContainer}
          />
          <ProtectedRoute {...commonProps} path={Constants.routes.statistics} exact hasAccess="isStatistician" component={ListContainer} />
          <ProtectedRoute
            {...commonProps}
            path={Constants.routes.feedbackList}
            exact
            hasAccess="isAdministrator"
            component={FeedbackListContainer}
          />
          <NotProtectedRoute component={AboutContainer} path={Constants.routes.about} color={Colors.bgSecondary} {...commonProps} exact />
          <NotProtectedRoute component={IDMSLoginContainer} path={Constants.routes.login} exact />
          <NotProtectedRoute component={IDMSLogoutContainer} path={Constants.routes.logout} redirectPath={this.logoutRedirectPath} exact />
          <NotProtectedRoute component={IDMSCancelContainer} path={Constants.routes.cancel} exact />
          <NotProtectedRoute component={ListContainer} path={Constants.routes.caValues} exact {...commonProps} />
          <NotProtectedRoute component={SelectorContainer} {...commonProps} tagGroups={this.tagGroups} />
        </Switch>
      </Router>
    );
  }
}

export default withRouter((props: any) => <App {...props} />);
