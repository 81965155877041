import React from 'react';
import { NoAppsContainer, HistoryIcon, NoAppsText, NoAppsInfoText } from './no-apps-style';

export const NoMyApps = () => (
  <NoAppsContainer>
    <HistoryIcon></HistoryIcon>
    <NoAppsText>No download history yet</NoAppsText>
    <NoAppsInfoText>After downloading software files, you can quickly find and return to them from here.</NoAppsInfoText>
  </NoAppsContainer>
);
