import { BmsStartPageLink } from './bms-start-page-link';
import { SupportSection } from './support-section';
import { ErrorPageContainer, ErrorPageContent, ErrorPageTitle, SupportContainer } from './error-pages-style';
import { TextRegularMedium, Margin } from '../styles/shared';

export const PageNotFound = () => {
  return (
    <ErrorPageContainer top="11.2rem">
      <ErrorPageContent>
        <Margin top="1.6rem" bottom="1.6rem">
          <ErrorPageTitle>Page not found</ErrorPageTitle>
        </Margin>
        <TextRegularMedium>Sorry, the page you requested does not exist.</TextRegularMedium>
        <Margin top="1.6rem" bottom="3.2rem">
          <TextRegularMedium>You may have typed the address incorrectly or you may have used and outdated link.</TextRegularMedium>
        </Margin>

        <Margin bottom="3.2rem">
          <BmsStartPageLink />
        </Margin>

        <SupportContainer>
          <SupportSection />
        </SupportContainer>
      </ErrorPageContent>
    </ErrorPageContainer>
  );
};
