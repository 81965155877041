import { Config } from '../../../config/config';

export const LoginRedirect = ({ component: Component }: { component: any }) => {
  const url = Config.idmsLoginUrl;

  return (
    <a href={url}>
      <Component />
    </a>
  );
};
