import styled from 'styled-components';
import ChevronLeft from '../../../assets/images/chevron-left.svg';

export const BackBtnContainer: any = styled.div.attrs({ className: 'BackBtnContainer' })`
  display: flex;
  align-items: center;
  border: none;
  background: none;
  cursor: pointer;
`;

export const BackIcon: any = styled.svg.attrs({ className: 'BackIcon' })`
  width: 1.2rem;
  height: 1.6rem;
  background: url(${ChevronLeft}) center right;
  background-repeat: no-repeat;
`;
