import React from 'react';
import { TextRegularMedium } from '../common/styles/shared';
import { ListHeader, ListColumn, ListRow, ArrowIcon, ListText, ListColumnItem } from './sortable-list-page-style';
import ArrowUp from '../../assets/images/chevron-up-filled.svg';
import ArrowDown from '../../assets/images/chevron-down-filled.svg';

interface SortableListProps {
  filteredList: any; // PropTypes.array,
  columns: any; // PropTypes.array,
  onSortList: (columnName: string) => void;
  path: string;
  bold?: boolean;
  color?: string;
}

export const SortableList = ({ ...props }: SortableListProps) => {
  return (
    <div>
      <ListHeader>
        {props.columns.map((col, index) => (
          <ListColumn key={index} disabled={col.enableSort ? false : true} onClick={() => props.onSortList(col.name)}>
            <TextRegularMedium>{col.display}</TextRegularMedium>
            {col.enableSort && <ArrowIcon img={col.desc ? ArrowUp : ArrowDown} active={col.active}></ArrowIcon>}
          </ListColumn>
        ))}
      </ListHeader>

      <div>
        <List {...props} />
      </div>
    </div>
  );
};

function List(props) {
  return props.filteredList.map((row, index) => {
    return <ListRow key={index}>{renderCells(props, row)}</ListRow>;
  });
}

const renderCells = (props, row) => {
  return props.columns.map((column, index) => (
    <ListColumnItem key={index}>
      <ListText bold={column.bold}>{row[column.name]}</ListText>
    </ListColumnItem>
  ));
};
