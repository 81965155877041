import React from 'react';
import { PopUpListContainer, VersionRow, VersionText } from './body-style';
import { TextRegularMedium } from '../../../common/styles/shared';

export const ReleaseNotesPopUpBody = ({ app }: { app: any }) => {
  const sortedReleaseNotes = [...app.releaseNotes].sort((a, b) => sortByVersion(b.version, a.version));
  return (
    <PopUpListContainer>
      <div>
        {sortedReleaseNotes.map((note, index) => (
          <VersionRow key={index}>
            <VersionText>
              Version<span>&nbsp;</span>
              {note.version} - {note.releaseDate}
            </VersionText>
            <TextRegularMedium>{note.text}</TextRegularMedium>
          </VersionRow>
        ))}
      </div>
    </PopUpListContainer>
  );
};

const getNumberArray = (stringArray) => {
  return stringArray.split('.').map((stringNum) => parseInt(stringNum, 10));
};

const sortByVersion = (a, b) => {
  const nums1 = getNumberArray(a);
  const nums2 = getNumberArray(b);

  for (var i = 0; i < Math.max(nums1.length, nums2.length); i++) {
    if (nums2[i] === undefined) {
      return 1;
    } else if (nums1[i] === undefined) {
      return -1;
    }
    if (nums1[i] !== nums2[i]) {
      return nums1[i] - nums2[i];
    }
  }
  return 0;
};
