import { LoaderGreenSmall } from '../../../common/loaders/green-loader-small';
import { DownLoadFileContainer, DownLoadIconGreen, FileType, FileLoader } from './action-buttons-style';

export const DownLoadFileBtn = ({ type, isDownloading }: { type: any; isDownloading: boolean }) => (
  <DownLoadFileContainer>
    <DownLoadIconGreen></DownLoadIconGreen>
    <FileType>{type}</FileType>
    <FileLoader visible={isDownloading}>
      <LoaderGreenSmall />
    </FileLoader>
  </DownLoadFileContainer>
);
