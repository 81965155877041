import styled from 'styled-components';
import { Colors } from '../common/styles/colors';
import { Fonts } from '../common/styles/fonts';
import { TextRegularMedium, PageScroll } from '../common/styles/shared';
import Info from '../../assets/images/information.svg';

export const ListPageScroll: any = styled(PageScroll).attrs({ className: 'ListPageScroll' })`
  background: ${Colors.bgSecondary};
`;

export const ListSearchContainer: any = styled.div.attrs({ className: 'ListSearchContainer' })`
  width: 30rem;
  position: relative;
`;

export const SearchSection: any = styled.div.attrs({ className: 'SearchSection' })`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const SortInfo: any = styled.div.attrs({ className: 'SortInfo' })`
  background: ${Colors.bgPrimary};
  display: flex;
  align-items: center;
  padding: 0.8rem 1.2rem;
`;

export const ListHeader: any = styled.div.attrs({ className: 'ListHeader' })`
  width: 100%;
  margin-top: 3.2rem;
  padding-bottom: 0.8rem;
  display: flex;
  justify-content: space-between;
  border-bottom: 0.1rem solid ${Colors.borderDark};
`;

export const ArrowIcon: any = styled.svg.attrs({ className: 'Arrow' })`
  width: 2rem;
  height: 1rem;
  background: url(${(props: any) => props.img}) center center;
  background-repeat: no-repeat;
  display: ${(props: any) => (props.active ? 'block' : 'none')};
`;

export const ListColumn: any = styled.div.attrs({ className: 'ListColumn' })`
  display: flex;
  align-items: center;
  width: 20%;
  padding-right: 3.2rem;
  cursor: pointer;
  pointer-events: ${(props: any) => (props.disabled ? 'none' : 'auto')};
  color: ${(props) => (props.color ? props.color : Colors.textPrimary)}
  font-weight: bold;

  &:hover ${ArrowIcon} {
    display: block;
  }
`;

export const ListColumnItem: any = styled(ListColumn).attrs({ className: 'ListColumnItem' })`
  cursor: default;
`;

export const ListRow: any = styled.div.attrs({ className: 'ListRow' })`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem 0;
  border-bottom: 0.1rem solid ${Colors.borderDark};
`;

export const ListText: any = styled(TextRegularMedium).attrs({ className: 'ListText' })`
  color: ${(props) => (props.color ? props.color : Colors.textPrimary)};
  font-weight: ${(props) => (props.bold ? Fonts.fontWeightBold : 'normal')};
`;

export const InfoIcon: any = styled.svg.attrs({ className: 'InfoIcon' })`
  height: 1.8rem;
  width: 1.8rem;
  margin-right: 1.2rem;
  background: url(${Info}) center center;
  background-repeat: no-repeat;
`;
