import React from 'react';
import { LoginMessage } from './login-message';
import { LoginText } from '../texts/login-text';
import { NoRightsText } from '../texts/no-rights-text';
import { ToastBackDrop, ToastBottomContainer, Close, ToastText } from './user-messages-style';

interface ToastBottomProps {
  responseCode: number; // PropTypes.number,
  onClose: any; // PropTypes.func,
  text?: string; // PropTypes.string,
  component?: any; // PropTypes.func,
  color?: string; // PropTypes.string,
}

export const ToastBottom = ({ responseCode, text, onClose, component: Component, color }: ToastBottomProps) => {
  const getMessageComponent = () => {
    switch (responseCode) {
      case 0:
        if (Component != null) {
          return <Component />;
        }
        return null;
      case 200:
        return <ToastText>{text}</ToastText>;
      case 400:
        return <ToastText>{text}</ToastText>;
      case 401:
        return <LoginMessage component={LoginText} />;
      case 403:
        return <LoginMessage component={NoRightsText} />;
      case 500:
        return <ToastText>{text}</ToastText>;
      default:
        return <ToastText>We have encountered a problem. Please try again.</ToastText>;
    }
  };

  return (
    <ToastBackDrop onClick={onClose}>
      <ToastBottomContainer color={color}>
        {getMessageComponent()}
        <Close onClick={onClose}></Close>
      </ToastBottomContainer>
    </ToastBackDrop>
  );
};
