import React from 'react';
import { SearchInput } from '../common/inputs/search/search-input';
import { SortableList } from './sortable-list';
import { Colors } from '../common/styles/colors';
import { PageLoaderWhite } from '../common/loaders/page-loader-white';
import { PageUnavailable } from '../common/error-pages/page-unavailable';
import { PageLoaderContainer } from '../admin/admin-style';
import { ListSearchContainer, ListPageScroll, SearchSection, SortInfo, InfoIcon } from './sortable-list-page-style';
import { PageTitle, AdminPageHeader, TextRegularDefault, BackDropTransparent, Margin } from '../common/styles/shared';
import { getPageInformation, isStatisticsPage } from './contents';
import { ToSelectorButton } from '../common/back-button/to-selector-button';
import { AppType, Application } from '../../interfaces/applications';

interface ListPageProps {
  loading: boolean;
  filteredList: Application[];
  onSearchChange: (e: any) => void;
  onSearchErase: () => void;
  showEraseButton: boolean;
  columns: any; // PropTypes.array,
  onSortList: (columnName: string) => void;
  responseCode: number;
  path: string;
  selectedAppType: AppType;
}

export const ListPage = ({
  loading,
  filteredList,
  onSearchChange,
  onSearchErase,
  showEraseButton,
  columns,
  onSortList,
  responseCode,
  path,
  selectedAppType,
}: ListPageProps) => {
  const sortInformation =
    path != null && isStatisticsPage(path) ? (
      <SortInfo>
        <InfoIcon></InfoIcon>
        <TextRegularDefault>
          Sorting is not available for item ID or Name.<br></br>
          File downloads are counted once per file type and user.
        </TextRegularDefault>
      </SortInfo>
    ) : null;

  const pageInformation: any = getPageInformation(path);

  return (
    <React.Fragment>
      {loading && (
        <React.Fragment>
          <BackDropTransparent visible={loading}></BackDropTransparent>
          <PageLoaderContainer>
            <PageLoaderWhite />
          </PageLoaderContainer>
        </React.Fragment>
      )}

      {responseCode !== 0 && responseCode === 500 && <PageUnavailable top="11.2rem" />}

      {!loading && responseCode === 0 && (
        <ListPageScroll>
          <Margin top="1.2rem" bottom="1.6rem">
            <ToSelectorButton selectedAppType={selectedAppType} />
          </Margin>
          <AdminPageHeader>
            <div>
              <PageTitle color={Colors.textAccentPrimary}>{pageInformation.header}</PageTitle>
              <TextRegularDefault>{pageInformation.description}</TextRegularDefault>
            </div>
          </AdminPageHeader>

          <SearchSection>
            <ListSearchContainer>
              <SearchInput
                placeHolder="Search"
                showEraseButton={showEraseButton}
                onSearchChange={onSearchChange}
                onSearchErase={onSearchErase}
              />
            </ListSearchContainer>
            {sortInformation}
          </SearchSection>

          <SortableList filteredList={filteredList} columns={columns} onSortList={onSortList} path={path} />
        </ListPageScroll>
      )}
    </React.Fragment>
  );
};
