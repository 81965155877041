import React, { Component } from 'react';
import { ApiService } from '../../services/api-service';
import { FeedbackListPage } from './view-feedback-page';
import { Constants } from '../../services/constant-service';

interface FeedbackListProps {
  selectedAppType: any;
}

interface FeedbackListState {
  loading: boolean;
  list: any;
  responseCode: number;
}

export class FeedbackListContainer extends Component<FeedbackListProps, FeedbackListState> {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      list: [],
      responseCode: 0,
    };
  }

  async componentDidMount() {
    try {
      const items = await ApiService.getFeedBackList();
      const dateTimeFormat = new Intl.DateTimeFormat(Constants.defaultDateFormat);
      this.setState({ list: items.map((item) => shapeItemDate(item, dateTimeFormat)), loading: false });
    } catch (error: any) {
      return this.setState({
        responseCode: error.response.status,
        loading: false,
      });
    }
  }

  render() {
    const { selectedAppType } = this.props;
    const { list, loading, responseCode } = this.state;

    return <FeedbackListPage list={list} loading={loading} responseCode={responseCode} selectedAppType={selectedAppType} />;
  }
}

function shapeItemDate(item, dateTimeFormat) {
  return { ...item, created: dateTimeFormat.format(new Date(item.created)) };
}
