import React from 'react';
import { PopUp } from '../../../common/popup/popup';
import { FileResultList } from './file-result-list';
import { SearchContainer } from '../search-container';
import { BackDrop } from '../../../common/styles/shared';

interface FileSearchProps {
  onClose: any; // PropTypes.func,
  list: any; // PropTypes.array,
  onSelected: any; // PropTypes.func,
  onSearchBlur: any; // PropTypes.func,
  selectedAppType: any; // PropTypes.object,
  downloadingFileId: any; // PropTypes.number,
}

export const FileSearch = ({ ...props }: FileSearchProps) => {
  const handleSelectedFile = (file) => {
    props.onSelected(file);
  };

  return (
    <React.Fragment>
      <BackDrop visible="true" onClick={props.onClose}></BackDrop>
      <PopUp title="File search" onClose={props.onClose}>
        <div>
          <SearchContainer
            prepopulateList
            resultComponent={FileResultList}
            list={props.list}
            placeHolder="Search file name or tag"
            onSelected={handleSelectedFile}
            onSearchBlur={props.onSearchBlur}
            isFileSearch={true}
            downloadingFileId={props.downloadingFileId}
            headerTitle={'Search for file to download'}
          />
        </div>
      </PopUp>
    </React.Fragment>
  );
};
