import PropTypes from 'prop-types';
import { Constants } from '../../../services/constant-service';
import { CheckBox } from '../../common/inputs/checkbox/checkbox';
import { FormSection, FormSectionFlex, FormSectionHeader, InputControlGroup } from './form-parts-style';
import { Margin, RowContainer, TextRegularMedium } from '../../common/styles/shared';

const find = (id, form) => Array.from(form.values()).find((group: any) => group.id === id);

export const CertificationsFormPart: any = ({ form, onChange }) => {
  const certifications: any = find(Constants.certificationsId, form);

  return (
    <FormSection>
      <FormSectionFlex>
        <InputControlGroup width="40%">
          <FormSectionHeader>{certifications.label}</FormSectionHeader>
          {certifications.options.map((option, index) => {
            return (
              <Margin top="1.6rem" key={index}>
                <RowContainer onClick={() => onChange(certifications.name, option.value, index)}>
                  <CheckBox checked={option.selected} />
                  <TextRegularMedium>{option.label}</TextRegularMedium>
                </RowContainer>
              </Margin>
            );
          })}
        </InputControlGroup>
      </FormSectionFlex>
    </FormSection>
  );
};

CertificationsFormPart.propTypes = {
  form: PropTypes.object,
  onChange: PropTypes.func,
};
