import { Constants, isSoftwareGroup } from '../../../../services/constant-service';
import { NewVersionStatus, NewStatus, DeletedStatus, EditedStatus } from './file-status/status';
import { FormFileCardContainer, FileInfoSection, ButtonSection } from './filecard-style';
import { SmallInputLabel, TextRegularDefault, TextRegularSmall } from '../../../common/styles/shared';
import { TextInputSmall } from '../../../common/inputs/text-input/text-input-small';
import { TagGroup } from '../../../../interfaces/tags';

const renderFileStatus = (status) => {
  switch (status) {
    case Constants.status.updated:
      return <NewVersionStatus />;
    case Constants.status.new:
      return <NewStatus />;
    case Constants.status.deleted:
      return <DeletedStatus />;
    case Constants.status.edited:
      return <EditedStatus />;
    default:
      break;
  }
};

interface FormFileCardProps {
  file: any;
  buttonComponent: any;
  onChangeSearchTag: (e: any, index?: number) => void;
  tagGroup: TagGroup;
}

export const FormFileCard = ({ file, buttonComponent: ButtonComponent, onChangeSearchTag, tagGroup }: FormFileCardProps) => {
  return (
    <FormFileCardContainer>
      <FileInfoSection>
        <TextRegularDefault>{file.name}</TextRegularDefault>
        {file.status && <span>{renderFileStatus(file.status)}</span>}
      </FileInfoSection>

      {isSoftwareGroup(tagGroup) && (
        <>
          <SmallInputLabel>
            <TextRegularSmall>Optional search tag</TextRegularSmall>
          </SmallInputLabel>
          <TextInputSmall
            type="text"
            value={file.searchTag || ''}
            name={file.name}
            width="100%"
            placeholder={'Optional search tag'}
            onChange={(e) => onChangeSearchTag(e)}
            maxLength={100}
          />
        </>
      )}
      <ButtonSection>
        <ButtonComponent />
      </ButtonSection>
    </FormFileCardContainer>
  );
};
