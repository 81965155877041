import { ApiService } from './api-service';
//iOS needs to be handled differently.
//as from version 13 iOS will be able to handle download
//in the same way other browsers do

export const DownloadService = {
  downLoadFile: async (file) => {
    if (isIOS()) {
      const downloadSupport = downloadAttributeSupported();
      var zipIt = !downloadSupport && file.fileTypeId === 2 ? true : false;
      const name = file.name;
      const url = ApiService.getDownLoadFileUrl(file.id, zipIt);
      handleiOSDownLoad(url, name, downloadSupport);
      return zipIt;
    } else {
      const name = file.name;
      const downLoadedFile = await ApiService.downLoadFile(file, false);
      const blob = new Blob([downLoadedFile], { type: 'application/octet-stream' });
      handleDownLoad(blob, name);
      return false;
    }
  },
  downLoadAll: async (app) => {
    if (isIOS()) {
      const url = ApiService.getDownLoadZipUrl(app.id);
      handleiOSDownLoad(url, app.applicationCode);
    } else {
      const downLoadedZip = await ApiService.downLoadAll(app.id);
      const blob = new Blob([downLoadedZip], { type: 'application/zip' });
      handleDownLoad(blob, app.applicationCode);
    }
  },
};

// TODO: Test all supported browsers
const handleDownLoad = (blob, name) => {
  // if (typeof window.navigator.msSaveBlob !== 'undefined') {
  //   // IE workaround for "HTML7007: One or more blob URLs were
  //   // revoked by closing the blob for which they were created.
  //   // These URLs will no longer resolve as the data backing
  //   // the URL has been freed."
  //   window.navigator.msSaveBlob(blob, name);
  // } else {
  const blobURL = window.URL.createObjectURL(blob);
  let link = document.createElement('a');
  link.style.display = 'none';
  link.href = blobURL;
  link.setAttribute('download', name);

  // Safari thinks _blank anchor are pop ups. We only want to set _blank
  // target if the browser does not support the HTML5 download attribute.
  // This allows you to download files in desktop safari if pop up blocking
  // is enabled.
  if (typeof link.download === 'undefined') {
    link.setAttribute('target', '_blank');
  }

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(blobURL);
  // }
};

const handleiOSDownLoad = (url, name, downloadSupport?) => {
  let form = document.createElement('form');
  form.method = 'GET';
  form.style.display = 'none';
  document.body.appendChild(form);

  let link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('target', '_blank');

  if (downloadSupport) {
    link.setAttribute('download', name);
  }

  form.appendChild(link);
  link.click();
  return document.body.removeChild(form);
};

const isIOS = function () {
  return /(Mac|iPhone|iPad)/i.test(navigator.platform);
};

export const downloadAttributeSupported = () => {
  var downloadSupport = true;
  // We only need to check for iOS on iPhone/iPad (Mac/Android/Windows supports it).
  if (/(iPhone|iPad)/i.test(navigator.platform)) {
    // Check if Download attribute is upported.
    var a = document.createElement('a');
    var downloadType: any = typeof a.download;
    if (downloadType == 'undefined' || downloadType == '') {
      // The iOS does not support the "download" attribute for a link.
      downloadSupport = false;
    }
  }
  return downloadSupport;
};

export const getDownloadMessage = (filename, zippedFile, success = true) => {
  if (success) {
    return zippedFile ? 'You need to unzip the Image file before you can use it.' : 'Thank you for downloading file: ' + filename;
  }
  return `Something went wrong downloading your ${zippedFile ? 'zip' : 'file'}. Please try again later`;
};
