import axios, { AxiosResponse } from 'axios';

export const ApiService = {
  getTags: async () => {
    return await getTags();
  },
  getTagGroups: async () => {
    return await getTagGroups();
  },
  getApplications: async () => {
    return await getApplications();
  },
  getApplicationsByType: async (typeId) => {
    return await getApplicationsByType(typeId);
  },
  getApplicationById: async (id) => {
    return await getAppById(id);
  },
  getFileGroups: async () => {
    return await getFileGroups();
  },
  getApplicationTypeGroups: async () => {
    return await getApplicationTypeGroups();
  },
  getApplicationTypes: async () => {
    return await getApplicationTypes();
  },
  saveApplication: async (app) => {
    return await saveApplication(app);
  },
  updateApplication: async (app, id) => {
    return await updateApplication(app, id);
  },
  deleteApplication: async (app, id) => {
    return await deleteApplication(app, id);
  },
  updateFile: async (file) => {
    return await updateFile(file);
  },
  updateFileSearchTag: async (file) => {
    return await updateFileSearchTag(file);
  },
  saveFile: async (file) => {
    return await saveFile(file);
  },
  getFile: async (path) => {
    return await getFile(path);
  },
  login: async (code) => {
    let formData = new FormData();
    formData.append('code', code);
    return await login(formData);
  },
  logout: async () => {
    return await logout();
  },
  downLoadFile: async (file, zipIt) => {
    return await downLoadFile(file, zipIt);
  },
  downLoadAll: async (id) => {
    return await downLoadAll(id);
  },
  getMyApplications: async () => {
    return await getMyApplications();
  },
  sendFeedBack: async (feedback) => {
    let formData = new FormData();
    formData.append('Comment', feedback);
    return await sendFeedBack(formData);
  },
  getUsageStatistics: async () => {
    return await getUsageStatistics();
  },
  getFeedBackList: async () => {
    return await getFeedBackList();
  },
  getDownLoadFileUrl: (fileId, zipIt) => {
    return getApiUrl() + `File/${fileId}/${zipIt}/download`;
  },
  getDownLoadZipUrl: (appId) => {
    return getApiUrl() + `Asset/${appId}/downloadall`;
  },
  getCAValues: async () => {
    return await getCAValues();
  },
};

const getApiUrl = () => {
  const host = window.location.hostname;
  return `https://${host}:5001/api/`;
};

const getTags = async () => {
  const response = await axios.get(getApiUrl() + 'Tag');
  return response.data;
};

const getTagGroups = async () => {
  const response = await axios.get(getApiUrl() + 'TagGroup');
  return response.data;
};

const getApplications = async () => {
  const response = await axios.get(getApiUrl() + 'Asset');
  return response.data;
};

const getApplicationsByType = async (typeId) => {
  const response = await axios.get(getApiUrl() + `Asset/byType?applicationTypeId=${typeId}`);
  return response.data;
};

const getAppById = async (id) => {
  const response = await axios.get(getApiUrl() + `Asset/${id}`);
  return response.data;
};

const getFileGroups = async () => {
  const response = await axios.get(getApiUrl() + 'FileTypeGroup');
  return response.data;
};

const getApplicationTypeGroups = async () => {
  const response = await axios.get(getApiUrl() + 'ApplicationTypeGroup');
  return response.data;
};

const getApplicationTypes = async () => {
  const response = await axios.get(getApiUrl() + 'ApplicationType');
  return response.data;
};

const getCAValues = async () => {
  const response = await axios.get(getApiUrl() + 'CAValues');
  return response.data;
};

const saveApplication = async (app) => {
  const response: AxiosResponse = await axios({
    method: 'post',
    url: getApiUrl() + 'Asset',
    data: app,
    withCredentials: true,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return response.data;
};

const updateApplication = async (app, id) => {
  const response: AxiosResponse = await axios({
    method: 'put',
    url: getApiUrl() + `Asset/${id}`,
    data: app,
    withCredentials: true,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return response.data;
};

const deleteApplication = async (app, id) => {
  const response: AxiosResponse = await axios({
    method: 'delete',
    url: getApiUrl() + `Asset/${id}`,
    data: app,
    withCredentials: true,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return response.data;
};

const saveFile = async (file) => {
  const response: AxiosResponse = await axios({
    method: 'post',
    url: getApiUrl() + 'file/storageUpload',
    data: file,
    withCredentials: true,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return response.data;
};

const updateFile = async (file) => {
  const response: AxiosResponse = await axios({
    method: 'put',
    url: getApiUrl() + 'file/storageEdit',
    data: file,
    withCredentials: true,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return response.data;
};

const updateFileSearchTag = async (file) => {
  const response: AxiosResponse = await axios({
    method: 'put',
    url: getApiUrl() + `file/editSearchTag`,
    data: file,
    withCredentials: true,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return response.data;
};

const getFile = async (path) => {
  const response = await axios.get(path);
  return response.data;
};

const downLoadFile = async (file, zipIt) => {
  const response = await axios({
    method: 'get',
    url: getApiUrl() + `File/${file.id}/${zipIt}/download`,
    responseType: 'blob',
    withCredentials: true,
  });

  return response.data;
};

const downLoadAll = async (id) => {
  const response = await axios({
    method: 'get',
    url: getApiUrl() + `Asset/${id}/downloadall`,
    responseType: 'blob',
    withCredentials: true,
  });

  return response.data;
};

const getMyApplications = async () => {
  const response = await axios({
    method: 'get',
    url: getApiUrl() + 'downloads/mydownloads',
    withCredentials: true,
  });
  return response.data;
};

const login = async (code) => {
  const response = await axios({
    method: 'post',
    url: getApiUrl() + 'User/authenticate',
    data: code,
    withCredentials: true,
  });

  return response.data;
};

const logout = async () => {
  const response = await axios({
    method: 'delete',
    url: getApiUrl() + 'User/signout',
    withCredentials: true,
  });

  return response.data;
};

const sendFeedBack = async (feedback) => {
  const response = await axios({
    method: 'post',
    url: getApiUrl() + 'Feedback',
    data: feedback,
    withCredentials: true,
  });

  return response.data;
};

const getFeedBackList = async () => {
  const response = await axios({
    method: 'get',
    url: getApiUrl() + 'Feedback',
    withCredentials: true,
  });
  return response.data;
};

const getUsageStatistics = async () => {
  const response = await axios({
    method: 'get',
    url: getApiUrl() + 'statistics',
    withCredentials: true,
  });
  return response.data;
};
