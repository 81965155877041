import { ApiService } from './api-service';

export const FileGroupService = {
  get: async (fileTypeId) => {
    let fileGroups = await ApiService.getFileGroups();
    return fileGroups.map((fileGroup) => {
      return { ...fileGroup, fileTypes: fileGroup.fileTypes.filter((fileType) => fileTypeId.includes(fileType.id)) };
    });
  },
  getAppFiles: (files, fileGroups) => {
    return fileGroups
      .map((group) => {
        return group.fileTypes.reduce((acc, type) => acc.concat(type), []);
      })
      .reduce((acc, arr) => acc.concat(arr))
      .map((type) => getFiles(type, files))
      .filter((type) => type.files.length);
  },
};

const getFiles = (fileType, files) => {
  fileType.files = files.filter((file) => file.fileTypeId === fileType.id);
  return fileType.files.length ? setIsDownLoading(fileType) : fileType;
};

const setIsDownLoading = (fileType) => {
  fileType.files.map((file) => {
    file.isDownLoading = false;
  });
  return fileType;
};
