import React from 'react';
import { Comment, Date, Priority, ListHeader, User, UserAndDate, ListRow, ListWrapper } from './view-feedback-style';

export function FeedbackList(props) {
  return (
    <ListWrapper>
      <ListHeader>
        <UserAndDate>User</UserAndDate>
        <Comment>Comment</Comment>
        <Priority>Priority</Priority>
      </ListHeader>

      <div>
        <List list={props.list} />
      </div>
    </ListWrapper>
  );
}

function List(props) {
  return props.list.map((item, index) => {
    return (
      <ListRow key={index}>
        <UserAndDate>
          <User>{item.federatedId}</User>
          <Date>{item.created}</Date>
        </UserAndDate>
        <Comment>{item.comment}</Comment>
        <Priority>{item.priority}</Priority>
      </ListRow>
    );
  });
}
