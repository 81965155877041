import styled, { keyframes } from 'styled-components';
import { Colors } from '../styles/colors';
import { TextRegularLarge } from '../styles/shared';
import Spinner from '../../../assets/images/spinner.svg';

export const LoaderContainer: any = styled.div.attrs({ className: 'LoaderContainer' })`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const PageLoaderContainer: any = styled.div.attrs({ className: 'PageLoaderContainer' })`
  width: 12rem;
  height: 12rem;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem;
  border-radius: 0.3rem;
`;

export const WhiteLoaderContainer: any = styled(PageLoaderContainer).attrs({ className: 'WhiteLoaderContainer' })`
  height: 14rem;
  background: ${Colors.bgSecondary};
  box-shadow: 0 0.1rem 0.5rem 0 rgba(0, 0, 0, 0.16);
`;

export const BlackLoaderContainer: any = styled(PageLoaderContainer).attrs({ className: 'BlackLoaderContainer' })`
  background: ${Colors.opacityDark};
`;

export const LoaderText: any = styled(TextRegularLarge).attrs({ className: 'LoaderText' })`
  color: ${Colors.textSecondary};
  margin-top: 1.2rem;
`;

export const Spin: any = keyframes`
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
`;

export const Loader: any = styled.svg.attrs({ className: 'Loader' })`
  background: url(${Spinner}) center center;
  background-repeat: no-repeat;
  -webkit-animation: ${Spin} 1s infinite linear;
  animation: ${Spin} 1s infinite linear;
`;

export const SmallGreenLoader: any = styled(Loader).attrs({ className: 'LoaderGreenSmall' })`
  width: 2.5rem;
  height: 2.5rem;
  background-size: 2.5rem 2.5rem;
`;

export const LargeGreenLoader: any = styled(Loader).attrs({ className: 'LoaderGreenLarge' })`
  width: 7.2rem;
  height: 7.2rem;
`;
