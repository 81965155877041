import { ApiService } from './api-service';
import { Constants } from './constant-service';
import { first } from 'lodash';

export const ApplicationService = {
  getApplicationsByType: async (groups, typeId) => {
    const applications = await ApiService.getApplicationsByType(typeId);
    return shapeApplications(applications, groups);
  },
  getAppsByType: (applications, selectedAppType) => {
    const activeApps = removeNotActiveApps(applications);
    return getAppsById(activeApps, selectedAppType.id);
  },
  removeInactive: (applications) => {
    return removeNotActiveApps(applications);
  },
};

const shapeApplications = (applications, groups) => {
  const models = groups.find((group) => group.id === Constants.modelGroupId);
  const featureGroups = groups.filter((group) => group.featureGroup);
  const andLaterGroups = groups.filter((group) => group.options.find((option) => option.level === Constants.andLaterLevel));
  const filterTagGroups = groups.filter((group) => !group.featureGroup && group.id !== Constants.modelGroupId);

  return applications.map((app) => {
    if (andLaterGroups.length > 0) {
      andLaterGroups.forEach((group) => modifyTagList(app, group));
    }
    app.model = models ? getModel(app, models) : null;
    app.features = getList(app, featureGroups);
    app.filterTags = getList(app, filterTagGroups);
    return app;
  });
};

const modifyTagList = (app, group) => {
  const currentTags = group.options.filter((option) => app.tagsId.includes(option.id));

  if (currentTags.length > 0 && currentTags.find((tag) => tag.level === Constants.andLaterLevel)) {
    app.tagsId = app.tagsId.filter((tagId) => !currentTags.some((current) => current.id === tagId));

    const minValue: any = first(currentTags);
    const tagsToAdd = group.options.filter((option: any) => option.level >= minValue.level && option.level < Constants.andLaterLevel);

    app.tagsId = app.tagsId.concat(tagsToAdd.map((tag) => tag.id));
    app.tagsId.sort((a, b) => a - b);
  }
};

const getModel = (app, models) => {
  return models.options.find((model) => app.tagsId.includes(model.id));
};

const getList = (app, groups) => {
  if (app.tagsId.length) {
    return groups
      .map((group) => {
        return {
          name: group.name,
          filterTags: getFeatures(group, app),
        };
      })
      .filter((group) => group.filterTags.length);
  }
};

const getFeatures = (group, app) => {
  return group.options.filter((option) => app.tagsId.includes(option.id));
};

const removeNotActiveApps = (applications) => {
  return applications.filter((app) => app.active == 1);
};

const getAppsById = (applications, id) => {
  return applications.filter((app) => app.applicationTypeId === id);
};
