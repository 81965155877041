import React from 'react';
import { SelectorPageHeader } from '../selector-page-style';
import { SectionTitle, TextRegularDefault } from '../../common/styles/shared';
import { Link } from 'react-router-dom';
import { Constants, isStandardApplication } from '../../../services/constant-service';
import { ErrorPageLinkThin } from '../../common/error-pages/error-pages-style';

export const RootHeader = ({ appTypeGroup }: { appTypeGroup: any }) => {
  const caValuesLink = isStandardApplication(appTypeGroup.applicationTypes[0]) ? (
    <Link to={Constants.routes.caValues} /*push="true" REMOVE?*/>
      <TextRegularDefault>
        To display heating and cooling values for RP-C devices, click the following link: <ErrorPageLinkThin>Ca Values</ErrorPageLinkThin>.
      </TextRegularDefault>
    </Link>
  ) : null;

  return (
    <SelectorPageHeader>
      <div>
        <SectionTitle>{appTypeGroup.name}</SectionTitle>
        <TextRegularDefault>{appTypeGroup.description}</TextRegularDefault>
        {caValuesLink}
      </div>
    </SelectorPageHeader>
  );
};
