import React, { Component } from 'react';
import { cloneDeep as CloneDeep } from 'lodash';
import { ApplicationFormCreateService } from '../../../services/application-form-create-service';
import { ApplicationFormSubmitService } from '../../../services/application-form-submit-service';
import { FileGroupService } from '../../../services/file-group-service';
import { PopulateEditFormService } from '../../../services/populate-edit-form-service';
import { EditAppPage } from './edit-app-page';
import { ApiService } from '../../../services/api-service';
import { PopUpAction } from '../../common/popup/popup-action';
import { MenuSmallInner } from '../../feedback-page/feedback-style';
import { TextMediumBoldInline, TextRegularMedium } from '../../common/styles/shared';
import { PopUpBackDrop } from '../../download-page/download-page-style';
import { Response } from '../../../interfaces/common';
import { TagGroup } from '../../../interfaces/tags';
import { FileTypeGroup } from '../../../interfaces/files';
import { ShapedUser } from '../../../interfaces/users';
import { AppType, Application } from '../../../interfaces/applications';

interface EditAppProps {
  tagGroups: TagGroup[];
  fileGroups: FileTypeGroup[];
  getUser: () => ShapedUser;
  applications: Application[];
  computedMatch: { params: { id: string | null } };
  selectedAppType: AppType;
  redirectUpdate: () => Promise<void>;
}

interface EditAppState {
  appId: string | null;
  app: any;
  uploading: boolean;
  loading: boolean;
  deleting: boolean;
  originalForm: any;
  form: any;
  responseCode: number;
  showPopup: boolean;
  deleteDone: ((result: Response) => void) | null;
}

export class EditAppContainer extends Component<EditAppProps, EditAppState> {
  constructor(props: EditAppProps) {
    super(props);

    this.state = {
      appId: props.computedMatch.params.id ? props.computedMatch.params.id : null,
      app: undefined,
      uploading: false,
      loading: true,
      deleting: false,
      originalForm: undefined,
      form: undefined,
      responseCode: 0,
      showPopup: false,
      deleteDone: null,
    };
  }

  async componentDidMount() {
    const { appId } = this.state;
    const app = await this.getAppById(appId);
    this.init(app);
  }

  async componentDidUpdate() {
    const inComingAppId = this.props.computedMatch.params.id;
    const { appId } = this.state;
    if (appId !== inComingAppId) {
      const app = await this.getAppById(inComingAppId);
      this.init(app);
      this.setState({ appId: inComingAppId });
    }
  }

  getAppById = async (appId) => {
    try {
      return await ApiService.getApplicationById(appId);
    } catch (error: any) {
      this.setState({
        responseCode: 404,
        loading: false,
      });
    }
  };

  init = (app) => {
    const { tagGroups, fileGroups, selectedAppType } = this.props;
    const files = FileGroupService.getAppFiles(app.files, CloneDeep(fileGroups));
    app.fileGroups = files;

    const form = ApplicationFormCreateService.getForm(CloneDeep(tagGroups), CloneDeep(fileGroups), selectedAppType);
    const originalForm = this.populateForm(app, form);

    this.setState({
      app,
      originalForm,
      form: CloneDeep(originalForm),
      loading: false,
      uploading: false,
    });
  };

  populateForm = (app, form) => {
    return PopulateEditFormService.populate(app, form);
  };

  submit = async (form): Promise<Response> => {
    const { getUser } = this.props;
    const { app } = this.state;
    const user = getUser();

    if (!user) {
      return ApplicationFormSubmitService.setErrorMessage(401);
    } else {
      if (!user.isUpLoader) {
        return ApplicationFormSubmitService.setErrorMessage(403);
      }
    }

    this.setState({
      uploading: true,
    });

    try {
      await ApplicationFormSubmitService.updateApplication(form, app, user);
      return this.submitFiles(app, form);
    } catch (error: any) {
      this.setState({ uploading: false });
      if (error.response.status !== 500) {
        return {
          status: error.response.status,
          message: '',
        };
      }
      return {
        status: 500,
        message: 'Item could not be updated',
      };
    }
  };

  onDeleteItem = async () => {
    const { app } = this.state;
    this.setState({
      deleting: true,
    });

    try {
      await ApiService.deleteApplication(app, app.id);
      this.state.deleteDone?.({ status: 200, message: '' });
    } catch (error: any) {
      this.state.deleteDone?.({ status: error.response.status, message: error.response.data.error });
    } finally {
      this.setState({ deleteDone: null, deleting: false });
    }
  };

  showOnDeletePopup = () => {
    let deleteDone: ((result: { status: number; message: string }) => void) | null = null;
    const result = new Promise((resolve: (r: Response) => void) => (deleteDone = resolve));

    this.setState({
      showPopup: true,
      deleteDone,
    });

    return result;
  };

  submitFiles = async (app, form): Promise<Response> => {
    try {
      await ApplicationFormSubmitService.updateFiles(app.id, form);
      return {
        status: 200,
        message: `Item ${app.applicationCode} is successfully updated!`,
      };
    } catch (error: any) {
      this.setState({ uploading: false });
      return ApplicationFormSubmitService.setErrorMessage(error.response.status);
    }
  };

  textBody = () => {
    return (
      <MenuSmallInner>
        <TextRegularMedium>
          Are you sure you want to delete <TextMediumBoldInline>{this.state.app.name}</TextMediumBoldInline>? All information will be
          removed from database.
        </TextRegularMedium>
      </MenuSmallInner>
    );
  };

  close = () => {
    this.setState({
      showPopup: false,
    });
  };

  render() {
    const { app, form, loading, uploading, deleting, originalForm, responseCode, showPopup } = this.state;
    const { applications, selectedAppType, redirectUpdate } = this.props;

    return (
      <React.Fragment>
        {showPopup && (
          <>
            <PopUpBackDrop visible />
            <PopUpAction title="Delete" onClose={this.close} onAction={() => this.onDeleteItem()} component={this.textBody} />
          </>
        )}

        <EditAppPage
          app={app}
          form={form}
          uploading={uploading}
          loading={loading}
          deleting={deleting}
          applications={applications}
          originalForm={originalForm}
          onSubmit={this.submit}
          responseCode={responseCode}
          selectedAppType={selectedAppType}
          redirectUpdate={redirectUpdate}
          onDelete={this.showOnDeletePopup}
        />
      </React.Fragment>
    );
  }
}
