import React from 'react';
import { AddNew } from '../menu-items/add-new';
import { EditApp } from '../menu-items/edit-app';
import { DownloadFileSearch } from '../menu-items/download-file-search';
import { ViewStatistics } from '../menu-items/view-statistics';
import { Logout } from '../menu-items/logout';
import { LogoutRedirect } from '../../common/idms/logout-redirect';
import { Login } from '../menu-items/login';
import { LoginRedirect } from '../../common/idms/login-redirect';
import { MyProfileRedirect } from '../menu-items/my-profile';
import { MyApps } from '../menu-items/my-apps/my-apps';
import { MyAppsGrey } from '../menu-items/my-apps/my-apps-grey';
import {
  MenuLargeContainer,
  UserStateContainer,
  MenuLargeItem,
  UserStateText,
  MyAppsItem,
  SubMenuHeader,
  ArrowButton,
  SubMenuContainer,
  SubmenuBackDrop,
} from './menu-style';
import { ViewFeedbackList } from '../menu-items/view-feedbackList';
import ArrowUp from '../../../assets/images/chevron-up-thin.svg';
import ArrowDown from '../../../assets/images/chevron-down-thin.svg';

interface MenuLargeProps {
  user: any; // PropTypes.object;
  showSubMenu: any; // PropTypes.bool;
  toggleSubMenu: any; // PropTypes.func;
  toggleEditSearch: any; // PropTypes.func;
  toggleFileSearch: any; // PropTypes.func;
  selectedAppType: any; // PropTypes.object;
}

export const MenuLarge = ({ user, showSubMenu, toggleSubMenu, toggleEditSearch, toggleFileSearch, selectedAppType }: MenuLargeProps) => (
  <React.Fragment>
    <MenuLargeContainer>
      <UserStateContainer>
        {user && user.isDownLoader && (
          <MyAppsItem>
            <MenuLargeItem>
              <MyApps component={MyAppsGrey} />
            </MenuLargeItem>
          </MyAppsItem>
        )}

        <MenuLargeItem>
          {user ? (
            <SubMenuHeader onClick={toggleSubMenu}>
              <UserStateText>{user.name}</UserStateText>
              <ArrowButton img={showSubMenu ? ArrowUp : ArrowDown}></ArrowButton>
            </SubMenuHeader>
          ) : (
            <LoginRedirect component={Login} />
          )}
        </MenuLargeItem>

        {showSubMenu && (
          <React.Fragment>
            <SubmenuBackDrop onClick={toggleSubMenu}></SubmenuBackDrop>
            <SubMenuContainer>
              <MyProfileRedirect />
              {user && user.isUpLoader && (
                <React.Fragment>
                  <AddNew selectedAppType={selectedAppType} onClick={toggleSubMenu} />
                  <EditApp onClick={toggleEditSearch} />
                </React.Fragment>
              )}

              {user && user.isStatistician && (
                <React.Fragment>
                  <ViewStatistics onClick={toggleSubMenu} />
                </React.Fragment>
              )}

              {user && user.isAdministrator && (
                <React.Fragment>
                  <ViewFeedbackList onClick={toggleSubMenu} />
                </React.Fragment>
              )}

              {user && user.isDownLoader && (
                <React.Fragment>
                  <DownloadFileSearch onClick={toggleFileSearch} />
                </React.Fragment>
              )}

              <LogoutRedirect component={Logout} />
            </SubMenuContainer>
          </React.Fragment>
        )}
      </UserStateContainer>
    </MenuLargeContainer>
  </React.Fragment>
);
