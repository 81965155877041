import React, { Component } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { Splash } from '../common/loaders/splash';

interface IDMSLoginProps {
  location: any; // PropTypes.object,
  login: any; // PropTypes.func,
  logout: any; // PropTypes.func,
  setUser: any; // PropTypes.func,
  getCurrentRoute: any; // PropTypes.func,
  setLoginError: any; // PropTypes.func,
}

interface IDMSLoginState {
  loading: boolean;
  redirect: boolean;
}

export class IDMSLoginContainer extends Component<IDMSLoginProps, IDMSLoginState> {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      redirect: false,
    };
  }

  componentDidMount() {
    this.handleIdmsLogin();
  }

  handleIdmsLogin = async () => {
    let {
      location: { search },
      login,
      setLoginError,
    } = this.props;

    try {
      const user = await login(search);
      return this.updateUser(user);
    } catch (error: any) {
      setLoginError(true);
      return this.setState({
        loading: false,
        redirect: true,
      });
    }
  };

  updateUser(user) {
    const { setUser } = this.props;
    setUser(user);

    return this.setState({
      loading: false,
      redirect: true,
    });
  }

  // Not used at all ?
  // redirect = () => {
  //   const { path } = this.state;
  //   return (window.location = path);
  // };

  render() {
    const { loading, redirect } = this.state;
    const { getCurrentRoute } = this.props;

    return (
      <React.Fragment>
        {loading && <Splash />}
        {!loading && redirect && (
          <Switch>
            <Redirect to={{ pathname: getCurrentRoute() }} /*reload={true}*/ />
          </Switch>
        )}
      </React.Fragment>
    );
  }
}
