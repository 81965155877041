import styled from 'styled-components';
import { Colors } from '../../../common/styles/colors';
import { Devices } from '../../../common/styles/devices';
import { TextButtonLargeBold } from '../../../common/styles/shared';
import ArrowIcon from '../../../../assets/images/chevron-right.svg';

export const LoginContainer: any = styled.div.attrs({ className: 'LoginContainer' })`
  display: flex;
  flex-direction: column;
  margin-right: 0;
  padding: 1.6rem 1.6rem 1.6rem 0.8rem;
  background: ${Colors.accentSecondary};
  cursor: pointer;

  @media ${Devices.breakSmall} {
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }

  @media ${Devices.breakMedium} {
    margin-right: 3.2rem;
  }
`;

export const NoRightsContainer: any = styled.div.attrs({ className: 'NoRightsContainer' })`
  padding: 1.6rem 1.6rem 1.6rem 0.8rem;
  background: ${Colors.accentNoAccess};

  @media ${Devices.breakMedium} {
    margin-right: 3.2rem;
  }
`;

export const LoginButtonContainer: any = styled.div.attrs({ className: 'LoginButtonContainer' })`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  cursor: pointer;

  @media ${Devices.breakLarge} {
    justify-content: flex-start;
  }
`;

export const LoginButton: any = styled(TextButtonLargeBold).attrs({ className: 'ButtonText' })`
  color: ${Colors.textLight};
  margin-right: 0.8rem;
`;

export const ArrowRightIcon: any = styled.svg.attrs({ className: 'ArrowRightIcon' })`
  width: 1.1rem;
  height: 1.4rem;
  background: url(${ArrowIcon}) center;
  background-repeat: no-repeat;
`;
