import React, { Component } from 'react';
import { cloneDeep as CloneDeep } from 'lodash';
import { Constants } from '../../services/constant-service';
import { StateContext } from '../../services/state-context';
import { FileGroupService } from '../../services/file-group-service';
import { DownloadService, getDownloadMessage } from '../../services/download-service';
import { DownloadPage } from './download-page';

interface DownloadContainerProps {
  applications: any; // PropTypes.array,
  fileGroups: any; // PropTypes.array,
  getUser: any; // PropTypes.func,
  selectedAppType: any; // PropTypes.object,
  computedMatch: { params: { id: any } };
  // computedMatch: PropTypes.shape({
  //   params: PropTypes.shape({
  //     id: PropTypes.string,
  //   }),
  // }),
}

interface DownloadContainerState {
  app: any;
  appId: any;
  responseCode: number;
  responseMessage: string;
  user: any;
  backDrop: {
    show: boolean;
    details: boolean;
    releaseNotes: boolean;
  };
}

export class DownloadContainer extends Component<DownloadContainerProps, DownloadContainerState> {
  constructor(props) {
    super(props);

    this.state = {
      app: {},
      appId: undefined,
      responseCode: 0,
      responseMessage: '',
      user: undefined,
      backDrop: {
        show: false,
        details: false,
        releaseNotes: false,
      },
    };
  }

  componentDidMount() {
    const { applications, fileGroups, computedMatch } = this.props;
    const appId = computedMatch.params.id;
    this.update(appId, applications, fileGroups);
  }

  componentDidUpdate() {
    const { appId } = this.state;
    let newAppId = undefined;
    const { applications, fileGroups, computedMatch } = this.props;

    if (appId !== computedMatch.params.id) {
      newAppId = computedMatch.params.id;
      this.update(newAppId, applications, fileGroups);
    }
  }

  update = (appId, apps, fileGroups) => {
    const { getUser } = this.props;
    appId ? this.getApp(appId, apps, fileGroups) : this.setPageNotFound();

    this.setState({
      user: getUser(),
      appId,
    });
  };

  getApp = (appId, apps, fileGroups) => {
    const app = CloneDeep(apps.find((a) => a.id == appId));
    return app ? this.shapeApp(app, fileGroups) : this.setPageNotFound();
  };

  setPageNotFound = () => {
    return this.setState({ responseCode: 404 });
  };

  shapeApp = (app, fileGroups) => {
    const notActive = app.active === 0;
    const dateTimeFormat = new Intl.DateTimeFormat(Constants.defaultDateFormat);

    if (notActive) {
      return this.setPageNotFound();
    } else {
      app.lastPublished = dateTimeFormat.format(new Date(app.lastPublished));
      app.isDownLoading = false;

      app.releaseNotes.forEach((note) => {
        note.releaseDate = dateTimeFormat.format(new Date(note.releaseDate));
      });

      const files = FileGroupService.getAppFiles(app.files, CloneDeep(fileGroups));
      app.fileGroups = files;
      return this.setState({ app });
    }
  };

  handleDownLoad = (file, groupIndex) => {
    const { user } = this.state;
    !user || !user.isDownLoader ? this.showAuthMessage() : this.downLoad(file, groupIndex);
  };

  showAuthMessage = () => {
    const { user } = this.state;

    if (!user) {
      return this.setState({ responseCode: 401 });
    } else {
      if (!user.isDownLoader) {
        return this.setState({ responseCode: 403 });
      }
    }
  };

  downLoad = (groupIndex, file = null) => {
    file ? this.downLoadFile(file, groupIndex) : this.downLoadAll(this.state.app);
  };

  downLoadAll = async (app) => {
    if (app.files.length === 0) {
      return;
    }
    this.setZipIsDownLoading(app);
    try {
      await DownloadService.downLoadAll(app);
      this.setState({
        responseCode: 200,
        responseMessage: `Thank you for downloading zip: ${app.applicationCode}.zip`,
      });
    } catch (error: any) {
      this.setState({
        responseCode: error.response.status,
        responseMessage: getDownloadMessage(app, true, false),
      });
    }
    return this.setZipIsDownLoading(app);
  };

  downLoadFile = async (file, groupIndex) => {
    this.setFileIsDownLoading(file.id, groupIndex);
    try {
      const isZipped = await DownloadService.downLoadFile(file);
      this.setState({
        responseCode: 200,
        responseMessage: getDownloadMessage(file.name, isZipped),
      });
    } catch (error: any) {
      if (error) {
        this.setState({
          responseCode: error.response.status,
          responseMessage: getDownloadMessage(file.name, false, false),
        });
      }
    }
    return this.setFileIsDownLoading(file.id, groupIndex);
  };

  setZipIsDownLoading = (app) => {
    app.isDownLoading = !app.isDownLoading;
    return this.setState({ app });
  };

  setFileIsDownLoading = (id, groupIndex) => {
    const { app } = this.state;
    const file = app.fileGroups[groupIndex].files.find((f) => f.id === id);
    file.isDownLoading = !file.isDownLoading;
    return this.setState({ app });
  };

  showDetails = () => {
    this.setState({
      backDrop: {
        show: true,
        details: true,
        releaseNotes: false,
      },
    });
  };

  showReleaseNotes = () => {
    this.setState({
      backDrop: {
        show: true,
        details: false,
        releaseNotes: true,
      },
    });
  };

  closePopUp = () => {
    this.setState({
      backDrop: {
        show: false,
        details: false,
        releaseNotes: false,
      },
    });
  };

  hideToast = () => {
    this.setState({
      responseCode: 0,
      responseMessage: '',
    });
  };

  render() {
    const { app, backDrop, responseCode, responseMessage } = this.state;

    return (
      <StateContext.Consumer>
        {(context) => (
          <DownloadPage
            user={context.state.user}
            appDetails={app}
            onDownLoad={this.handleDownLoad}
            showDetails={this.showDetails}
            showReleaseNotes={this.showReleaseNotes}
            backDrop={backDrop}
            responseCode={responseCode}
            responseMessage={responseMessage}
            hideToast={this.hideToast}
            closePopUp={this.closePopUp}
            selectedAppType={this.props.selectedAppType}
          />
        )}
      </StateContext.Consumer>
    );
  }
}
