import styled from 'styled-components';
import { Colors } from '../../styles/colors';
import { Devices } from '../../styles/devices';
import { BoxSection } from '../../styles/shared';

export const Card: any = styled(BoxSection).attrs({ className: 'Card' })`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 300px;
  height: auto;
  margin-bottom: 1.6rem;
  cursor: pointer;

  @media ${Devices.breakMedium} {
    width: calc(50% - 1.6rem);
    margin-left: 1.6rem;
  }

  @media ${Devices.breakLarge} {
    width: calc(50% - 1.6rem);
    min-width: 280px;
  }

  @media ${Devices.breakXLarge} {
    min-width: 260px;
    width: calc(33.33% - 1.6rem);
    margin-left: 1.6rem;
  }

  @media ${Devices.breakMegaLarge} {
    margin-left: 1.6rem;
  }
`;

export const CardTitle: any = styled.div.attrs({ className: 'CardTitle' })`
  color: ${Colors.textAccentPrimary};
  font-size: 2rem;
  font-weight: bold;
`;

export const List: any = styled.div.attrs({ className: 'List' })`
  width: 100%;
  overflow: hidden;
`;

export const TagList: any = styled(List).attrs({ className: 'TagList' })`
  margin-top: auto;
`;

export const ListItem: any = styled.div.attrs({ className: 'ListItem' })`
  float: left;
  margin: 0 0.5rem 0.4rem 0;
`;

export const AppFeatures: any = styled.div.attrs({ className: 'AppFeatures' })`
  margin: 0 0 1.6rem 0;
`;

export const AppName: any = styled.div.attrs({ className: 'AppName' })`
  margin-bottom: 1.6rem;
  word-break: break-word;
`;
