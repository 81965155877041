import { DescriptionExampleContainer } from './form-parts-style';
import { TextRegularMedium, TextRegularMediumInline, TextMediumBoldInline, Margin } from '../../common/styles/shared';

export const DescriptionExample = () => {
  return (
    <DescriptionExampleContainer>
      <Margin bottom=".4rem">
        <TextRegularMedium>TEMPLATE:</TextRegularMedium>
      </Margin>
      <Margin bottom="1.6rem">
        <TextMediumBoldInline>&lt;system type&gt;</TextMediumBoldInline>&nbsp;
        <TextRegularMediumInline>with</TextRegularMediumInline>&nbsp;
        <TextMediumBoldInline>&lt;control media 1&gt;</TextMediumBoldInline>&nbsp;
        <TextRegularMediumInline>and</TextRegularMediumInline>&nbsp;
        <TextMediumBoldInline>&lt;control media 2&gt;</TextMediumBoldInline>&nbsp;
        <TextRegularMediumInline>based on a </TextRegularMediumInline>&nbsp;
        <TextMediumBoldInline>&lt;coil cofiguration&gt;</TextMediumBoldInline>&nbsp;
        <TextRegularMediumInline>configuration. A</TextRegularMediumInline>&nbsp;
        <TextMediumBoldInline>&lt;control valve&gt;</TextMediumBoldInline>&nbsp;
        <TextRegularMediumInline>control valve with actuator operating via </TextRegularMediumInline>&nbsp;
        <TextMediumBoldInline>&lt;actuator type&gt;</TextMediumBoldInline>&nbsp;
        <TextRegularMediumInline>signal. The fan is controlled using</TextRegularMediumInline>&nbsp;
        <TextMediumBoldInline>&lt;fan control&gt;</TextMediumBoldInline>.
      </Margin>
      <Margin bottom=".4rem">
        <TextRegularMedium>EXAMPLE:</TextRegularMedium>
      </Margin>
      <TextMediumBoldInline>Fan coil unit</TextMediumBoldInline>&nbsp;
      <TextRegularMediumInline>with</TextRegularMediumInline>&nbsp;
      <TextMediumBoldInline>hot water</TextMediumBoldInline>&nbsp;
      <TextRegularMediumInline>and</TextRegularMediumInline>&nbsp;
      <TextMediumBoldInline>chilled water</TextMediumBoldInline>&nbsp;
      <TextRegularMediumInline>based on a</TextRegularMediumInline>&nbsp;
      <TextMediumBoldInline>4-pipe</TextMediumBoldInline>&nbsp;
      <TextRegularMediumInline>configuration. A</TextRegularMediumInline>&nbsp;
      <TextMediumBoldInline>3 port</TextMediumBoldInline>&nbsp;
      <TextRegularMediumInline>control valve with actuator operating via</TextRegularMediumInline>&nbsp;
      <TextMediumBoldInline>0-10V</TextMediumBoldInline>&nbsp;
      <TextRegularMediumInline>signal. The fan is controlled using</TextRegularMediumInline>&nbsp;
      <TextMediumBoldInline>modulating - 0-10V</TextMediumBoldInline>.
    </DescriptionExampleContainer>
  );
};
