import styled from 'styled-components';
import { Colors } from '../common/styles/colors';
import { Button } from '../common/styles/shared';
import { Devices } from '../common/styles/devices';

export const PageLoaderContainer: any = styled.div.attrs({ className: 'LoaderBackDrop' })`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`;

export const CancelResetBtn: any = styled(Button).attrs({ className: 'CancelResetBtn' })`
  height: 4.8rem;
  margin-right: 3.2rem;
  border: 0.2rem solid ${Colors.borderSecondary};
  color: ${Colors.textSecondary};
  border-radius: 0.3rem;
  flex: 0 1 14.8rem;
  :disabled {
    opacity: 0.5;
  }
`;

export const SaveBtn: any = styled(Button).attrs({ className: 'SaveBtn' })`
  height: 4.8rem;
  background: ${Colors.accentSecondary};
  color: ${Colors.textLight};
  border-radius: 0.3rem;
  flex: 0 1 22.2rem;
  :disabled {
    opacity: 0.5;
  }
`;

export const DeleteBtn: any = styled(Button).attrs({ className: 'DeleteBtn' })`
  height: 4.8rem;
  background: ${Colors.deleted};
  color: ${Colors.bgSecondary};
  border-radius: 0.3rem;
  flex: 100%;
  display: block;

  @media ${Devices.breakSmall} {
    flex: 0 1 32.8rem;
  }
`;
