import styled from 'styled-components';
import { Colors } from '../../common/styles/colors';
import { Devices } from '../../common/styles/devices';
import { IconButton } from '../../common/styles/shared';
import { TextRegularDefault, BackDropTransparent } from '../../common/styles/shared';
import CloseIcon from '../../../assets/images/close.svg';
import AckIcon from '../../../assets/images/icon_Acknowledge.svg';

export const MenuSmallContainer: any = styled.div.attrs({ className: 'MenuSmallContainer' })`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 10%;
  max-width: 36rem;
  min-width: 28rem;
  display: flex;
  background: transparent;

  @media ${Devices.breakLarge} {
    display: none;
  }
`;

export const MenuSmallInner: any = styled.div.attrs({ className: 'MenuSmallInner' })`
  overflow-y: auto;
  background: ${Colors.bgSecondary};
  flex: 1;
  z-index: 3;
  padding-left: 1.2rem;
`;

export const MenuCloseContainer: any = styled.div.attrs({ className: 'MenuCloseContainer' })`
  width: 4.8rem;
  height: 4.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${Colors.accentPrimary};
`;

export const OuterMenuClose: any = styled.div.attrs({ className: 'OuterMenuClose' })`
  width: 4.8rem;
  height: 4.8rem;
  z-index: 3;
  background: ${Colors.accentPrimary};
`;

export const MenuCloseBtn: any = styled(IconButton).attrs({ className: 'MenuCloseBtn' })`
  width: 1.6rem;
  height: 1.6rem;
  background: url(${CloseIcon}) center center;
  background-repeat: no-repeat;
`;

export const MenuRow: any = styled.div.attrs({ className: 'MenuRow' })`
  padding-right: 1.2rem;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const MenuTextRow: any = styled(MenuRow).attrs({ className: 'MenuTextRow' })`
  height: 6rem;
  text-transform: uppercase;
  border-bottom: 0.1rem solid ${Colors.borderPrimary};
  justify-content: space-between;
`;

export const AcknowledgeIcon: any = styled.svg.attrs({ className: 'AcknowledgeIcon' })`
  background: url(${AckIcon}) center center;
  background-repeat: no-repeat;
  width: 2.1rem;
  height: 2.1rem;
`;

export const MenuIconRow: any = styled(MenuRow).attrs({ className: 'MenuIconRow' })`
  height: 4.4rem;
`;

export const MenuIcon: any = styled.svg.attrs({ className: 'MenuIcon' })`
  width: 2.1rem;
  height: 2.1rem;
  margin-right: 1.2rem;
  background: url(${(props: any) => props.img}) center center;
  background-repeat: no-repeat;
`;

export const MenuLargeContainer: any = styled.div.attrs({ className: 'MenuLargeContainer' })`
  display: none;

  @media ${Devices.breakLarge} {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 3.2rem;
    color: ${Colors.textSecondary};
  }
`;

export const UserStateContainer: any = styled.div.attrs({ className: 'UserStateContainer' })`
  position: relative;
  display: flex;
  align-items: center;
`;

export const MenuLargeItem: any = styled.div.attrs({ className: 'MenuLargeItem' })`
  height: 3rem;
  display: flex;
  align-items: center;
  padding-right: 1.2rem;
  cursor: pointer;
`;

export const UserStateText: any = styled(TextRegularDefault).attrs({ className: 'UserStateText' })`
  color: ${Colors.textSecondary};
  padding-left: 1.2rem;
`;

export const MyAppsItem: any = styled.div.attrs({ className: 'MyAppsItem' })`
  border-right: 0.1rem solid ${Colors.textSecondary};
`;

export const ArrowButton: any = styled(IconButton).attrs({ className: 'ArrowButton' })`
  width: 1rem;
  height: 1rem;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: 0.4rem;
`;

export const SubMenuHeader: any = styled.div.attrs({ className: 'SubMenuHeader' })`
  display: flex;
  align-items: center;
`;

export const SubMenuContainer: any = styled.div.attrs({ className: 'SubMenuContainer' })`
  width: 24rem;
  position: absolute;
  top: 4rem;
  right: 1.2rem;
  padding: 1.2rem;
  background: ${Colors.bgSecondary};
  z-index: 2;
  box-shadow: 0 0.1rem 0.3rem 0 rgba(0, 0, 0, 0.16);
`;

export const SubmenuBackDrop: any = styled(BackDropTransparent).attrs({ className: 'SubmenuBackDrop' })`
  top: 11.2rem;
`;
